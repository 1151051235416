import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { performLogin } from "../redux/AuthAction.js";
import {
	WebUrl,
	ApiUrl,
	ApiKey,
	LanguageOption,
	SessionKey,
	Language,
	LanguageSelectStyle,
} from "../util/Constant";
import { useTranslation } from "react-i18next";
import Input from "../components/custom/Input";
import Checkbox from "../components/custom/Checkbox";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import PhoneNumber from "../components/custom/PhoneNumber.js";
import ApiEngine from "../util/ApiEngine.js";
import { createFormBody, createMultiPartFormBody } from "../util/Util.js";
import { setBusy, setIdle, showResponseMessage } from "../redux/AppAction.js";
import { saveLanguageId } from "../redux/LanguageAction";
import Select from "react-select";
import SecureLS from "secure-ls";
import ReCAPTCHA from "react-google-recaptcha";

/// <summary>
/// Author: -
/// </summary>
const Login = (props) => {
	var { t, i18n } = useTranslation();
	var _dispatch = useDispatch();
	var { isLoggedIn } = useSelector((state) => state.authState);
	var userData = useSelector((state) => state.authState.userData);
	var _history = useHistory();
	var ls = new SecureLS();
	const {
		register: loginRegister,
		handleSubmit: loginHandleSubmit,
		errors: loginErrors,
	} = useForm();
	const {
		register: forgotPasswordRegister,
		handleSubmit: forgotPasswordHandleSubmit,
		errors: forgotPasswordErrors,
		watch: forgotPasswordWatch,
		setValue: forgotPasswordSetValue,
		unregister: forgotPasswordUnregister,
	} = useForm();
	const { handleSubmit: recaptchaSubmit } = useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
	const [loginFailCount, setLoginFailCount] = useState(0);
	const [loginAttemptUsername, setLoginAttemptUsername] = useState("");
	const recaptcharef = useRef();

	const TEST_SITE_KEY = "6LffRtsbAAAAAODSwUqbIwV2mKf5HFmro6_OD4Yy"; //6LffRtsbAAAAAODSwUqbIwV2mKf5HFmro6_OD4Yy - Key
	/*6LffRtsbAAAAAIURcVT3-1jHgY7WZ_Neg2Z5XbnS - Secret*/

	const { register, handleSubmit, errors } = useForm();
	const [activeLanguage, setActiveLanguage] = useState(
		localStorage.getItem(SessionKey._LANGUAGE) || "EN"
	);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		if (isLoggedIn) {
			if (!window.ReactNativeWebView) {
				if (userData["selectedTheme"] === 0) {
					_history.push(WebUrl._URL_MAIN);
				} else if (userData["selectedTheme"] === 1) {
					_history.push(WebUrl._URL_DASHBOARD_V2);
				}
			}
		}
		if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
			document.getElementById("loginUsername").value =
				ls.get("gR97lPRIn797")["Do3SwI4cuvqR"];
			document.getElementById("loginPassword").value =
				ls.get("gR97lPRIn797")["gR1IPGBN01dS"];
		}
	}, [isLoggedIn]);

	/// <summary>
	/// Author: -
	/// </summary>
	const submitLoginForm = async (data, e) => {
		_dispatch(setBusy());
		setLoginAttemptUsername(data.username);
		if (data.isKeepedPassword) {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: data.username,
				gR1IPGBN01dS: data.password,
				PYTLczcty9uH: data.isKeepedPassword,
			});
		} else {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: "fake",
				gR1IPGBN01dS: "null",
				PYTLczcty9uH: false,
			});
		}
		await _dispatch(
			performLogin(data.username, data.password, data.isKeepedLogin)
		);
		let params = {
			username: data.username,
		};
		var loginFailCountJson = await ApiEngine.post(
			ApiUrl._API_GET_LOGIN_FAILED_COUNT,
			createFormBody(params)
		);
		if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
			let data = loginFailCountJson[ApiKey._API_DATA_KEY];
			setLoginFailCount(data);
		}
		_dispatch(setIdle());
	};

	useEffect(() => {
		if (isLoggedIn) {
			if (loginAttemptUsername == "nn02") {
				_history.push(WebUrl._URL_CAMO_HOMEPAGE);
			} else {
				if (!window.ReactNativeWebView) {
					_history.push(WebUrl._URL_SUMMARY);
				}
			}
		} else {
			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "logout",
					})
				);
			}
		}
		if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
			document.getElementById("loginUsername").value =
				ls.get("gR97lPRIn797")["Do3SwI4cuvqR"];
			document.getElementById("loginPassword").value =
				ls.get("gR97lPRIn797")["gR1IPGBN01dS"];
		}
	}, [isLoggedIn]);

	/// <summary>
	/// Author: -
	/// </summary>
	async function submitForgotPasswordForm(data) {
		_dispatch(setBusy());
		data["phoneNumber"] = formattedPhoneNumber;
		data["language"] = i18n.language;

		var responseJson = await ApiEngine.post(
			ApiUrl._API_SEND_RESET_PASSWORD_SMS,
			createMultiPartFormBody(data)
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			resetForm();
		}

		_dispatch(
			showResponseMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				responseJson[ApiKey._API_MESSAGE_KEY]
			)
		);
		_dispatch(setIdle());
	}

	/// <summary>
	/// Author: -
	/// </summary>
	function resetForm() {
		forgotPasswordUnregister(["username", "phoneNumber"]);
		setFormattedPhoneNumber("");
		setIsModalVisible(false);
	}

	async function onChange(value) {
		let params = {
			username: loginAttemptUsername,
		};
		var loginJson = await ApiEngine.post(
			ApiUrl._API_MANAGE_MEMBER_LOGIN_FAIL_COUNT,
			createFormBody(params)
		);
		if (loginJson[ApiKey._API_SUCCESS_KEY]) {
			setLoginFailCount(0);
			_dispatch(
				showResponseMessage(
					loginJson[ApiKey._API_SUCCESS_KEY],
					loginJson[ApiKey._API_MESSAGE_KEY]
				)
			);
		}
	}

	const onRecaptchaSubmit = () => {
		const recaptchaValue = recaptcharef.current.getValue();
	};

	/// <summary>
	/// Author: Wong
	/// </summary>
	const onChangeLanguage = async (selectedLanguage) => {
		i18n.changeLanguage(selectedLanguage);

		_dispatch(saveLanguageId(selectedLanguage, true));
	};

	const submitForm = async (data, e) => {
		_dispatch(setBusy());
		setLoginAttemptUsername(data.username);
		if (data.isKeepedPassword) {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: data.username,
				gR1IPGBN01dS: data.password,
				PYTLczcty9uH: data.isKeepedPassword,
			});
		} else {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: "fake",
				gR1IPGBN01dS: "null",
				PYTLczcty9uH: false,
			});
		}
		await _dispatch(
			performLogin(
				data.username,
				data.password,
				data.isKeepedLogin,
				activeLanguage
			)
		);
		let params = {
			username: data.username,
		};

		_dispatch(setIdle());
	};

	return (
		<>
			<div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xxl-8 col-lg-10">
							<div className="card overflow-hidden">
								<div className="row g-0">
									<div className="col-lg-12">
										<div className="d-flex flex-column h-100">
											<div className="auth-brand p-4 text-center">
												<a className="logo-dark">
													<img
														src={require("../assets/img/logo.png")}
														alt="logo"
														height="22"
													/>
												</a>
											</div>
											<div className="my-auto">
												<h4 className="fs-20">Sign In</h4>
												<p className="text-muted mb-3">
													Enter your username and password to access
													account.
												</p>

												<form onSubmit={handleSubmit(submitForm)}>
													<div className="mb-3">
														<label for="emailaddress" className="form-label">
															Username
														</label>
														<input
															id="username"
															key="username"
															type="text"
															name="username"
															ref={register({
																required: "PLEASE_ENTER_USERNAME",
															})}
															className="form-control"
															placeholder={t("USERNAME")}
														/>
														{errors.username && (
															<div className="invalid-feedback">
																{t(errors.username.message)}
															</div>
														)}
													</div>
													<div className="mb-3">
														<label for="password" className="form-label">
															Password
														</label>
														<input
															id="password"
															key="password"
															type="password"
															name="password"
															ref={register({
																required: "PLEASE_ENTER_PASSWORD",
															})}
															className="form-control"
															placeholder={t("PASSWORD")}
														/>
														{errors.password && (
															<div className="invalid-feedback">
																{t(errors.password.message)}
															</div>
														)}
													</div>
													<div className="mb-3">
														<div className="form-check">
															<input
																type="checkbox"
																className="form-check-input"
																id="checkbox-signin"
															/>
															<label
																className="form-check-label"
																for="checkbox-signin"
															>
																{t("KEEP_LOGGED_IN")}
															</label>
														</div>
													</div>
													<div className="mb-0 text-start">
														<button
															className="btn btn-soft-primary w-100"
															type="submit"
														>
															<i className="ri-login-circle-fill me-1"></i>{" "}
															<span className="fw-bold">Log In</span>{" "}
														</button>
													</div>

													<div className="text-center mt-4">
														<p className="text-muted fs-16">
															Ver. {process.env.REACT_APP_VERSION}
														</p>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
