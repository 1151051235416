import React, { useState, useRef } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { map, filter, sortBy } from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, _MAIN_WALLET_ID } from "util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import {
    showMessage,
    showResponseMessage,
    setBusy,
    setIdle,
} from "../../redux/AppAction";
import { AlertTypes } from "../../util/Constant";
import {
    stringIsNullOrEmpty,
    numberWithCurrencyFormat,
    createFormBody,
} from "../../util/Util";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import BottomSuccessModal from "../../components/custom/BottomSuccessModal";
import reloadPasswordIcon from "../../assets/img/icon/reset_password.svg";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import UMIcon from "../../assets/img/icon/dashboardv2_um.png";

const GameListCarousel = ({ title, gameList }) => {
    const { t } = useTranslation();
    const _history = useHistory();
    const _dispatch = useDispatch();
    const [packageNameKeyword, setPackageNameKeyword] = useState("");
    const [isNewWindow, setIsNewWindow] = useState(false);
    const [memberData, setMemberData] = useState({});
    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const _USER_DATA = useSelector((s) => s.authState.userData);
    const [showProductPasswordAlert, setShowProductPasswordAlert] =
        useState(false);
    const [showPregamePopup, setShowPregamePopup] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [fromOptions, setFromOptions] = useState([]);
    const [toOptions, setToOptions] = useState([]);
    const [currentInput, setCurrentInput] = useState();
    const [productGameId, setProductGameId] = useState(0);
    const [masterProductId, setMasterProductId] = useState(0);
    const [paidAmount, setPaidAmount] = useState();
    const [transferDate, setTransferDate] = useState();
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);
    const [transcactionNumber, setTranscactionNumber] = useState();
    const [popupBannerImage, setPopupBannerImage] = useState("");
    const [bonus, setBonus] = useState(0);
    const [commission, setCommission] = useState(0);
    const [clubFee, setClubFee] = useState(0);
    const [selectedGameName, setSelectedGameName] = useState("");
    const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
        useState(0);
    const [memberMainWalletBalance, setMemberMainWalletBalance] = useState("");
    const [mpData, setMpData] = useState([]);
    const [isApp, setIsApp] = useState(false);
    const [toLobbyBtn, setToLobbyBtn] = useState(false);
    const [appUrl, setAppUrl] = useState("");
    const [appDeepLink, setAppDeepLink] = useState("");
    const [masterProductContent, setMasterProductContent] = useState("");
    const [conversionRate, setConversionRate] = useState(0);


    let usernameRef = useRef();
    let passwordRef = useRef();

    const _ALERT_TIMEOUT = 3000;

    const providerSlider = {
        infinite: gameList.length > 4,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const {
        handleSubmit: handleSubmitPopup,
        register: registerPopup,
        control: controlPopup,
        watch: watchPopup,
        getValues: getValuesPopup,
        setValue: setValuePopup,
        errors: errorsPopup,
        reset: resetPopup,
    } = useForm();
    // const filterGame = filter(gameList, (item) => {
    //   return item.categoryTitle =  41;
    // });
    const sortedGame = sortBy(gameList, ({ categoryTitle }) =>
        categoryTitle === "Slots" ? 0 : 1
    );

    const { isLoggedIn } = useSelector((state) => ({
        isLoggedIn: state.authState.isLoggedIn,
    }));

    async function updateWalletBalance() {
        var responseJson = await ApiEngine.get(
            ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let tempFromOptions = [];
            let tempToOptions = [];
            responseJson[ApiKey._API_DATA_KEY].map((product) => {
                let option = {
                    label: product.productName,
                    value: product.productId,
                    amount: parseFloat(product.balance),
                };
                if (product.productId === _MAIN_WALLET_ID) {
                    setMemberMainWalletBalance(
                        isNaN(parseFloat(product.balance), 3)
                            ? 0.0
                            : numberWithCurrencyFormat(parseFloat(product.balance), 3, true)
                    );
                    tempFromOptions.push(option);
                } else if (product.productId === masterProductId) {
                    let gameWalletBalance = parseFloat(product.balance);
                    setGameWalletBalanceBeforeTransfer(gameWalletBalance);
                    tempToOptions.push(option);
                }
            });
            setFromOptions(tempFromOptions);
            setToOptions(tempToOptions);
        }
    }

    async function startGame(
        gameId,
        hasOwnLobby,
        masterProductId,
        gameName,
        isSeamless,
        isApp,
        appUrl,
        appDeepLink,
        isNewWindow,
        packageNameKeyword
    ) {
        setSelectedGameName(gameName);
        setMasterProductId(masterProductId);
        setIsApp(isApp);
        setAppDeepLink(appDeepLink);
        setPackageNameKeyword(packageNameKeyword);
        if (isApp && !stringIsNullOrEmpty(appUrl)) {
            setAppUrl(appUrl);
        }
        if (hasOwnLobby !== true) {
            if (isSeamless == false) {
                _dispatch(setBusy());
                var responseJson = await ApiEngine.get(
                    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                    "?masterProductId=" +
                    masterProductId
                );
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                    let tempFromOptions = [];
                    let tempToOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = {
                            label: product.productName,
                            value: product.productId,
                            amount: parseFloat(product.balance),
                        };
                        if (product.productId === _MAIN_WALLET_ID) {
                            tempFromOptions.push(option);
                        } else if (product.productId === masterProductId) {
                            setBonus(product.bonus);
                            setCommission(product.commission);
                            if (product.productName === "D2 Poker") {
                                setClubFee(product.clubFee);
                            }
                            setGameWalletBalanceBeforeTransfer(product.balance);
                            if (product.masterProductContent !== null) {
                                setMasterProductContent(product.masterProductContent);
                            } else {
                                setMasterProductContent("");
                            }
                            tempToOptions.push(option);
                        }
                    });
                    setFromOptions(tempFromOptions);
                    setToOptions(tempToOptions);

                    var mpJson = await ApiEngine(
                        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = mpJson[ApiKey._API_DATA_KEY];
                        setMpData(data);

                        let productName = gameName.replaceAll(' ', '');

                        if (data.hasOwnProperty('conversionRate')) {
                            data['conversionRate'].map((item) => {
                                if (item.key.includes(productName + "ConversionRate")) {
                                    setConversionRate(item.value);
                                }
                            })
                        }

                        if (window.ReactNativeWebView && isApp) {
                            window.ReactNativeWebView.postMessage(
                                JSON.stringify({
                                    action: "deeplink",
                                    url: appUrl,
                                    deeplink: appDeepLink
                                        .replace("[username]", data["username"])
                                        .replace("[password]", data["password"]),
                                    packageKeyword: packageNameKeyword,
                                })
                            );
                        }
                    }
                }
                setProductGameId(gameId);
                var isNewWindowJson = await ApiEngine(
                    ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                );
                // setShowPregamePopup(true);

                setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                setShowPregamePopup(true);

                _dispatch(setIdle());
            } else {
                window.open(
                    `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                    }?gameId=${gameId}&isApp=${isApp ? 1 : 0}&device=d`
                );
            }
        } else {
            if (isSeamless == false) {
                _dispatch(setBusy());
                var responseJson = await ApiEngine.get(
                    ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
                    "?masterProductId=" +
                    masterProductId
                );
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWalletList(responseJson[ApiKey._API_DATA_KEY]);

                    let tempFromOptions = [];
                    let tempToOptions = [];

                    responseJson[ApiKey._API_DATA_KEY].map((product) => {
                        let option = {
                            label: product.productName,
                            value: product.productId,
                            amount: parseFloat(product.balance),
                        };
                        if (product.productId === _MAIN_WALLET_ID) {
                            tempFromOptions.push(option);
                        } else if (product.productId === masterProductId) {
                            setBonus(product.bonus);
                            setCommission(product.commission);
                            if (product.productName === "D2 Poker") {
                                setClubFee(product.clubFee);
                            }
                            setGameWalletBalanceBeforeTransfer(product.balance);
                            if (product.masterProductContent !== null) {
                                setMasterProductContent(product.masterProductContent);
                            } else {
                                setMasterProductContent("");
                            }
                            tempToOptions.push(option);
                        }
                    });
                    setFromOptions(tempFromOptions);
                    setToOptions(tempToOptions);

                    var mpJson = await ApiEngine(
                        ApiUrl._API_GET_MEMBER_PRODUCT_DETAILS +
                        "?masterProductId=" +
                        masterProductId
                    );
                    if (mpJson[ApiKey._API_SUCCESS_KEY]) {
                        let data = mpJson[ApiKey._API_DATA_KEY];
                        setMpData(data);

                        let productName = gameName.replaceAll(' ', '');

                        if (data.hasOwnProperty('conversionRate')) {
                            data['conversionRate'].map((item) => {
                                if (item.key.includes(productName + "ConversionRate")) {
                                    setConversionRate(item.value);
                                }
                            })
                        }
                    }
                }

                var isNewWindowJson = await ApiEngine(
                    ApiUrl._API_GET_GAME_IS_NEW_WINDOW + "?gameId=" + gameId
                );
                setIsNewWindow(isNewWindowJson[ApiKey._API_DATA_KEY]);
                setShowPregamePopup(true);
                setProductGameId(gameId);
                // setShowPregamePopup(true);
                setToLobbyBtn(true);
                _dispatch(setIdle());
            }
        }
    }

    const submitPopupForm = async (data, e) => {
        _dispatch(setBusy());
        let params = {
            fromWallet: _MAIN_WALLET_ID,
            toWallet: masterProductId,
            amount: data.amount,
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
            createFormBody(params)
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            await updateWalletBalance(
                parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
            );
            setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
            setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
            setIsTransferSuccess(true);
            setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);

            e.target.reset();
        } else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }
        _dispatch(setIdle());
    };

    function copyText(textType) {
        if (textType === "username") {
            usernameRef.current.select();
        } else if (textType === "password") {
            passwordRef.current.select();
        }
        document.execCommand("copy");
        setShowCopyAlert(true);
        setTimeout(() => setShowCopyAlert(false), [_ALERT_TIMEOUT]);
    }

    async function resetProductPassword(masterProductId) {
        setMasterProductId(masterProductId);
        let apiUrl =
            ApiUrl._API_CHANGE_PRODUCT_PASSWORD +
            "?masterProductId=" +
            masterProductId;
        let responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setMpData(data);
            setShowProductPasswordAlert(true);
            setTimeout(() => setShowProductPasswordAlert(false), [_ALERT_TIMEOUT]);
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    {title && <h2 className="mb-4">{title}</h2>}
                    {/* <div className="provider-slider">
            <Slider {...providerSlider}>
              {gameList &&
                gameList.length &&
                map(gameList, (item, index) => {
                  return (
                    <div className="provider-item" key={index}>
                      <Link
                        to={
                          item.hasOwnLobby
                            ? WebUrl._URL_GAME_LIST.replace(
                                ":provider",
                                item.gameName
                              )
                            : ""
                        }
                      >
                        <img
                          src={item.image}
                          alt="Game provider"
                          className="img-responsive"
                        />
                      </Link>
                    </div>
                  );
                })}
            </Slider>
          </div> */}
                </div>
            </div>
            <div className="row seven-cols">
                {sortedGame &&
                    sortedGame.length &&
                    map(sortedGame, (game, index) => {
                        return (
                            <div
                                className={
                                    isLoggedIn
                                        ? game["gameUm"] == true
                                            ? "col provider-item mb-4 um-wrapper disabled"
                                            : "col provider-item mb-4"
                                        : "col provider-item mb-4"
                                }
                                key={index}
                                onClick={() => {
                                    if (isLoggedIn) {
                                        setPopupBannerImage(game["popupBannerImage"]);
                                        if (game["gameUm"] !== true) {
                                            startGame(
                                                game["id"],
                                                game["hasOwnLobby"],
                                                game["masterProductId"],
                                                game["gameName"],
                                                game["masterProductIsSeamless"],
                                                game["isApp"],
                                                game["appUrl"],
                                                game["appDeepLink"]
                                            );
                                        }
                                    } else if (game.hasOwnLobby && game.masterProductIsSeamless) {
                                        _history.push(WebUrl._URL_GAME_LIST.replace(
                                            ":provider",
                                            game.gameName));
                                    }
                                    else if (!game.hasOwnLobby) {
                                        _dispatch(
                                            showMessage({
                                                type: AlertTypes._INFO,
                                                content: t("PLEASE_LOGIN_FIRST"),
                                                onConfirm: () => {
                                                    //console.log("hi");
                                                },
                                            })
                                        );
                                    }
                                }}
                            >
                                <Link
                                    className={
                                        isLoggedIn ? (game["gameUm"] == true ? "game-um" : "") : ""
                                    }
                                    to={
                                        game.hasOwnLobby && game.masterProductIsSeamless
                                            ? WebUrl._URL_GAME_LIST.replace(
                                                ":provider",
                                                game.gameName)
                                            : {}
                                        // game.hasOwnLobby 
                                        // ? WebUrl._URL_GAME_LIST.replace(
                                        //     ":provider",
                                        //     game.gameName
                                        //   )
                                        // : ""
                                    }
                                >
                                    {/* <div className={ game["gameUm"] == "true" && "desktop" }> */}
                                    <div className="desktop">
                                        <div
                                            className="img-100"
                                            style={{
                                                backgroundImage:
                                                    (isLoggedIn
                                                        ? game["gameUm"] == true
                                                            ? "linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), "
                                                            : " "
                                                        : " ") + `url(${encodeURI(game.image)})`,
                                                position: "relative",
                                                backgroundPosition: "center",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                borderRadius: "16px",
                                            }}
                                            alt="Game provider"
                                        >
                                            {isLoggedIn && game["gameUm"] == true && (
                                                <>
                                                    {/* <div className="v2-um-tag game-category-label"></div> */}
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${UMIcon})`,
                                                        }}
                                                        className="v2-um-tag-icon"
                                                    ></div>
                                                </>
                                            )}
                                            <img
                                                src={game.image}
                                                alt="Game provider"
                                                className="img-100"
                                                style={{ visibility: "hidden" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-white font-semi font-14 mt-3">
                                        {game.gameName}
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
            </div>
            <Modal
                id="pregame-popup"
                fade={false}
                contentClassName="pregame-popup"
                isOpen={showPregamePopup}
                toggle={() => {
                    setShowPregamePopup(false);
                    setToLobbyBtn(false);
                }}
                centered
            >
                <ModalHeader className="m-auto border-0">
                    <div className="modal-header-wrapper">
                        <span className="font-20 text-white font-bold">
                            {selectedGameName}
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody style={{ padding: "0px 16px 41px" }}>
                    <form onSubmit={handleSubmitPopup(submitPopupForm)}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex wallet-info text-center text-white font-bold">
                                    <div className="col-6 border-right">
                                        {fromOptions.length > 0 && (
                                            <>
                                                <span className="font-15">{fromOptions[0].label}</span>
                                                <br />
                                                <span className="font-16">
                                                    {numberWithCurrencyFormat(fromOptions[0].amount, 3)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-6">
                                        {toOptions.length > 0 && (
                                            <>
                                                <span className="font-15">{toOptions[0].label}</span>
                                                <br />
                                                <span className="font-16">
                                                    {numberWithCurrencyFormat(toOptions[0].amount, 3)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 d-flex">
                                    <div className="col-8">
                                        <div className="pregame-transfer-input">
                                            <input
                                                type="text"
                                                className="custom-input-style-2"
                                                placeholder={"0.00"}
                                                name="amount"
                                                onClick={(e) => {
                                                    setCurrentInput(e.target);
                                                }}
                                                ref={registerPopup({
                                                    required: "PLEASE_ENTER_AMOUNT",
                                                    validate: {
                                                        minValue: (value) =>
                                                            parseFloat(value) > 0 ||
                                                            "PLEASE_ENTER_VALUE_MORE_THAN_ZERO",
                                                    },
                                                })}
                                            />
                                            {errorsPopup.amount && (
                                                <div className="invalid-feedback">
                                                    {t(errorsPopup.amount.message)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-4 pregame-transfer-btn">
                                        <button type="submit" className="btn btn-brand-gradient">
                                            {t("TRANSFER")}
                                        </button>
                                    </div>
                                </div>

                                <div className="col-12 d-flex transfer-balance font-15 text-white font-bold">
                                    <div>{selectedGameName + " " + t("NEW_BALANCE") + " : "}
                                        {selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888" ?
                                            <div className="reminder font-11 text-white font-semi-italic">
                                                1 THB = {conversionRate} point
                                            </div>
                                            : ""
                                        }
                                    </div>

                                    <div>
                                        {
                                            selectedGameName == '918Kiss' || selectedGameName == "D2 Poker" || selectedGameName == "Pussy888" || selectedGameName == "Mega888"
                                                ?
                                                watchPopup("amount") > 0 &&
                                                numberWithCurrencyFormat(
                                                    parseFloat(toOptions[0].amount) +
                                                    parseFloat(watchPopup("amount")) * conversionRate,
                                                    2,
                                                    true
                                                )
                                                :
                                                watchPopup("amount") > 0 &&
                                                numberWithCurrencyFormat(
                                                    parseFloat(toOptions[0].amount) +
                                                    parseFloat(watchPopup("amount")),
                                                    2,
                                                    true
                                                )
                                        }
                                        {" "}
                                        {watchPopup("amount") == "" && "-"}
                                    </div>

                                    {/* <div>
                    {watchPopup("amount") > 0 &&
                      numberWithCurrencyFormat(
                        // parseFloat(toOptions[0].amount) +
                        toOptions.length > 0
                          ? parseFloat(toOptions[0].amount) +
                              parseFloat(watchPopup("amount"))
                          : parseFloat(0) + parseFloat(watchPopup("amount")),
                        2,
                        true
                      )}{" "}
                    {watchPopup("amount") == "" && "-"}
                  </div> */}
                                </div>
                            </div>
                            {masterProductContent.length > 1 && !stringIsNullOrEmpty(masterProductContent) && (
                                <div className="masterProductContent text-white">{masterProductContent}</div>
                            )}
                        </div>
                    </form>
                    <div className="container">
                        <div className="row">
                            {(bonus > 0 || commission > 0 || clubFee > 0) && (
                                <div className="col-12 d-flex bonus-commission font-12 text-white">
                                    {bonus > 0 && (
                                        <div className="bonus">
                                            {t("BONUS") +
                                                " (%) : " +
                                                numberWithCurrencyFormat(parseFloat(bonus), 2, true) +
                                                "%"}
                                            &nbsp;&nbsp;
                                        </div>
                                    )}

                                    {bonus > 0 && commission > 0 && <div>{"/"}&nbsp;&nbsp;</div>}

                                    {commission > 0 && (
                                        <div className="commission">
                                            {t("COMMISSION") +
                                                " (%) : " +
                                                numberWithCurrencyFormat(
                                                    parseFloat(commission),
                                                    2,
                                                    true
                                                ) +
                                                "%"}
                                            &nbsp;&nbsp;
                                        </div>
                                    )}

                                    {commission > 0 && clubFee > 0 && (
                                        <div>{"/"}&nbsp;&nbsp;</div>
                                    )}

                                    {clubFee > 0 && (
                                        <div className="clubFee">
                                            {t("CLUB_FEE") +
                                                " (%) : " +
                                                numberWithCurrencyFormat(parseFloat(clubFee), 2, true) +
                                                "%"}
                                        </div>
                                    )}
                                </div>
                            )}
                            {isApp && appDeepLink !== "" && (
                                <>
                                    <div className="col-12" style={{ paddingBottom: "20px" }}>
                                        <hr
                                            style={{
                                                backgroundColor: "#ffc159",
                                                width: "90%",
                                                padding: "0.5px 0",
                                            }}
                                        />
                                        <div
                                            className="text-center"
                                            style={{
                                                marginTop: "-28px",
                                            }}
                                        >
                                            <span
                                                className="font-16 text-gold font-bold"
                                                style={{
                                                    backgroundColor: "#001A3D",
                                                    padding: "0 25px",
                                                }}
                                            >
                                                {selectedGameName + " " + t("ACCOUNT")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="username-and-password">
                                        <div className="details username">
                                            <div className="details-label">
                                                {t("USERNAME") + " : "}
                                            </div>
                                            <input
                                                type="text"
                                                className="username-input"
                                                value={mpData["username"]}
                                                ref={usernameRef}
                                                readOnly
                                            />
                                            <div
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => copyText("username")}
                                            >
                                                <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                                            </div>
                                        </div>
                                        <div className="details password">
                                            <div className="details-label">
                                                {t("PASSWORD") + " : "}
                                            </div>
                                            <input
                                                type="text"
                                                className="password-input"
                                                value={mpData["password"]}
                                                ref={passwordRef}
                                                readOnly
                                            />
                                            <div
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => copyText("password")}
                                            >
                                                <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                                            </div>
                                            <div
                                                className="refresh-password-btn"
                                                style={{
                                                    backgroundImage: `url(${reloadPasswordIcon})`,
                                                }}
                                                onClick={() => resetProductPassword(masterProductId)}
                                            >
                                                <div className="tooltip-text">
                                                    <span>{t("REFRESH_PASSWORD")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {selectedGameName == "Mega888" && (
                                <>
                                    <div className="col-12" style={{ paddingBottom: "20px" }}>
                                        <hr
                                            style={{
                                                backgroundColor: "#ffc159",
                                                width: "90%",
                                                padding: "0.5px 0",
                                            }}
                                        />
                                        <div
                                            className="text-center"
                                            style={{
                                                marginTop: "-28px",
                                            }}
                                        >
                                            <span
                                                className="font-16 text-gold font-bold"
                                                style={{
                                                    backgroundColor: "#001A3D",
                                                    padding: "0 25px",
                                                }}
                                            >
                                                {selectedGameName + " " + t("ACCOUNT")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="username-and-password">
                                        <div className="details username">
                                            <div className="details-label">
                                                {t("USERNAME") + " : "}
                                            </div>
                                            <input
                                                type="text"
                                                className="username-input"
                                                value={mpData["username"]}
                                                ref={usernameRef}
                                                readOnly
                                            />
                                            <div
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => copyText("username")}
                                            >
                                                <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                                            </div>
                                        </div>
                                        <div className="details password">
                                            <div className="details-label">
                                                {t("PASSWORD") + " : "}
                                            </div>
                                            <input
                                                type="text"
                                                className="password-input"
                                                value={mpData["password"]}
                                                ref={passwordRef}
                                                readOnly
                                            />
                                            <div
                                                style={{ marginLeft: "10px" }}
                                                onClick={() => copyText("password")}
                                            >
                                                <Icon path={mdiContentCopy} size={1} color="#ffc159" />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="col-12 d-flex start-btn-wrapper">
                                {!isApp || (isApp && appDeepLink != "") ? (
                                    <>
                                        {!toLobbyBtn ? (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (!window.ReactNativeWebView) {
                                                        // _history.push({
                                                        //   pathname: WebUrl._URL_GAME_PAGE,
                                                        //   search: "?gameId=" + productGameId,
                                                        //   state: { url: window.location.pathname },
                                                        // });
                                                        window.open(
                                                            `${process.env.REACT_APP_ENDPOINT_URL || ""}${ApiUrl._API_START_GAME
                                                            }?gameId=${productGameId}&isApp=0&device=d`
                                                        );
                                                    } else {
                                                        window.ReactNativeWebView.postMessage(
                                                            JSON.stringify({
                                                                url:
                                                                    window.location.origin +
                                                                    WebUrl._URL_GAME_PAGE +
                                                                    "?gameId=" +
                                                                    productGameId,
                                                            })
                                                        );
                                                    }
                                                }}
                                                className="btn pregame-popup-btn btn-block btn-brand"
                                            >
                                                {t("START_GAME")}
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    _history.push(
                                                        WebUrl._URL_GAME_LIST.replace(
                                                            ":provider",
                                                            selectedGameName
                                                        )
                                                    );
                                                    // _history.push(
                                                    //   WebUrl._URL_GAME_LOBBY +
                                                    //     "?name=" +
                                                    //     selectedGameName +
                                                    //     "&id=" +
                                                    //     productGameId +
                                                    //     "&languageId=" +
                                                    //     memberData["languageSetting"]
                                                    // );
                                                }}
                                                className="btn pregame-popup-btn btn-block btn-brand"
                                            >
                                                {t("TO_LOBBY", { productName: selectedGameName })}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            if (window.ReactNativeWebView) {
                                                window.ReactNativeWebView.postMessage(
                                                    JSON.stringify({ url: appUrl })
                                                );
                                            } else {
                                                window.open(appUrl);
                                            }
                                        }}
                                        className="btn pregame-popup-btn btn-block btn-brand"
                                    >
                                        {t("DOWNLOAD_APP")}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="pregame-popup-close-btn"
                        onClick={() => {
                            setShowPregamePopup(false);
                            setToLobbyBtn(false);
                        }}
                    >
                        <img
                            src={require("../../assets/img/assets/close.svg")}
                            className="img-responsive"
                            alt="close"
                        />
                    </div>
                </ModalBody>
            </Modal>
            <BottomSuccessModal
                isOpen={isTransferSuccess}
                setIsOpen={setIsTransferSuccess}
                title={"TRANSFER_SUCCESS"}
                amount={paidAmount}
                date={transferDate}
                transactionNumber={transcactionNumber}
            />
        </div>
    );
};

GameListCarousel.propTypes = {
    title: PropTypes.string,
    gameList: PropTypes.array,
};

GameListCarousel.defaultProps = {
    gameList: [],
};

export default GameListCarousel;
