import React, { useState, useEffect } from "react";
import NormalDeposit from "./NormalDeposit";
import TNGDeposit from "./TNGDeposit";
import InstantDeposit from "./InstantDeposit";
import IBayarDeposit from "./IBayarDeposit";
import SPGDeposit from "./SPGDeposit";
import VaderDeposit from "./VaderDeposit";
import SPGQRDeposit from "./SPGQRDeposit";
import VaderQRDeposit from "./VaderQRDeposit";
import FattyPayDeposit from "./FattyPayDeposit";
import FattyPayQRDeposit from "./FattyPayQRDeposit";
import PowerPayDeposit from "./PowerPayDeposit";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar";
import { isEmpty, isNull, map } from "lodash";
import ContentHeader from "components/content/header";
import ApiEngine from "../../util/ApiEngine";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, SessionKey, WebUrl,
    LanguageOption, } from "../../util/Constant";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Modal,
    ModalBody,
} from "reactstrap";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import {
    showResponseMessage,
    showResponseMessageWithoutT,
} from "../../redux/AppAction";
import classnames from "classnames";
import TngIcon from "../../assets/img/assets/normal1.svg";
import NormalIcon from "../../assets/img/assets/normal2.svg";
import PayTrustIcon from "../../assets/img/assets/payment2.svg";
import VaderPayIcon from "../../assets/img/assets/payment4.svg";
import VaderPayQRIcon from "../../assets/img/assets/VaderPay_QR.svg";
import SPGIcon from "../../assets/img/assets/SPG.svg";
import FattyPayIcon from "../../assets/img/assets/FattyPay.svg";
import FattyPayQRIcon from "../../assets/img/assets/truepayWhite.svg";
import PowerPayicon from "../../assets/img/icon/PowerPay.png";
import SPGQRIcon from "../../assets/img/assets/SPG_QR.svg";
import OfflineIcon from "../../assets/img/icon/Offline_Icon.png";
import { Link } from "react-router-dom";
import { mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Slider from "react-slick";

function CustomToggle({ children, eventKey }) {
    const wrapOnClick = useAccordionButton(eventKey, () =>
        console.log("totally custom!")
    );

    return <div onClick={wrapOnClick}>{children}</div>;
}

const Deposit = () => {
    const { t } = useTranslation();
    const _dispatch = useDispatch();
    const { setValue } = useForm();

    const {
        handleSubmit: handleFullNameSubmit,
        register: registerFullName,
        getValues: getValuesFullName,
        errors: errorsFullName,
        setValue: setValueFullName,
    } = useForm();

    const _INSTANT_DEPOSIT = 0;
    const _NORMAL_DEPOSIT = -1;
    const _TNG_DEPOSIT = -2;
    const _SPG_QR = -3;
    const _VADERPAY_QR = -4;
    const _INSTANT_PAYMENT = 1;
    const _NORMAL_UPLOAD_DEPOSIT = 2;
    const _QR_DEPOSIT = 3;
    const [headerMsg, setHeaderMsg] = useState("");

    const [instantPayOption, setInstantPayOption] = useState([]);
    const [tempInstantPayOption, setTempInstantPayOption] = useState([]);
    //const [tempNormalPayOption, setTempNormalPayOption] = useState([]);
    const [paymentOptionList, setPaymentOptionList] = useState([]);
    const [paymentOption, setPaymentOption] = useState(_INSTANT_DEPOSIT);
    const [activeTab, setActiveTab] = useState(_INSTANT_PAYMENT);
    const [showFullnamePopup, setShowFullnamePopup] = useState(false);
    const [memberId, setMemberId] = useState("");
    const [tngStartTime, setTngStartTime] = useState(moment());
    const [tngEndTime, setTngEndTime] = useState(moment());
    const [activePaymentOption, setActivePaymentOption] = useState();
    const [tngStatus, setTngStatus] = useState(false);
    const [transType, setTransType] = useState("");
    const currentHourInUTC0 = moment();
    const [countdownPeriod, setCountdownPeriod] = useState(0);
    const [hasPendingTxn, setHasPendingTxn] = useState(false);
    const [countdownStr, setCountdownStr] = useState("");
    const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
    const [countdownTime, setCountdownTime] = useState();
    const _SECONDS_IN_MS = 1000;

    const [bonusDepositList, setBonusDepositList] = useState([]);
    const [bonusDeposit, setBonusDeposit] = useState("");
    const [bonusDepositName, setBonusDepositName] = useState("");
    const [showPaymentOption, setShowPaymentOption] = useState(false);
    let _userData = useSelector((state) => state.authState.userData);

    var TNG_START_HOUR_LIMIT_IN_UTC0 = 0;
    var TNG_END_HOUR_LIMIT_IN_UTC0 = 23;
    //var vaderPayStartTime = moment(
    //  moment().format("YYYY-MM-DD") + " 00:30:00.000"
    //);
    //var vaderPayEndTime = moment(moment().format("YYYY-MM-DD") + " 23:30:00.000");

    const toggleTab = async (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        //await togglePaymentOptionList(tab);
    };

    const bannerSlick = {
        slidesToShow: 3,
        dots: true,
        arrows: false,
        swipe: true,
        infinite: false,
        swipeToSlide: true,
        speed: 1000,
        // autoplay: true,
    };

    useEffect(() => {
        init();
    }, [(localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en')]);

    useEffect(() => {
        if (countdownPeriod > 0) {
            clearTimeout(countdownTime);
            setCountdownTime(
                setTimeout(() => {
                    let tempPeriod = countdownPeriod - 1;
                    setCountdownPeriod(tempPeriod);
                    let tempX = moment.duration(tempPeriod);
                    let tempMM = Math.floor(tempX["_milliseconds"] / 60);
                    let tempSS = tempX["_milliseconds"] - tempMM * 60;
                    setTimeDiffSeconds(tempX["_milliseconds"]);
                    let tempStr =
                        (tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
                        " " +
                        t("MINUTES") +
                        " " +
                        (tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
                        " " +
                        t("SECOND");
                    setCountdownStr(tempStr);

                }, _SECONDS_IN_MS)
            );
        }
    }, [countdownPeriod]);

    async function showTngOffline() {
        _dispatch(
            showResponseMessageWithoutT(
                false,
                t("TNG_OFFLINE", { startTime: tngStartTime, endTime: tngEndTime })
            )
        );
    }

    async function showTngUnavailable() {
        _dispatch(showResponseMessageWithoutT(false, t("TNG_UNAVAILABLE")));
    }

    const init = () => {
        (async function () {
            let memberJson = await ApiEngine.get(ApiUrl._API_GET_USER_FULLNAME);
            if (memberJson[ApiKey._API_SUCCESS_KEY]) {
                setMemberId(memberJson[ApiKey._API_DATA_KEY]["memberId"]);
                if (
                    stringIsNullOrEmpty(memberJson[ApiKey._API_DATA_KEY]["userFullName"])
                ) {
                    setShowFullnamePopup(true);
                }
            }
            let tempOptions = [];
            let responseJson = await ApiEngine.get(
                ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setInstantPayOption(responseJson[ApiKey._API_DATA_KEY]);

                let temp = responseJson[ApiKey._API_DATA_KEY].findIndex(
                    (x) => x.instantPayGatewayString === activePaymentOption
                );

                setPaymentOption(temp);
                responseJson[ApiKey._API_DATA_KEY].map((option, index) => {
                    option["status"] === true &&
                        tempOptions.push({
                            key: index,
                            value: option.id,
                            label: option.instantPayGatewayString,
                            image: option.image,
                            fee: option.serviceCharge,
                            paymentType: option.instantPayGatewayString.endsWith(" QR") || option.instantPayGatewayString == "TruePay"
                                ? t("DUIT_NOW")
                                : t("INSTANT_PAYMENT"),
                        });
                });
                setTempInstantPayOption(tempOptions);
                //let tempNormalOptions = [];
                let TnGresponseJson = await ApiEngine.get(
                    ApiUrl._API_GET_COMPANY_TNG_SYSTEM_SETTINGS
                );
                let startTimeStr = "";
                let endTimeStr = "";
                let TnGStatus = false;

                if (
                    TnGresponseJson[ApiKey._API_SUCCESS_KEY] &&
                    TnGresponseJson[ApiKey._API_DATA_KEY].length > 0
                ) {
                    let data = TnGresponseJson[ApiKey._API_DATA_KEY];

                    data.map((keyValuePair, index) => {
                        if (keyValuePair["key"] === "TnGStartTime") {
                            startTimeStr = keyValuePair["value"];
                            setTngStartTime(keyValuePair["value"]);
                            TNG_START_HOUR_LIMIT_IN_UTC0 = moment(
                                moment().format("YYYY-MM-DD") + " " + startTimeStr,
                                "YYYY-MM-DD HH:mm:ss"
                            );
                        }
                        if (keyValuePair["key"] === "TnGEndTime") {
                            endTimeStr = keyValuePair["value"];
                            setTngEndTime(keyValuePair["value"]);
                            TNG_END_HOUR_LIMIT_IN_UTC0 = moment(
                                moment().format("YYYY-MM-DD") + " " + endTimeStr,
                                "YYYY-MM-DD HH:mm:ss"
                            );
                        }
                        if (keyValuePair["key"] === "TnGStatus") {
                            TnGStatus = keyValuePair["value"] === "true";
                        }
                    });
                }

                if (
                    moment(TNG_END_HOUR_LIMIT_IN_UTC0).isBefore(
                        TNG_START_HOUR_LIMIT_IN_UTC0
                    )
                ) {
                    TNG_END_HOUR_LIMIT_IN_UTC0 = moment(TNG_END_HOUR_LIMIT_IN_UTC0).add(
                        1,
                        "day"
                    );
                }

                if (
                    TnGStatus &&
                    currentHourInUTC0.isAfter(TNG_START_HOUR_LIMIT_IN_UTC0) &&
                    currentHourInUTC0.isBefore(TNG_END_HOUR_LIMIT_IN_UTC0)
                ) {
                    //tempNormalOptions.push({ key: _TNG_DEPOSIT, value: _TNG_DEPOSIT, label: "Touch N Go (" + (moment(TNG_START_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + "- " + (moment(TNG_END_HOUR_LIMIT_IN_UTC0).format("HH:mm")) + ") GMT+8", image: null, fee: 0 });
                    tempOptions.push({
                        key: _TNG_DEPOSIT,
                        value: _TNG_DEPOSIT,
                        label: "TnG",
                        image: null,
                        paymentType: t("NORMAL_DEPOSIT"),
                        fee: 0,
                    });
                    setTngStatus(true);
                }

                tempOptions.push({
                    key: _NORMAL_DEPOSIT,
                    value: _NORMAL_DEPOSIT,
                    label: "Normal",
                    image: null,
                    paymentType: t("NORMAL_DEPOSIT"),
                    fee: 0,
                });

                // tempOptions.push({
                //   key: _SPG_QR,
                //   value: 3,
                //   label: "SPG QR",
                //   image: null,
                //   fee: 0,
                // });

                // tempOptions.push({
                //   key: _VADERPAY_QR,
                //   value: 4,
                //   label: "VaderPay QR",
                //   image: null,
                //   fee: 0,
                // });

                //setTempNormalPayOption(tempNormalOptions);
                if (
                    tempOptions.filter((x) => x.paymentType === t("INSTANT_PAYMENT"))
                        .length <= 0
                ) {
                    setActiveTab(_NORMAL_DEPOSIT);
                }
                setValue("paymentMethod", tempOptions[0].value);
                setPaymentOptionList(tempOptions);
            }

            setHeaderMsg("");
            var pendingTransactionJson = await ApiEngine.get(
                ApiUrl._API_GET_PENDING_TRANSACTION
            );
            if (pendingTransactionJson[ApiKey._API_SUCCESS_KEY]) {
                if (pendingTransactionJson[ApiKey._API_DATA_KEY] != null) {
                    clearTimeout(countdownTime);
                    let tempS = parseInt(pendingTransactionJson[ApiKey._API_DATA_KEY]["timeSpan"]);
                    setTransType(pendingTransactionJson[ApiKey._API_DATA_KEY]["transType"]);
                    setTimeDiffSeconds(tempS);
                    setCountdownPeriod(tempS < 0 ? 0 : tempS);
                    setHasPendingTxn(true);
                    setHeaderMsg(t("NEW_TXN_PROHIBITED"));
                }
            } else {
                _dispatch(
                    showResponseMessage(false, pendingTransactionJson[ApiKey._API_MESSAGE_KEY])
                );
            }

            //console.log("here");
            var language = (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0] !== undefined) ? (LanguageOption.filter(option => option.value == localStorage.getItem(SessionKey._LANGUAGE))[0].languageId) : 7;
            var getBonusDeposit = await ApiEngine.get(
                ApiUrl._API_GET_BONUS_DEPOSIT + "?memberId=" + _userData.userId + "&languageId=" + language
            );
            //console.log(getBonusDeposit);
          if (getBonusDeposit[ApiKey._API_SUCCESS_KEY]) {
            let data = getBonusDeposit[ApiKey._API_DATA_KEY];
            let itemList = []

            let item = null;
            data.map((row, index) => {
              item =
              {
                name: row["bonusName"],
                bonusId: row["id"],
                info:
                {
                  bonus: row["bonus"],
                  minimumDeposit: row["minimumDeposit"],
                  rollover: row["rollover"],
                  maxBonus: row["maxBonus"],
                  freeSpinStatus: row["freeSpinStatus"],
                  maxLimitPerMember: row["maxLimitPerMember"],
                  dayLimit: row["dayLimit"],
                  image: row["bonusImage"],
                  popImage: row["popupImage"],
                  remainingCount: row["remainingCount"],
                  refreshDateTime: row["refreshDateTime"] !== "" ? moment(row["refreshDateTime"], "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss") : ""
                }
              }
              itemList.push(item);

            })
            setBonusDepositList(itemList)
          } else {
            _dispatch(
              showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
            );
          }

        })();
    };



    const togglePaymentOptionList = async (tab) => {
        if (tab === _INSTANT_PAYMENT) {
            setValue("paymentMethod", tempInstantPayOption[0].value);
            setPaymentOption(0);
            setPaymentOptionList(tempInstantPayOption);
        } else if (tab === _NORMAL_UPLOAD_DEPOSIT) {
            //setValue("paymentMethod", tempNormalPayOption[0].value);
            //setPaymentOption(tempNormalPayOption[0].value);
            //(tempNormalPayOption);
        }
    };

    const toggleActivePaymentOption = (selectedPaymentOption, tab) => {
        toggleTab(tab);
        setActivePaymentOption(selectedPaymentOption);
        let temp = paymentOptionList.find(
            (x) => x.label === selectedPaymentOption
        ).value;
        if (tab === _INSTANT_PAYMENT) {
            let tempId = instantPayOption.findIndex((x) => x.id === temp);
            setPaymentOption(tempId);
        } else if (tab === _QR_DEPOSIT) {
            let tempId = instantPayOption.findIndex((x) => x.id === temp);
            setPaymentOption(tempId);
        } else {
            setPaymentOption(temp);
        }
        setValue("paymentMethod", temp);
    };

    async function submitFullName(data) {
        let params = {
            userFullName: data.userFullName,
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_MEMBER_USERFULLNAME,
            createFormBody(params)
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );

            setShowFullnamePopup(false);
        } else {
            _dispatch(
                showResponseMessage(
                    responseJson[ApiKey._API_SUCCESS_KEY],
                    responseJson[ApiKey._API_MESSAGE_KEY]
                )
            );
        }

        init();
    }

    async function updateBonusDeposit(menu) {
        setBonusDeposit(menu["bonusId"]);
        setBonusDepositName(menu["name"]);
        setShowPaymentOption(true);
        toggleActivePaymentOption(paymentOptionList.filter(x => x.paymentType === "Instant Payment")[0].label, _INSTANT_PAYMENT)
    }

    return (
        <section className="section-padding" id="deposit-section">
            <div className="container">
                <div className="row">
                    <div className="col-3" id="account-menu">
                        <Sidebar activePage={"WALLET"} />
                    </div>
                    <div className="col-9">
                        <ContentHeader enableBackButton title={t("DEPOSIT")} />
                        <hr className="right-title-hr" />

                        {hasPendingTxn && headerMsg && (
                            <div className="alert alert-warning">
                                {timeDiffSeconds > 0
                                    ? t("PLEASE_WAIT") +
                                    " " +
                                    countdownStr +
                                    " " +
                                    (transType === "Deposit"
                                        ? t("FOR_DEPOSIT_APPROVAL")
                                        : t("FOR_WITHDRAWAL_APPROVAL"))
                                    : headerMsg}
                            </div>
                        )}

                        <Accordion defaultActiveKey="0" className="accordion-container">

                            <Accordion.Item className="accordion-bonusdeposit" eventKey="0">
                                <Accordion.Header>
                                    <div>
                                        <h4 className="font-15 mb-0">
                                            {t("BONUS_DEPOSIT")}
                                                {!stringIsNullOrEmpty(bonusDeposit)
                                                    ? bonusDeposit != "-1"
                                                        ? ": " + bonusDepositName
                                                        : ": " + t("NONE")
                                                    : ""}
                                        </h4>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="bonusdeposit-menu">
                                        <div className="font-11 mb-3" style={{color: '#5c708a'}}>
                                          {t("SELECT_A_PAYMENT")}
                                        </div>
                                        <Slider {...bannerSlick} className="deposit-slick">
                                            {bonusDepositList.map((menu, index) => {
                                                return (
                                                    <>
                                                        <CustomToggle eventKey="1">
                                                            <div
                                                                key={index}
                                                                className={"deposit-slick-item" + (bonusDeposit == menu["bonusId"] ? ' active': '')}
                                                                onClick={() =>
                                                                  updateBonusDeposit(menu)
                                                                }
                                                              >
                                                                <div className="deposit-bonus-image">
                                                                    <img
                                                                        src={menu["info"]["image"]}
                                                                        className="w-100"
                                                                        alt={menu["info"]["bonus"]}
                                                                    />
                                                                </div>
                                                                <div className="deposit-bonus-details">
                                                                    {menu["info"] &&
                                                                        <>
                                                                            <div> {t("BONUS") + " (%) : " + menu["info"]["bonus"]}</div>
                                                                            <div> {t("MINIMUM_DEPOSIT") + " : RM" + menu["info"]["minimumDeposit"]}</div>
                                                                            <div> {t("ROLLOVER") + " : x" + menu["info"]["rollover"]}</div>
                                                                            {menu["info"]["bonus"] > 0 && menu["info"]["maxBonus"] && <div> {t("MAX_BONUS") + " : " + menu["info"]["maxBonus"]}&#3647;</div>}
                                                                            {/* <div> {t("ELIGIBLE_FOR_FREE_SPIN") + " : " + (menu["info"]["freeSpinStatus"] ? t("YES") : t("NO"))}</div> */}
                                                                            <div> {menu["info"]["maxLimitPerMember"] !== 0 && t("CLAIM_LIMIT") + " : " + menu["info"]["maxLimitPerMember"] + " " + t("TIMES") + (menu["info"]["remainingCount"] > 0 ? " (" + menu["info"]["remainingCount"] + " " + t("LEFT") + ")" : "")} </div>
                                                                            <div> {menu["info"]["maxLimitPerMember"] !== 0 && menu["info"]["dayLimit"] !== 0 && <span className="claim-limit-reminder">{"*" + t("LIMIT_REFRESHES_EVERY_FEW_DAYS", { value: menu["info"]["dayLimit"] })}</span>} </div>
                                                                            <div> {menu["info"]["maxLimitPerMember"] !== 0 && menu["info"]["dayLimit"] !== 0 && menu["info"]["refreshDateTime"] !== "" && <span className="claim-limit-reminder">{menu["info"]["refreshDateTime"] !== "" && "*" + t("REFRESHES_ON") + " " + menu["info"]["refreshDateTime"]}</span>} </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </CustomToggle>
                                                    </>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            {showPaymentOption && (<><Accordion.Item className="accordion-bonusdeposit" eventKey="1">
                                <Accordion.Header className="d-none" onClick={() => { toggleActivePaymentOption(paymentOptionList.filter(x => x.paymentType === "Instant Payment")[0].label, _INSTANT_PAYMENT) }}>
                                    <div class="font-15">{t("PAYMENT_METHOD")}</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="payment-method-box">
                                        <div className="row" style={{ justifyContent: "space-between", padding: "0px 10px" }}>
                                            <h4 className="font-15 mb-3">{t("PAYMENT_METHOD")}</h4>
                                            <Link to={WebUrl._URL_DEPOSIT_RECORD}>
                                                <div className="d-flex justify-content-end align-items-center deposit-record-btn">
                                                    <div style={{ marginRight: "5px", fontSize: "12px" }}> {t("DEPOSIT_RECORD")} </div>
                                                    <Icon
                                                        path={mdiHistory}
                                                        title="User Profile"
                                                        size={1}
                                                    />
                                                </div>
                                            </Link>
                                        </div>

                                        {paymentOptionList.length > 0 && (
                                            <>
                                                <Nav className="nav nav-tabs" id="my-tabs">
                                                    {paymentOptionList.filter(
                                                        (x) => x.paymentType === t("INSTANT_PAYMENT")
                                                    ).length > 0 && (
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTab === _INSTANT_PAYMENT,
                                                                    })}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setActiveTab(_INSTANT_PAYMENT); //todo
                                                                        let temp = paymentOptionList.filter((x) => x.paymentType === "Instant Payment")[0].label;
                                                                        toggleActivePaymentOption(
                                                                            temp,
                                                                            _INSTANT_PAYMENT
                                                                        );
                                                                    }}
                                                                >
                                                                    {t("INSTANT_PAYMENT")}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}

                                                    {paymentOptionList.filter(
                                                        (x) => x.paymentType === t("NORMAL_DEPOSIT")
                                                    ).length > 0 && (
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTab === _NORMAL_UPLOAD_DEPOSIT,
                                                                    })}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setActiveTab(_NORMAL_UPLOAD_DEPOSIT);
                                                                        if (tngStatus == true) {
                                                                            toggleActivePaymentOption(
                                                                                "TnG",
                                                                                _NORMAL_UPLOAD_DEPOSIT
                                                                            );
                                                                        } else {
                                                                            toggleActivePaymentOption(
                                                                                "Normal",
                                                                                _NORMAL_UPLOAD_DEPOSIT
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {t("NORMAL_DEPOSIT")}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}

                                                    {paymentOptionList.filter(
                                                        (x) => x.paymentType === t("DUIT_NOW")
                                                    ).length > 0 && (
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTab === _QR_DEPOSIT,
                                                                    })}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setActiveTab(_QR_DEPOSIT);
                                                                        toggleActivePaymentOption(paymentOptionList.filter(
                                                                            (x) => x.paymentType === t("DUIT_NOW")
                                                                        )[0].label, _QR_DEPOSIT);
                                                                    }}
                                                                >
                                                                    {t("DUIT_NOW")}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                </Nav>

                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId={_INSTANT_PAYMENT}>
                                                        <div className="payment-methods-radio">
                                                            {paymentOptionList
                                                                .filter(
                                                                    (x) => x.paymentType === t("INSTANT_PAYMENT")
                                                                )
                                                                .map((option, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            {option["label"].startsWith("VaderPay") ? (
                                                                                //moment() > vaderPayStartTime &&
                                                                                //moment() < vaderPayEndTime && (
                                                                                <div
                                                                                    key={"instant_payment_option" + index}
                                                                                >
                                                                                    <div className="form-checkbox">
                                                                                        <div
                                                                                            id={"method" + index}
                                                                                            className={
                                                                                                "option " +
                                                                                                classnames({
                                                                                                    active:
                                                                                                        activePaymentOption ===
                                                                                                        option["label"],
                                                                                                })
                                                                                            }
                                                                                            onClick={() =>
                                                                                                toggleActivePaymentOption(
                                                                                                    option["label"],
                                                                                                    _INSTANT_PAYMENT
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <label>
                                                                                                <div>
                                                                                                    <img
                                                                                                        src={`${VaderPayIcon}`}
                                                                                                        className="img-responsive"
                                                                                                        alt="payment"
                                                                                                    />
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                /* )*/
                                                                            ) : (
                                                                                <div key={"instant_payment_option" + index}>
                                                                                    <div className="form-checkbox">
                                                                                        <div
                                                                                            id={"method" + index}
                                                                                            className={
                                                                                                "option " +
                                                                                                classnames({
                                                                                                    active:
                                                                                                        activePaymentOption ===
                                                                                                        option["label"],
                                                                                                })
                                                                                            }
                                                                                            onClick={() =>
                                                                                                toggleActivePaymentOption(
                                                                                                    option["label"],
                                                                                                    _INSTANT_PAYMENT
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <label>
                                                                                                {<div>
                                                                                                    <img
                                                                                                        src={`${option["label"] === "PayTrust" ? PayTrustIcon : option["label"] === "SPG" ? SPGIcon : FattyPayIcon}`}
                                                                                                        className="img-responsive img1"
                                                                                                        alt="payment"
                                                                                                    />
                                                                                                        <img
                                                                                                            src={`${option["label"] === "PayTrust" ? PayTrustIcon : option["label"] === "SPG" ? SPGIcon : option["label"] === "PowerPay88" ? PowerPayicon : FattyPayIcon}`}
                                                                                                        className="img-responsive img2"
                                                                                                        alt="payment"
                                                                                                    />
                                                                                                </div>}
                                                                                                {/*option["label"] === "PayTrust" ? (
                                            <div>
                                              <img
                                                src={
                                                  option["label"] === "SPG"
                                                    ? `${SPGIcon}`
                                                    : `${PayTrustIcon}`
                                                }
                                                className="img-responsive img1"
                                                alt="payment"
                                              />
                                              <img
                                                src={`${PayTrustIcon}`}
                                                className="img-responsive img2"
                                                alt="payment"
                                              />
                                            </div>
                                          ) : (
                                            <div>
                                              <img
                                                src={
                                                  option["label"] === "SPG"
                                                    ? `${SPGIcon}`
                                                    : `${PayTrustIcon}`
                                                }
                                                className="img-responsive"
                                                alt="payment"
                                              />
                                            </div>
                                          )*/}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>

                                                        <div className="">
                                                            <>
                                                                {paymentOption >= 0 &&
                                                                    !isEmpty(instantPayOption) &&
                                                                    ((activePaymentOption == "SPG" && instantPayOption[paymentOption]
                                                                        .instantPayGatewayString == "SPG") ? (
                                                                        <SPGDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ) : (activePaymentOption == "iBayar" && instantPayOption[paymentOption]
                                                                        .instantPayGatewayString == "iBayar") ? (
                                                                        <IBayarDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ) : (activePaymentOption == "VaderPay" && instantPayOption[paymentOption]
                                                                        .instantPayGatewayString == "VaderPay") ? (
                                                                        <VaderDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            // setTriggerParentInit={setTriggerParentInit}
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ) : (activePaymentOption == "FattyPay" && instantPayOption[paymentOption]
                                                                        .instantPayGatewayString == "FattyPay") ? (
                                                                        <FattyPayDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ) : (activePaymentOption == "PowerPay88" && instantPayOption[paymentOption]
                                                                                    .instantPayGatewayString == "PowerPay88") ? (
                                                                        <PowerPayDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ) : (
                                                                        <InstantDeposit
                                                                            gatewayOption={
                                                                                instantPayOption[paymentOption]
                                                                            }
                                                                            parentInit={init}
                                                                            bonusDeposit={bonusDeposit}
                                                                        />
                                                                    ))}
                                                            </>
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId={_NORMAL_UPLOAD_DEPOSIT}>
                                                        <div className="payment-methods-radio">
                                                            {paymentOptionList
                                                                .filter(
                                                                    (x) => x.paymentType === t("NORMAL_DEPOSIT")
                                                                )
                                                                .map((option, index) => {
                                                                    return (
                                                                        <div key={"normal_payment_option" + index}>
                                                                            <div className="form-checkbox">
                                                                                {(tngStatus == true &&
                                                                                    option["label"] == "TnG") ||
                                                                                    option["label"] == "Normal" ? (
                                                                                    <div
                                                                                        id={"methodnormal" + index}
                                                                                        className={
                                                                                            "option " +
                                                                                            classnames({
                                                                                                active:
                                                                                                    activePaymentOption ===
                                                                                                    option["label"],
                                                                                            })
                                                                                        }
                                                                                        onClick={() =>
                                                                                            toggleActivePaymentOption(
                                                                                                option["label"],
                                                                                                _NORMAL_UPLOAD_DEPOSIT
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <label>
                                                                                            <div>
                                                                                                <img
                                                                                                    src={
                                                                                                        option["label"] === "TnG"
                                                                                                            ? `${TngIcon}`
                                                                                                            : `${NormalIcon}`
                                                                                                    }
                                                                                                    className="img-responsive"
                                                                                                    alt="payment"
                                                                                                />
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div style={{ cursor: "not-allowed" }}>
                                                                                        <div
                                                                                            id={"methodnormal" + index}
                                                                                            className={
                                                                                                "option " +
                                                                                                classnames({
                                                                                                    active:
                                                                                                        activePaymentOption ===
                                                                                                        option["label"],
                                                                                                })
                                                                                            }
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    moment().format("HH:mm:ss") >
                                                                                                    tngStartTime &&
                                                                                                    moment().format("HH:mm:ss") <
                                                                                                    tngEndTime
                                                                                                ) {
                                                                                                    showTngUnavailable();
                                                                                                } else {
                                                                                                    showTngOffline();
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <label>
                                                                                                <div>
                                                                                                    <img
                                                                                                        src={`${TngIcon}`}
                                                                                                        className="img-responsive option-image offline-tng"
                                                                                                        alt="payment"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="offline-icon"
                                                                                                    style={{
                                                                                                        backgroundImage: `url(${OfflineIcon})`,
                                                                                                    }}
                                                                                                ></div>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>

                                                        <div className="">
                                                            <>
                                                                {paymentOption !== 0 &&
                                                                    (paymentOption == _TNG_DEPOSIT &&
                                                                        tngStatus == true ? (
                                                                        <TNGDeposit parentInit={init} bonusDeposit={bonusDeposit} />
                                                                    ) : (
                                                                        <NormalDeposit parentInit={init} bonusDeposit={bonusDeposit} />
                                                                    ))}
                                                            </>
                                                        </div>
                                                    </TabPane>
                        <TabPane tabId={_QR_DEPOSIT}>
                          <div className="payment-methods-radio">
                            {paymentOptionList
                              .filter((x) => x.paymentType === t("DUIT_NOW"))
                              .map((option, index) => {
                                return (
                                  <div key={"qr_payment_option" + index}>
                                    <div className="form-checkbox">
                                      <div
                                        id={"methodqr" + index}
                                        className={
                                          "option " +
                                          classnames({
                                            active:
                                              activePaymentOption ===
                                              option["label"],
                                          })
                                        }
                                        onClick={() =>
                                          toggleActivePaymentOption(
                                            option["label"],
                                            _QR_DEPOSIT
                                          )
                                        }
                                      >
                                        <label>
                                          <div>
                                            <img
                                              src={
                                                option["label"] === "SPG QR"
                                                  ? `${SPGQRIcon}`
                                                  : option["label"] === "VaderPay QR" ? `${VaderPayQRIcon}`
                                                                        : `${FattyPayQRIcon}`
                                              }
                                              className="img-responsive"
                                              alt="payment"
                                              style={
                                                option["label"] === "SPG QR"
                                                      ? { width: "45px" }
                                                      : { width:"60px" }
                                              }
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                                                        <div className="">
                                                            <>
                                                                {activePaymentOption === "SPG QR" ? (
                                                                    <SPGQRDeposit
                                                                        gatewayOption={instantPayOption[paymentOption]}
                                                                        parentInit={init}
                                                                        bonusDeposit={bonusDeposit}
                                                                    />
                                                                ) : activePaymentOption === "VaderPay QR" ? (
                                                                    <VaderQRDeposit
                                                                        gatewayOption={instantPayOption[paymentOption]}
                                                                        parentInit={init}
                                                                        bonusDeposit={bonusDeposit}
                                                                    />
                                                                ) : activePaymentOption === "TruePay" ? (
                                                                    <FattyPayQRDeposit
                                                                        gatewayOption={instantPayOption[paymentOption]}
                                                                        parentInit={init}
                                                                        bonusDeposit={bonusDeposit}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </>
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item></>)}
                        </Accordion>
                    </div>
                </div>
            </div>
            <Modal
                fade={false}
                contentClassName="modal-brand modal-bottom modal-numpad"
                isOpen={showFullnamePopup}
                centered
            >
                <ModalBody>
                    <form onSubmit={handleFullNameSubmit(submitFullName)}>
                        <div className="display-wrapper text-center">
                            <strong
                                className="reload-title text-yellow d-block"
                                style={{ fontSize: "20px" }}
                            >
                                {t("ENTER_YOUR_FULL_NAME")}
                            </strong>
                            <strong
                                className="reload-title text-yellow mb-3 d-block"
                                style={{ fontSize: "12px", fontStyle: "italic" }}
                            >
                                {t("IN_YOUR_BANK_ACCOUNT")}
                            </strong>
                            <input
                                type="text"
                                name="userFullName"
                                className="withdrawal-fullName-input"
                                ref={registerFullName({
                                    required: "FIELD_REQUIRED",
                                    pattern: {
                                        value: /^[a-z A-Z]+$/,
                                        message: "INVALID_NAME",
                                    },
                                })}
                                onChange={(e) =>
                                    setValueFullName("userFullName", e.target.value)
                                }
                            />
                            {errorsFullName.userFullName && (
                                <div className="invalid-feedback">
                                    {t(errorsFullName.userFullName.message)}
                                </div>
                            )}
                            <button
                                type="submit"
                                className="btn btn-block btn-brand-gradient mx-auto"
                                style={{ width: "200px" }}
                            >
                                {t("SUBMIT")}
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </section>
    );
};

export default Deposit;
