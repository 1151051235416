import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GameCategory from "components/game-category";
import { filter, map } from "lodash";
import { setGameCategories, setGameProviders } from "redux/GameAction";
import ApiEngine from "util/ApiEngine";
import { ApiUrl, WebUrl, SessionKey, LanguageOption } from "util/Constant";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

const GameMenu = ({ isHome }) => {
    // const match = useRouteMatch(WebUrl._URL_GAME_PROVIDER);
    var url_string = window.location;
    var url = new URL(url_string);
    const match = useRouteMatch(url.pathname);
    var selectedLanguage = localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined' ?
        localStorage.getItem(SessionKey._LANGUAGE) : 'en';

    const { isLoggedIn } = useSelector((state) => state.authState);
    const _userData = useSelector((state) => state.authState.userData);
    const languageId = useSelector((state) => state.languageState.languageId);
    let title = "";

    if (match) {
        const { path } = match;
        const value = path.replace('/', '');
        title = value;
    }

    const dispatch = useDispatch();

    const gameCategories = useSelector((state) => {
        return state.gameState.gameCategories;
    });

    useEffect(() => {
        const fetchData = async () => {
            // Get game categories
            const { data: gameCategories } = await ApiEngine.get(
                ApiUrl._API_GET_PRODUCT_CATEGORY_DESKTOP +
                "?selectedLanguage=" +
                selectedLanguage
            );

            dispatch(
                setGameCategories({
                    gameCategories,
                })
            );

            // Get game providers
            let apiUrl = ApiUrl._API_GET_CATEGORY_GROUPED_GAME_DASHBOARD_DESKTOP;

            if (isLoggedIn) {
                if (localStorage.getItem(SessionKey._LANGUAGE) && localStorage.getItem(SessionKey._LANGUAGE) != 'undefined') {
                    if (LanguageOption.find(
                        (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)) != undefined) {
                        apiUrl += "?languageId=" + LanguageOption.find(
                            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
                        ).languageId;
                    }
                    else {
                        apiUrl += "?languageId=1";
                    }
                } else {
                    apiUrl += "?languageId=1";
                }

                // LanguageOption.find(
                //   (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
                // ).languageId;
            }

            var { data: gameProviders } = await ApiEngine.get(apiUrl);

            dispatch(
                setGameProviders({
                    gameProviders,
                })
            );
        };

        fetchData();
    }, [isLoggedIn]);

    const getActive = (item, index) => {
        if (match.url == "/") {
            return item.id === -1;
        } else if (match.url == "/GameProvider/slots") {
            return item.id === gameCategories.find(x => x.originalTitle === "Slots").id;
        } else {
            return item.originalTitle.toLowerCase() === title;
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <ul className="category-box list-inline mb-0 gold-scroll" onClick={() => {
                        localStorage.setItem(SessionKey._ISONLOAD, true);
                    }}>
                        {gameCategories &&
                            gameCategories.length &&
                            map(gameCategories.filter(x => x.originalTitle !== "Virtual-Sports" && x.originalTitle !== "V.Sports" && x.originalTitle !== "Fish Games"), (item, index) => {
                                return (
                                    <GameCategory
                                        key={"game_categories_" + index}
                                        index={index}
                                        item={item}
                                        active={getActive(item, index)}
                                    />
                                );
                            })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

GameMenu.propTypes = {
    isHome: PropTypes.bool,
};

GameMenu.defaultProps = {
    isHome: false,
};

export default GameMenu;
