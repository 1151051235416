import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DateTime from "react-datetime";
import moment from "moment";
import "moment/min/locales.min";
import PropTypes from "prop-types";
import { SessionKey, LanguageOption } from "../../util/Constant";

const DateRangeFilter = (props) => {
	const { t, i18n } = useTranslation();

	const [sDate, setSDate] = useState();
	const [eDate, setEDate] = useState();
	const [selectedOption, setSelectedOption] = useState({});
	const [openCalendar, setOpenCalendar] = useState(false);
	const [optionClicked, setOptionClicked] = useState(false);
	const [currMinDate, setCurrMinDate] = useState(moment());
	const [currMaxDate, setCurrMaxDate] = useState(moment());
	const [localeId, setLocaleId] = useState("");
	var _dateRef = useRef(null);

	const {
		startDate = sDate,
		endDate = eDate,
		setStartDate,
		setEndDate,
		onStartDateChange,
		onEndDateChange,
		onSave,
	} = props;

	const _PREV_DAY = "PREV";
	const _NEXT_DAY = "NEXT";

	const _DATE_SELECTIONS = [
		{
			label: t("PREV_DAY"),
			from: _PREV_DAY,
		},
		{
			label: t("TODAY"),
			from: moment(),
		},
		{
			label: t("YESTERDAY"),
			from: moment().subtract(1, "day"),
			to: moment().subtract(1, "day"),
		},
		{
			label: t("THIS_WEEK"),
			from: moment().startOf("isoWeek"),
		},
		{
			label: t("LAST_WEEK"),
			from: moment().subtract(1, "week").startOf("isoWeek"),
			to: moment().subtract(1, "week").endOf("isoWeek"),
		},
		{
			label: t("THIS_MONTH"),
			from: moment().startOf("month"),
		},
		{
			label: t("LAST_MONTH"),
			from: moment().startOf("month").subtract(1, "month"),
			to: moment().startOf("month").subtract(1, "month").endOf("month"),
		},
		{
			label: t("NEXT_DAY"),
			from: _NEXT_DAY,
		},
	];

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		let langId = LanguageOption.find(
			(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
		).languageId;
		if (langId == 1) moment.locale("en");
		else if (langId == 2) moment.locale("zh-cn");
		else if (langId == 3) moment.locale("ms");
		else if (langId == 5) moment.locale("th");
		else if (langId == 6) moment.locale("vi");
		else if (langId == 7) moment.locale("id");

		setLocaleId(moment.locale());
	};

	useEffect(() => {
		let tempMinDate = props.minDate;
		let tempMaxDate = props.maxDate;
		if (props.minDate === undefined) {
			tempMinDate = moment().subtract(8, "months");
		}
		if (props.maxDate === undefined) {
			tempMaxDate = moment();
		}
		setCurrMinDate(tempMinDate);
		setCurrMaxDate(tempMaxDate);

		let startDateMoment;
		let endDateMoment;
		if (selectedOption.from == _PREV_DAY) {
			startDateMoment = moment(startDate).subtract(1, "day");
			endDateMoment = moment(startDate).subtract(1, "day");
		} else if (selectedOption.from == _NEXT_DAY) {
			startDateMoment = moment(startDate).add(1, "day");
			endDateMoment = moment(startDate).add(1, "day");
		} else {
			startDateMoment = selectedOption.from ? selectedOption.from : moment();
			endDateMoment = selectedOption.to ? selectedOption.to : moment();
		}

		if (startDate === undefined || optionClicked) {
			setSDate(startDateMoment);
			if (typeof setStartDate !== "undefined") {
				props.setStartDate(startDateMoment);
			}
		} else setSDate(startDate);
		if (endDate === undefined || optionClicked) {
			setEDate(endDateMoment);
			if (typeof setEndDate !== "undefined") {
				props.setEndDate(endDateMoment);
			}
		} else setEDate(endDate);

		setOptionClicked(false);
	}, [selectedOption]);

	useEffect(() => {
		if (openCalendar) {
			_dateRef.current.openCalendar();
		}
	}, [openCalendar]);

	function handleStartDate(date) {
		setSDate(date);
		props.setStartDate(date);
		setOpenCalendar(true);
		if (onStartDateChange) {
			onStartDateChange(date);
		}
	}

	function handleEndDate(date) {
		setEDate(date);
		props.setEndDate(date);
		if (onEndDateChange) {
			onEndDateChange(date);
		}
	}

	function handleSave() {
		if (onSave) {
			onSave(sDate, eDate);
		}
	}

	var sDateValid = function (current) {
		return (
			current.isSameOrAfter(currMinDate) && current.isSameOrBefore(currMaxDate)
		);
	};

	var eDateValid = function (current) {
		return (
			current.isSameOrAfter(moment(sDate).startOf("day")) &&
			current.isSameOrBefore(currMaxDate)
		);
	};

	return (
		<>
			<div className="form-control transac-history-search-box">
				<DateTime
					inputProps={{
						className: "th-date-search",
						readOnly: true,
					}}
					name="startDate"
					dateFormat="YYYY-MM-DD"
					closeOnSelect={true}
					value={sDate}
					isValidDate={sDateValid}
					timeFormat={false}
					onChange={handleStartDate}
					onFocus={init}
					locale={localeId}
				/>
				<div>{t("TO")}</div>
				<DateTime
					inputProps={{ className: "th-date-search", readOnly: true }}
					name="endDate"
					dateFormat="YYYY-MM-DD"
					closeOnSelect={true}
					timeFormat={false}
					value={eDate}
					isValidDate={eDateValid}
					ref={_dateRef}
					onChange={handleEndDate}
				/>

				<div className="th-search-btn">
					<a
						onClick={() => {
							handleSave();
						}}
					>
						<i
							className="fas fa-search"
							style={{
								fontSize: "15px",
							}}
						></i>
					</a>
				</div>
			</div>

			{
				<div className="filter-with-scroll-box gold-scroll">
					{_DATE_SELECTIONS.map((selection) => {
						return (
							<div
								key={selection.label}
								className={
									selection.from !== _PREV_DAY &&
									selection.from !== _NEXT_DAY &&
									selectedOption.label == selection.label
										? "btn filter-items btn-outline-info rounded-pill active"
										: "btn filter-items btn-outline-info rounded-pill"
								}
							>
								<div
									onClick={() => {
										setOptionClicked(true);
										setSelectedOption(selection);
									}}
								>
									{selection.label}
								</div>
							</div>
						);
					})}
				</div>
			}
		</>
	);
};

DateRangeFilter.propTypes = {
	startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	setStartDate: PropTypes.func,
	setEndDate: PropTypes.func,
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
	onSave: PropTypes.func,
};

export default DateRangeFilter;
