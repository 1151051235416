import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AlertTypes, ApiUrl, WebUrl } from "util/Constant";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
const GameCategory = ({ item, active }) => {
    const _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const availableGameCategory = useSelector((state) => (state.gameState.gameProviders));
    const { isLoggedIn } = useSelector(state => state.authState);

    const [link, setLink] = useState("");
    useEffect(() => {
        if (item.originalTitle === "Slots") {
            setLink(WebUrl._URL_GAME_PROVIDER.replace(":title", item.originalTitle.toLowerCase()));
        } else if (item.originalTitle === "All") {
            setLink(WebUrl._URL_MAIN);
        } else {
            setLink("/" + item.originalTitle.toLowerCase());
        }
    }, []);
    return (
        (availableGameCategory && availableGameCategory.filter(
            (n) => n.categoryTitle === item.originalTitle
        ).length > 0 ||
            item.title === "All") &&
        <li onClick={() => {
            if (item.originalTitle === "Connect") {
                if (isLoggedIn) {
                    //window.open(ApiUrl._API_START_GAME + "?gameId=" + availableGameCategory.filter(x => x.categoryTitle == item.originalTitle)[0].id + "&isApp=0&device=d");
                }
                else {
                    _dispatch(
                        showMessage({
                            type: AlertTypes._INFO,
                            content: t("PLEASE_LOGIN_FIRST"),
                            onConfirm: () => {
                                //console.log("hi");
                            },
                        })
                    );
                }
            }
            }}>
            <Link
                // to={WebUrl._URL_GAME_PROVIDER.replace(":title", item.originalTitle)}
                to={link}
                className={`cat-item ${active && "active"}`}
            >
                <div className='cat-img'>
                    <img
                        src={require(`../../assets/img/assets/game-category/svg/${item.originalTitle}.svg`)}
                        className='img-responsive'
                        alt={item.title}
                    />
                </div>
                <div className={'cat-details' + (item.originalTitle == "Connect" ? " connect-font" : "")}>{t(item.originalTitle)}</div>
            </Link>
        </li>
    );
};

GameCategory.propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    active: PropTypes.bool,
};

GameCategory.defaultProps = {
    active: false,
};

export default GameCategory;
