import React from "react";
import { PageSettings } from "./config/page-settings.js";
import Content from "./components/content";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, connect } from "react-redux";
import {
	updateLoginUser,
	resetLogin,
	performLogout,
	checkIsLoggedIn,
} from "./redux/AuthAction.js";
import { disposeMessage } from "./redux/AppAction.js";
import classNames from "classnames";
import { isObjectEmpty, stringIsNullOrEmpty } from "./util/Util.js";
import { withTranslation } from "react-i18next";
import ApiEngine from "./util/ApiEngine.js";
import { ApiKey, ApiUrl } from "./util/Constant";

LoadingOverlay.propTypes = undefined;
class App extends React.Component {
	constructor(props) {
		super(props);

		this.handleSetPageContent = (value) => {
			this.setState((state) => ({
				pageContent: value,
			}));
		};
		this.handleSetPageContentClass = (value) => {
			this.setState((state) => ({
				pageContentClass: value,
			}));
		};
		this.handleSetPageContentFullHeight = (value) => {
			this.setState((state) => ({
				pageContentFullHeight: value,
			}));
		};
		this.handleSetPageContentFullWidth = (value) => {
			this.setState((state) => ({
				pageContentFullWidth: value,
			}));
		};
		this.handleSetPageContentInverseMode = (value) => {
			this.setState((state) => ({
				pageContentInverseMode: value,
			}));
		};
		this.handleSetPageFooter = (value) => {
			this.setState((state) => ({
				pageFooter: value,
			}));
		};
		this.handleSetBodyWhiteBg = (value) => {
			if (value === true) {
				document.body.classList.add("bg-white");
			} else {
				document.body.classList.remove("bg-white");
			}
		};

		this.state = {
			pageContent: true,
			pageContentClass: "",
			pageContentFullHeight: false,
			pageContentFullWidth: true,
			pageContentInverseMode: false,
			handleSetPageContent: this.handleSetPageContent,
			handleSetPageContentClass: this.handleSetPageContentClass,
			handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
			handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
			handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

			pageFooter: false,
			handleSetPageFooter: this.handleSetPageFooter,
			error: null,
			errorInfo: null,

			handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
			handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,
			interval1: null,
		};
	}

	/// <summary>
	/// Author: -
	/// </summary>
	componentDidMount() {
		//navigator.serviceWorker.getRegistrations().then(function (registrations) { for (let registration of registrations) { registration.unregister(); } });
		this.setState({
			hasGetSkinSettings: false,
			hasCustomSkin: false,
			activeTheme: 0,
			backgroundImage: "",
		});
		clearInterval(this.state.interval1);
		this.state.interval1 = null;
		this.props.checkIsLoggedIn();
		this.state.interval1 = setInterval(
			() => this.props.checkIsLoggedIn(),
			30000
		);
	}

	componentWillUnmount() {
		clearInterval(this.state.interval1);
		this.state.interval1 = null;
	}

	/// <summary>
	/// Author: -
	/// </summary>
	componentDidUpdate(prevProps) {
		if (
			this.props.languageState.languageId != prevProps.languageState.languageId
		) {
			this.props.i18n.changeLanguage(this.props.languageState.languageId);
		}

		if (this.state.interval1 == null) {
			this.state.interval1 = setInterval(
				() => this.props.checkIsLoggedIn(),
				30000
			);
		}
	}

	/// <summary>
	/// Author: -
	/// </summary>
	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
		clearInterval(this.state.interval1);
	}

	async getSkinSettings() {
		if (!window.location.pathname.match(/login/)) {
			var responseJson = await ApiEngine.get(
				ApiUrl._API_GET_COMPANY_SKIN_SETTINGS
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				this.state.hasCustomSkin = true;
				this.state.backgroundImage =
					responseJson[ApiKey._API_DATA_KEY]["backgroundImage"];
			}
			this.state.hasGetSkinSettings = true;
		} else {
			this.state.hasGetSkinSettings = false;
		}
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xxl-8 col-lg-10">
								<div className="card overflow-hidden">
									<div className="row g-0">
										<div className="col-lg-12">
											<div className="d-flex flex-column h-100">
												<div className="auth-brand p-4 text-center">
													<a className="logo-dark">
														<img
															src={require("./assets/img/logo.png")}
															alt="logo"
															height="22"
														/>
													</a>
												</div>
												<div className="p-4 my-auto">
													<div className="d-flex justify-content-center mb-5">
														<img
															src={require("./assets/img/svg/500.svg")}
															alt=""
															className="img-fluid"
														/>
													</div>

													<div className="text-center">
														<h1 className="mb-3"></h1>
														<h4 className="fs-20">
															Something went wrong, please contact admin for
															help
														</h4>
														<p className="text-muted mb-3">
															{" "}
															{window.navigator.userAgent}{" "}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<PageSettings.Provider value={this.state}>
					<LoadingOverlay
						active={
							this.props.appState.isBusy ||
							!this.props.authState.loginStateInitialized
						}
						styles={{
							overlay: (base) => ({
								...base,
								zIndex: "10000",
								position: "fixed",
							}),
						}}
						className={classNames("loading-overlay")}
					>
						<div className="loader"></div>
						<b className="loader-font">{this.props.appState.message + "..."}</b>
					</LoadingOverlay>
					{!isObjectEmpty(this.props.appState.alert) && (
						<div className="sweet-alert-container">
							<SweetAlert {...this.props.appState.alert}>
								{!stringIsNullOrEmpty(this.props.appState.alert.content) &&
									(this.props.appState.alert.html
										? this.props.appState.alert.content
										: this.props.appState.alert.content
												.split("\n")
												.map((item, key) => {
													return (
														<span key={key} className="sweet-alert-content">
															{item}
															<br />
														</span>
													);
												}))}
							</SweetAlert>
						</div>
					)}
					{this.props.authState.loginStateInitialized && (
						<div
							onClick={() => {
								if (this.state.pageSidebarToggled) {
									this.toggleMobileSidebar();
								}
							}}
						>
							{this.state.pageContent && <Content />}
						</div>
					)}
				</PageSettings.Provider>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		...state,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	var alert = {};
	if (
		Object.keys(stateProps.appState.alert).length > 0 &&
		!stateProps.appState.alert.onConfirm
	) {
		alert = {
			...stateProps.appState.alert,
			onConfirm: dispatchProps.disposeMessage,
		};
		stateProps.appState.alert = { ...alert };
	}

	return { ...stateProps, ...dispatchProps, ...ownProps };
};

export default connect(
	mapStateToProps,
	{
		updateLoginUser: updateLoginUser,
		resetLogin: resetLogin,
		performLogout: performLogout,
		checkIsLoggedIn: checkIsLoggedIn,
		disposeMessage: disposeMessage,
	},
	mergeProps
)(withTranslation()(App));
