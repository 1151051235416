import { AlertTypes, ApiKey, WebUrl, ApiUrl } from "../util/Constant.js";
import ApiEngine from "../util/ApiEngine.js";
import i18n from "../localization/i18n";
import InfoIcon from "../assets/img/icon/InfoIcon.svg";
import SuccessIcon from "../assets/img/icon/SuccessIcon.svg";

export const _BUSY = "busy";
export const _IDLE = "idle";
export const _API_CALLED = "setApiCalled";
export const _DOWNLINE_SEARCH = "setDownlineSearchTextCalled";
export const _DOWNLINE_QUERY = "setDownlineQueryCalled";
export const _GAME_SEARCH = "setGameSearchTextCalled";
export const _GAME_QUERY = "setGameQueryCalled";
export const _SET_Y = "setY";
export const _SET_PAGESIZE = "setPageSize";
export const _SET_ACTIVE_TAB = "setActiveTab";
export const _IS_KEEPED_LOGIN = "userKeepLogin";
export const _SET_IS_SHAREHOLDER = "setIsShareholder";

export const _ALERT_TYPES = {
  SET_ALERT: "SetAlert",
  NONE: "AlertNone",
};

export const setY = (y) => {
  return (dispatch) => {
    dispatch({
      type: _SET_Y,
      y,
    });
  };
};

export const setPageSize = (pagesize) => {
  return (dispatch) => {
    dispatch({
      type: _SET_PAGESIZE,
      data: pagesize,
    });
  };
};

export const setActiveTab = (activeTab) => {
  return (dispatch) => {
    dispatch({
      type: _SET_ACTIVE_TAB,
      data: activeTab,
    });
  };
};

export const userKeepLogin = (isKeepedLogin) => {
  return (dispatch) => {
    dispatch({
      type: _IS_KEEPED_LOGIN,
      data: isKeepedLogin,
    });
  };
};

export const setDownlineSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _DOWNLINE_SEARCH,
      data: e,
    });
  };
};

export const setDownlineQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _DOWNLINE_QUERY,
      data: e,
    });
  };
};

export const setGameSearchText = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_SEARCH,
      data: e,
    });
  };
};

export const setGameQuery = (e) => {
  return async (dispatch) => {
    dispatch({
      type: _GAME_QUERY,
      data: e,
    });
  };
};

/// <summary>
/// Author :
/// </summary>
export const setBusy = (iconEnabled, message) => {
  return async (dispatch) => {
    dispatch({
      type: _BUSY,
      message: message,
      iconEnabled: iconEnabled,
    });
  };
};

/// <summary>
/// Author :
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
  return async (dispatch) => {
    dispatch({
      type: _IDLE,
    });
  };
};

/// <summary>
/// Author :
/// </summary>
export const setPerformApiCall = (status, url) => {
  return async (dispatch) => {
    dispatch({
      type: _API_CALLED,
      data: { success: status, url: url },
    });
  };
};

/// <summary>
/// Author: -
/// </summary>
export const showResponseMessage = (
  status,
  message,
  translateRequired = true
) => {
  return (dispatch) => {
    var messageToShow = message;
    if (translateRequired) {
      messageToShow = i18n.t(message);
    }
    dispatch(
      showMessage({
        type: status ? AlertTypes._SUCCESS : AlertTypes._ERROR,
        content: messageToShow,
      })
    );
  };
};

export const showResponseMessageWithoutT = (status, message, translateRequired = true) => {
  return dispatch => {
    var messageToShow = message;
    dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow }));
  }
}

/// <summary>
/// Author: -
/// </summary>
export const showMessage = (alertProps) => {
  return (dispatch) => {
    const customConfirm = alertProps.onConfirm;
    alertProps.onConfirm = async (...args) => {
      if (alertProps.content === i18n.t("SESSION_EXPIRED") || alertProps.content === i18n.t("FORCED_LOGOUT"))
      {
        disposeMessage(window.location.replace(WebUrl._URL_MAIN))(await dispatch);
      }
      else
      {
        disposeMessage()(await dispatch);
      }
      
      if (customConfirm) {
        customConfirm(...args);
      }
    };

    const customCancel = alertProps.onCancel;
    alertProps.onCancel = async (...args) => {
      if (alertProps.content === i18n.t("SESSION_EXPIRED") || alertProps.content === i18n.t("FORCED_LOGOUT"))
      {
        disposeMessage(window.location.replace(WebUrl._URL_MAIN))(await dispatch);
      }
      else
      {
        disposeMessage()(await dispatch);
      }

      if (customCancel) {
        customCancel(...args);
      }
    };

    alertProps.custom = true;
    alertProps.confirmBtnCssClass = "custom-btn-style-1";
    alertProps.confirmBtnStyle = { width: "200px" };
    if (alertProps.type === "info") {
      alertProps.confirmBtnStyle = { backgroundColor: "#46b8da", color: "white", width: "200px" };
      alertProps.customIcon = `${InfoIcon}`;
    }
    else if (alertProps.type === "success") {
      alertProps.customIcon = `${SuccessIcon}`;
    }
    alertProps.cancelBtnBsStyle = "danger";
    alertProps.title = alertProps.title ?? "";
    alertProps.allowEscape = false;
    alertProps.reverseButtons = true;

    if (alertProps.customIcon) {
      alertProps.type = undefined;
    }

    dispatch({ type: _ALERT_TYPES.SET_ALERT, alert: alertProps });
  };
};

/// <summary>
/// Author: -
/// </summary>
export const disposeMessage = (url) => {
  return (dispatch) => {
    dispatch({ type: _ALERT_TYPES.NONE, path: url });
  };
};

export const setIsShareholder = (isShareholder) => {
  return (dispatch) => {
    dispatch({
      type: _SET_IS_SHAREHOLDER,
      data: isShareholder,
    });
  };
}
