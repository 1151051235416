import { WebUrl } from "./../util/Constant.js";

const gameCategory = [
  {
    text: "E-Sport",
    image: require("../assets/img/assets/game-category/eSports.png"),
    link: WebUrl._URL_GAME_PROVIDER,
  },
  {
    text: "Casino",
    image: require("../assets/img/assets/game-category/Casino.png"),
    link: "#",
  },
  {
    text: "Slots",
    image: require("../assets/img/assets/game-category/Slots.png"),
    link: "#",
  },
  {
    text: "RNG",
    image: require("../assets/img/assets/game-category/RNG.png"),
    link: "#",
  },
  {
    text: "Sports",
    image: require("../assets/img/assets/game-category/Sports.png"),
    link: "#",
  },
  {
    text: "Lottery",
    image: require("../assets/img/assets/game-category/Lottery.png"),
    link: "#",
  },
  {
    text: "Fishing",
    image: require("../assets/img/assets/game-category/Fish Games.png"),
    link: "#",
  },
  {
    text: "Poker",
    image: require("../assets/img/assets/game-category/Poker.png"),
    link: "#",
  },
];

const gameProvider = [
  {
    image: require("../assets/img/game-banner/provider1.png"),
    link: "#",
  },
  {
    image: require("../assets/img/game-banner/provider2.png"),
    link: "#",
  },
  {
    image: require("../assets/img/game-banner/provider3.png"),
    link: "#",
  },
  {
    image: require("../assets/img/game-banner/provider4.png"),
    link: "#",
  },
  {
    image: require("../assets/img/game-banner/provider3.png"),
    link: "#",
  },
];
const filterList = [
  {
    text: "Pussy888",
  },
  {
    text: "Jclub777",
  },
  {
    text: "Playtech ",
  },
  {
    text: "Mario Club",
  },
  {
    text: "Red Tiger",
  },
  {
    text: "Funky Game",
  },
  {
    text: "Pragmatic Play",
  },
  {
    text: "Yggdrasil",
  },
  {
    text: "Microgaming",
  },
  {
    text: "Gamatron",
  },
  {
    text: "Joker Gaming",
  },
  {
    text: "SBO Slot",
  },
  {
    text: "918 Kiss",
  },
  {
    text: "V Power",
  },
  {
    text: "XE88",
  },
];

const newGames = [
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Lucky New Year.png"),
    link: "#",
    text: "Lucky New Year Tiger Treasures",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Heart of the Frontier.png"),
    link: "#",
    text: "Heart of the Frontier Treasures",
  },
  {
    tag: "",
    image: require("../assets/img/game/Legacy of the Tiger.png"),
    link: "#",
    text: "Legacy of the Tiger",
  },
  {
    tag: "",
    image: require("../assets/img/game/5DragonLegend_EN.png"),
    link: "#",
    text: "5 Dragons Legend",
  },
  {
    tag: "",
    image: require("../assets/img/game/Fire Blaze Golden_ Amazing Factory.png"),
    link: "#",
    text: "Fire Blaze Golden: Amazing Factory",
  },
  {
    tag: "",
    image: require("../assets/img/game/Age of the Gods Norse Book of Dwarves.png"),
    link: "#",
    text: "Book of Dwarves",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Caishen2.png"),
    link: "#",
    text: "Emperor Caishen",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Age of Gods_ Book of Oracle.png"),
    link: "#",
    text: "Book of the Oracle",
  },
  {
    tag: "",
    image: require("../assets/img/game/Nian Nian You Yu Asia.png"),
    link: "#",
    text: "Nian Nian You Yu",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/GoldenDynasty.png"),
    link: "#",
    text: "Golden Dynasty",
  },
  {
    tag: "",
    image: require("../assets/img/game/03_Cashanova-Cashpotsen.png"),
    link: "#",
    text: "Cashanova Cashpots",
  },
  {
    tag: "",
    image: require("../assets/img/game/15_Fire-and-Golden.png"),
    link: "#",
    text: "Fire And Gold",
  },
  {
    tag: "",
    image: require("../assets/img/game/Fat Choy Choy Sun.png"),
    link: "#",
    text: "Fat Choy Choy Sun",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Olympus2.png"),
    link: "#",
    text: "Gate of Olympus",
  },
];

const bannerList = [
  {
    image: require("../assets/img/home/home-slider-banner-1.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-2.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-3.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-4.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-5.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-6.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-7.png"),
  },
  {
    image: require("../assets/img/home/home-slider-banner-8.png"),
  },
];

const popularGames = [
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/Heart of Ocean.png"),
    link: "#",
    text: "Heart of Ocean",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/MonkeyKing_EN.png"),
    link: "#",
    text: "Monkey King",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/Eros_EN.png"),
    link: "#",
    text: "Eros Sexy",
  },
  {
    tag: "",
    image: require("../assets/img/game/LegendOfEgypt_EN.png"),
    link: "#",
    text: "Legend Of Egypt",
  },
  {
    tag: "",
    image: require("../assets/img/game/fanTan_mobileCasino_gameicon_174x174_en.png"),
    link: "#",
    text: "Fan Tan",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/mobile_game_game_virtualRoulette_en.png"),
    link: "#",
    text: "Virtual Roulette",
  },
  {
    tag: "",
    image: require("../assets/img/game/pking.png"),
    link: "#",
    text: "Pyramid King",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/JiJiJi_EN.png"),
    link: "#",
    text: "Ji Ji Ji",
  },
  {
    tag: "",
    image: require("../assets/img/game/basketballStrike.png"),
    link: "#",
    text: "Basketball Strike",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/Long Long Long III_game.png"),
    link: "#",
    text: "Long Long Long",
  },
  {
    tag: "",
    image: require("../assets/img/game/Olympus2.png"),
    link: "#",
    text: "Gates of Olympus",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/CaribbeanSaga.png"),
    link: "#",
    text: "Caribbean Saga",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Quest West.png"),
    link: "#",
    text: "Quest West",
  },
  {
    tag: "",
    image: require("../assets/img/game/FortuneofGiza.png"),
    link: "#",
    text: "Fortune of Giza",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Age of the Gods Norse_ Gods and Giants.png"),
    link: "#",
    text: "God and Giants",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/CaptainsTreasure_EN.png"),
    link: "#",
    text: "Captains's Teasure",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/Queen of Wands.png"),
    link: "#",
    text: "Queen Of Wands",
  },
  {
    tag: require("../assets/img/assets/new.png"),
    image: require("../assets/img/game/CaiShensFortune.png"),
    link: "#",
    text: "Caisheng's Fortune",
  },
  {
    tag: "",
    image: require("../assets/img/game/Fire Blaze Golden_ Buccaneer Bells.png"),
    link: "#",
    text: "Bucchneer Bells",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Adventure Trail.png"),
    link: "#",
    text: "Adventure Trail",
  },
  {
    tag: require("../assets/img/assets/hot.png"),
    image: require("../assets/img/game/Buffalo King.png"),
    link: "#",
    text: "Buffalo King",
  },
];
const sortList = [
  {
    text: "Popularity",
  },
  {
    text: "New",
  },
  {
    text: "A - Z",
  },
];
const sideMenu = [
  {
    image: require("../assets/img/assets/side-menu/VIP_EVENT.svg"),
    activeImage: require("../assets/img/assets/side-menu/VIP_EVENT_active.svg"),
    text: "VIP_EVENT",
    link: WebUrl._URL_VIP,
  },
  //{
  //  image: require("../assets/img/assets/side-menu/DOWNLINE.svg"),
  //  activeImage: require("../assets/img/assets/side-menu/DOWNLINE_active.svg"),
  //  text: "DOWNLINE",
  //  link: WebUrl._URL_DOWNLINE,
  //},
];
const historyFilter = [
  {
    text: "Previous Day",
  },
  {
    text: "Today",
  },
  {
    text: "Yesterday",
  },
  {
    text: "This Week",
  },
  {
    text: "Last Week",
  },
  {
    text: "This Month",
  },
  {
    text: "Last Month",
  },
  {
    text: "Next Day",
  },
];

const rewardsFilter = [
  {
    text: "Last Week",
  },
  {
    text: "Sunday",
  },
  {
    text: "Monday",
  },
  {
    text: "Tuesday",
  },
  {
    text: "Wednesday",
  },
  {
    text: "Thursday",
  },
  {
    text: "Friday",
  },
  {
    text: "Saturday",
  },
];

const tabReferralHeadings = [
  {
    title: "Casino",
    link: "casino",
  },
  {
    title: "Slots",
    link: "slots",
  },
  {
    title: "RNG",
    link: "rng",
  },
  {
    title: "Sports",
    link: "sports",
  },
  {
    title: "Lottery",
    link: "lottery",
  },
];

//4D result//
const _POOL_NAME = {
  mg: "Magnum",
  kd: "Da Ma Cai 1 + 3D",
  tt: "Sport TOTO",
  sg: "Singapore Pool ",
  sr: "Sarawak 88 ",
  sn: "SANDAKAN",
  sb: "Sabah 88",
  gd: "GRAND DRAGON",
};

const _TITLE_COLOR = {
  mg: "#000000",
  kd: "#FFFFFF",
  tt: "#FFFFFF",
  sg: "#FFFFFF",
  sr: "#FFFFFF",
  sn: "#000000",
  sb: "#FFFFFF",
  gd: "#FFFFFF",
};

const _COLOR = {
  mg: "#FFC159",
  kd: "#1C377C",
  tt: "#E9181B", //4D AND 5D AND 6D
  sg: "#055F8E",
  sr: "#008000",
  sn: "#F3C034",
  sb: "#2B7BF8", // currently no color
  gd: "#A1030E", //4D AND 5D AND 6D
};

const _POOL_IMAGE = {
  mg: require("../assets/img/4dresult-icon/magnum_logo.svg"),
  kd: require("../assets/img/4dresult-icon/damacai_logo.svg"),
  tt: require("../assets/img/4dresult-icon/toto_logo.svg"),
  sg: require("../assets/img/4dresult-icon/singapore_logo.svg"),
  sr: require("../assets/img/4dresult-icon/bigcash_logo.svg"),
  sn: require("../assets/img/4dresult-icon/stc_logo.svg"),
  sb: require("../assets/img/4dresult-icon/sabah_logo.svg"),
  gd: require("../assets/img/4dresult-icon/gd_logo.svg"),
};

const _SECTION_TYPE = {
  mg: "section-3",
  kd: "section-3",
  tt: "section-3", //4D AND 5D AND 6D
  sg: "section-3",
  sr: "section-3",
  sn: "section-3",
  sb: "section-3", // currently no color
  gd: "section-3", //4D AND 5D AND 6D
};

const _IS_5D_6D = {
  tt: "section-2",
  gd: "section-1",
};

//End 4D result//

const vipSideMenu = [
  {
    image: require("../assets/img/assets/side-menu/report.png"),
    activeImage: require("../assets/img/assets/side-menu/report.png"),
    page: "SUMMARY",
    link: WebUrl._URL_SUMMARY,
    title: "Summary",
    tab_id: 0
  },
  {
    image: require("../assets/img/assets/side-menu/team.png"),
    activeImage: require("../assets/img/assets/side-menu/team.png"),
    page: "SHAREHOLDERS",
    link: WebUrl._URL_SHAREHOLDERS,
    title: "Shareholders",
    tab_id: 1
  },
  {
    image: require("../assets/img/assets/side-menu/refund.png"),
    activeImage: require("../assets/img/assets/side-menu/refund.png"),
    page: "CLAIM",
    link: WebUrl._URL_CLAIM,
    title: "Claim",
    tab_id: 2
  },
  {
   image: require("../assets/img/assets/side-menu/refund.png"),
   activeImage: require("../assets/img/assets/side-menu/refund.png"),
   page: "CUSTOMIZE",
   link: WebUrl._URL_CUSTOMIZE_WEB,
   title: "Customize",
   tab_id: 0
   },
];

export {
  gameCategory,
  gameProvider,
  filterList,
  newGames,
  bannerList,
  popularGames,
  sortList,
  sideMenu,
  historyFilter,
  rewardsFilter,
  tabReferralHeadings,
  _POOL_NAME,
  _TITLE_COLOR,
  _COLOR,
  _POOL_IMAGE,
  _SECTION_TYPE,
  _IS_5D_6D,
  vipSideMenu
};


