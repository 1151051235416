import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";

const ContentHeader = ({
	enableBackButton,
	title,
	backButtonOnClick,
	escButtonOnClick,
}) => {
	const _history = useHistory();

	useEffect(() => {
		window.addEventListener("keydown", (e) => {
			if (e.key === "Escape") {
				_history.replace(WebUrl._URL_MAIN);
			}
		});
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				<div className="page-title-box">
					<h4 className="page-title">{title}</h4>
				</div>
			</div>
		</div>
	);
};

ContentHeader.propTypes = {
	enableBackButton: PropTypes.bool,
	title: PropTypes.string.isRequired,
	backButtonOnClick: PropTypes.func,
	escButtonOnClick: PropTypes.func,
};

ContentHeader.defaultProps = {
	enableBackButton: false,
	backButtonOnClick: () => window.history.go(-1),
	escButtonOnClick: () => {},
};

export default ContentHeader;
