import React, { useEffect, useState } from "react";
import { PageSettings } from "../../config/page-settings.js";
import { _ROUTES, _LOGIN_ROUTE } from "../../config/page-route.jsx";
import { useSelector } from "react-redux";
import { filter } from "lodash";
import { Route, useHistory, useLocation } from "react-router-dom";
import appsettings from "../../appsettings-fe.json";
import Header from "components/header";
import Sidebar from "components/sidebar/index2";
import Footer from "components/footer/index.js";
import ScrollToTop from "./scrolltotop";

const Content = () => {
	const history = useHistory();
	const location = useLocation();
	const [routes, setRoutes] = useState([]);
	const [scrollTop, setScrollTop] = useState(0);
	const [showScrolledHeader, setShowScrolledHeader] = useState(false);
	const liveChatLicense = parseInt(
		appsettings.ApplicationConfig.liveChatLicense
	);
	const authState = useSelector((state) => state.authState);
	const { pathname } = location;

	useEffect(() => {
		const scrollEvent = () => {
			window.addEventListener("scroll", (e) => {
				setScrollTop(e.target.documentElement.scrollTop);
			});
		};

		scrollEvent();

		return () => {
			//window.removeEventListener("scroll");
		};
	}, []);

	useEffect(() => {
		setRoutes(
			filter(
				_ROUTES,
				(route) =>
					authState.isLoggedIn ||
					!route.authRequired ||
					route.path == history.location.pathname
			)
		);
	}, [authState, location]);

	const PrivateRoute = (index, routeData) => {
		return (
			<Route
				key={index}
				exact={routeData.exact}
				path={routeData.path}
				component={routeData.component}
			/>
		);
	};

	const headerClassName = () => {
		if (pathname === "/") {
			return scrollTop > 150 ? "fixedme" : "";
		}
		if (pathname === "/download") {
			return "d-none";
		} else {
			if (scrollTop > 150) {
				setShowScrolledHeader(true);
			} else {
				setShowScrolledHeader(false);
			}

			return scrollTop > 150 ? "without-banner fixedme" : "without-banner";
		}
	};

	return (
		<PageSettings.Consumer>
			{({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
				<div
					className={
						"content " +
						(pageContentFullWidth ? "content-full-width " : "") +
						(pageContentInverseMode ? "content-inverse-mode " : "") +
						pageContentClass
					}
				>
					{authState.isLoggedIn && (
						<>
							<Sidebar />
						</>
					)}

					<div className={authState.isLoggedIn ? "content-page" : ""}>
						<ScrollToTop />
						{routes.map((route, index) => PrivateRoute(index, route))}
						<Footer />
					</div>
				</div>
			)}
		</PageSettings.Consumer>
	);
};

export default Content;
