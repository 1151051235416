import React, { useState, useEffect } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar/index2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ApiKey, ApiUrl, DisplayDevice } from "../../util/Constant";
import ContentHeader from "components/content/header";
import ApiEngine from "../../util/ApiEngine";
import Dropzone from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import {
	stringIsNullOrEmpty,
	createMultiPartFormBody,
	imagePathToFileObject,
} from "../../util/Util";
import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { updateUserData, checkIsLoggedIn } from "../../redux/AuthAction";

const CustomizeWeb = (props) => {
	const { t } = useTranslation();
	const _location = useLocation();
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [initPage, setInitPage] = useState(true);
	const [claimData, setClaimData] = useState([]);
	const [transferUrl, setTransferUrl] = useState("");
	const [selectedDate, setSelectedDate] = useState("");
	const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
	const [activeTab, setActiveTab] = useState(0);
	const [tabName, setTabName] = useState("Summary");

	const {
		register,
		unregister,
		handleSubmit,
		errors,
		setError,
		control,
		setValue,
	} = useForm();
	const [memberId, setMemberId] = useState("");
	const [content, setContent] = useState("");

	const [desktopFile, setDesktopFile] = useState([]);
	const [mobileFile, setMobileFile] = useState([]);
	const [desktopImage, setDesktopImage] = useState();
	const [mobileImage, setMobileImage] = useState();
	const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
	const [isNewMobileImage, setIsNewMobileImage] = useState(false);
	const [selectedTheme, setSelectedTheme] = useState(1);

	useEffect(() => {
		init();
	}, []);

	async function init() {
		_dispatch(setBusy());
		if (checkIsLoggedIn) {
			var responseJson = await ApiEngine.get(
				ApiUrl._API_GET_MEMBER_DETAILS_BY_ID
			);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				let tempMemberData = responseJson[ApiKey._API_DATA_KEY];
				setMemberId(tempMemberData["id"]);
				setContent(responseJson[ApiKey._API_DATA_KEY]["htmlStyle"]);
				setSelectedTheme(responseJson[ApiKey._API_DATA_KEY]["selectedTheme"]);

				if (!stringIsNullOrEmpty(tempMemberData["logo"])) {
					setDesktopImage(tempMemberData["logo"]);
				}

				//unregister("content");
			}
		}
		_dispatch(setIdle());
	}

	const submitForm = async (data, e) => {
		let params = {
			content: content,
			isNewDesktopFile: isNewDesktopImage,
			isNewMobileFile: isNewMobileImage,
			selectedTheme: selectedTheme,
		};

		if (!stringIsNullOrEmpty(memberId)) {
			params["id"] = memberId;
		}

		if (!stringIsNullOrEmpty(desktopFile)) {
			params["desktopFile"] = desktopFile;
		}

		if (stringIsNullOrEmpty(desktopImage)) {
			params["desktopImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(mobileFile)) {
			params["mobileFile"] = mobileFile;
		}

		if (stringIsNullOrEmpty(mobileImage)) {
			params["mobileImageRemoved"] = true;
		}

		console.log(params);
		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_MEMBER_LOGO_AND_HTML_STYLE, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					init();
				}

				_dispatch(
					showResponseMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			});
	};

	const logoDrop = (acceptedFiles, isDesktop) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			if (isDesktop) {
				setDesktopFile(file);
			} else {
				setMobileFile(file);
			}
			const reader = new FileReader();
			reader.onloadend = () => {
				if (isDesktop) {
					setIsNewDesktopImage(true);
					setDesktopImage(reader.result);
				} else {
					setIsNewMobileImage(true);
					setMobileImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	// const handleCheckboxChange = () => {
	const handleCheckboxChange = async (selectedTheme, e) => {
		setSelectedTheme(selectedTheme);
	};

	return (
		<>
			<div className="">
				<div className="container-fluid">
					<ContentHeader title={t("CUSTOMIZE")} />
					<div className="row">
						<div className="card p-0">
							<div className="card-body">
								<form onSubmit={handleSubmit(submitForm)}>
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group row m-b-15">
												<label className="col-md-4 col-form-label">
													<b>{t(t("COMPANY_LOGO"))}</b>
												</label>
												<div className="col-md-8">
													<Dropzone
														accept={"image/*"}
														onDrop={(acceptedFiles) =>
															logoDrop(acceptedFiles, true)
														}
													>
														{({ getRootProps, getInputProps }) => (
															<section>
																<div
																	className="dropzone"
																	style={{
																		minHeight: "200px",
																		textAlign: "center",
																	}}
																	{...getRootProps()}
																>
																	<input {...getInputProps()} />
																	<h4 style={{ color: "grey" }}>
																		{t(
																			"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																		)}
																	</h4>
																	{!stringIsNullOrEmpty(desktopImage) && (
																		<aside className="thumbsContainer">
																			<div className="thumb">
																				<div className="thumbInner">
																					<img
																						src={desktopImage}
																						className="dropzone-img"
																					/>
																				</div>
																			</div>
																		</aside>
																	)}
																</div>
															</section>
														)}
													</Dropzone>
													<br />
													<button
														type="button"
														className="btn btn-danger"
														onClick={() => {
															setDesktopImage("");
															setDesktopFile();
														}}
													>
														{t("REMOVE_LOGO")}
													</button>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group row m-b-15">
												<label className="col-md-4 col-form-label">
													<b>{t("CSS_STYLE")}</b>
												</label>
												<div className="col-md-8">
													<textarea
														className="form-control"
														rows="20"
														style={{ marginTop: "10px" }}
														ref={register}
														name="content"
														defaultValue={content}
														onChange={(e) => {
															setContent(e.target.value);
															setValue("content", e.target.value);
														}}
													></textarea>
													{errors.content && (
														<div className="invalid-feedback">
															{t(errors.content.message)}
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group row m-b-15">
												<label className="col-md-4 col-form-label">
													<b>{t("THEME")}</b>
												</label>
												<div className="col-md-8">
													<div
														className="d-flex align-items-center justify-content-between"
														style={{ gap: "15px" }}
													>
														<div>
															<div className="d-flex align-items-center">
																<label className="s-label">
																	<input
																		type="checkbox"
																		className="s-input"
																		checked={selectedTheme == 1 ? true : false}
																		onChange={(e) => handleCheckboxChange(1, e)}
																	/>
																	<span className="slider"></span>
																</label>
																<span className="font-12 ml-2">Default</span>
															</div>
															<img
																src={require("../../assets/img/defaulttheme.png")}
																className="img-responsive w-300 mt-2"
																alt="icon"
															/>
														</div>

														<div>
															<div className="d-flex align-items-center">
																<label className="s-label">
																	<input
																		type="checkbox"
																		className="s-input"
																		checked={selectedTheme == 2 ? true : false}
																		onChange={(e) => handleCheckboxChange(2, e)}
																	/>
																	<span className="slider"></span>
																</label>
																<span className="font-12 ml-2">Light</span>
															</div>
															<img
																src={require("../../assets/img/lighttheme.png")}
																className="img-responsive w-300 mt-2"
																alt="icon"
															/>
														</div>

														<div>
															<div className="d-flex align-items-center">
																<label className="s-label">
																	<input
																		type="checkbox"
																		className="s-input"
																		checked={selectedTheme == 3 ? true : false}
																		onChange={(e) => handleCheckboxChange(3, e)}
																	/>
																	<span className="slider"></span>
																</label>
																<span className="font-12 ml-2">Dark</span>
															</div>
															<img
																src={require("../../assets/img/darktheme.png")}
																className="img-responsive w-300 mt-2"
																alt="icon"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<hr />
									<div className="row">
										<div className="col-lg-3">
											<div className="form-group">
												<button type="submit" className="btn btn-primary">
													{t("SUBMIT")}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default CustomizeWeb;
