import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	const isLoggedIn = useSelector((state) => state.authState.isLoggedIn);
	const _userData = useSelector((state) => state.authState.userData);

	return (
		<>
			<footer id="footer">
				{isLoggedIn && (
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 text-center">
								Copyright © {new Date().getFullYear()} ike4.com
							</div>
						</div>
					</div>
				)}
			</footer>
		</>
	);
};

export default Footer;
