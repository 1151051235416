/// <summary>
/// Author: -
/// </summary>
import axios from "axios";
import { ApiKey, WebUrl } from "./Constant.js";
import * as AppActions from "../redux/AppAction";
import * as AuthActions from "../redux/AuthAction";
import { store as Store } from "../ApplicationStore";
import i18n from "i18next";

const _HTTP_CODE_UNAUTHORIZED = 401;
const _HTTP_CODE_FORBIDDEN = 403;

/// <summary>
/// Author: -
/// </summary>
async function interceptRequest(requestConfig) {
  //Store.dispatch(AppActions.setBusy());
  return requestConfig;
}





/// <summary>
/// Author: -
/// </summary>
function interceptResponse(response) {
  Store.dispatch(
    AppActions.setPerformApiCall(
      response.data[ApiKey._API_SUCCESS_KEY],
      response.config.url
    )
  );
  //Store.dispatch(AppActions.setIdle());
  return response.data;
}

/// <summary>
/// Author: -
/// </summary>
function interceptError(error) {
  var returnData = {};
  Store.dispatch(AppActions.setIdle());
  if (error && error.response) {
    if (
      error.response.status == _HTTP_CODE_UNAUTHORIZED ||
      error.response.status == _HTTP_CODE_FORBIDDEN
    ) {
      console.log(error);
      Store.dispatch(AuthActions.performLogout());
      Store.dispatch(
        AppActions.showResponseMessage(false, i18n.t("SESSION_EXPIRED"))
      );
      returnData = {
        success: false,
        message: i18n.t("SESSION_EXPIRED"),
      };
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(error.response));
      }

      // window.location.replace(WebUrl._URL_MAIN);
    }
  }

  return returnData;
}

const ApiEngine = axios.create({
  // baseURL: null,
  withCredentials: true,
  headers: {
    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
  },
});

ApiEngine.interceptors.request.use(
  (config) => interceptRequest(config),
  (error) => interceptError(error)
);

ApiEngine.interceptors.response.use(
  (response) => interceptResponse(response),
  (error) => interceptError(error)
);

export default ApiEngine;
