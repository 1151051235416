import React, { useState, useEffect, useRef } from "react";
import "react-simple-keyboard/build/css/index.css";
import Sidebar from "components/sidebar/index2";
import { map } from "lodash";
import { mdiEyeOff, mdiHistory } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "components/button";
import ContentHeader from "components/content/header";
import moment from "moment";
import DateRangeFilter from "../../components/custom/DateRangeFilter";
import { ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import {
	setBusy,
	setIdle,
	showResponseMessage,
	setDownlineSearchText,
	setDownlineQuery,
	setPageSize,
	setY,
} from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import { Modal, ModalBody } from "reactstrap";
import {
	createMultiPartFormBody,
	numberWithCurrencyFormat,
	stringIsNullOrEmpty,
	createFormBody,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import DynamicLoadTable from "../../components/custom/DynamicLoadTable";
import Input from "../../components/custom/Input";
import { useForm } from "react-hook-form";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateUserData, checkIsLoggedIn } from "../../redux/AuthAction";
import Accordion from "react-bootstrap/Accordion";
import PinInput from "react-pin-input";

const Shareholders = (props) => {
	const { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	const _history = useHistory();
	const [isShowEditForm, setIsShowEditForm] = useState(false);
	const [shareholderIndex, setShareholderIndex] = useState("");
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showShareholderConfirmation, setShowShareholderConfirmation] =
		useState(false);
	const { handleSubmit } = useForm();
	const {
		handleSubmit: handleSubmitShareholder,
		register: registerShareholder,
		errors: errorsShareholder,
		setValue: setValueShareholder,
		getValues: getValuesShareholder,
		watch: watchShareholder,
	} = useForm();

	//const [startDate, setStartDate] = useState("2021-08-01"); // for quicker debug purpose
	//const [endDate, setEndDate] = useState("2021-08-01"); // for quicker debug purpose
	const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
	const [endDate, setEndDate] = useState(moment().subtract(1, "days"));
	const [allData, setAllData] = useState({});
	const [memberDepthData, setMemberDepthData] = useState({});

	const [processedData, setProcessedData] = useState({});
	const [processedUserTotal, setProcessedUserTotal] = useState({});
	const [processedCategoryTotal, setProcessedCategoryTotal] = useState({});
	const [processedSubTotal, setProcessedSubTotal] = useState({});
	const [processedGrandTotal, setProcessedGrandTotal] = useState({});
	const [processedUsernameRows, setProcessedUsernameRows] = useState({});
	const [companySummaryData, setCompanySummaryData] = useState({});
	const [companyCategoryTotal, setCompanyCategoryTotal] = useState({});
	const [companyGrandTotal, setCompanyGrandTotal] = useState({});

	const [claimData, setClaimData] = useState({});
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedMonth, setSelectedMonth] = useState("");

	const [member, setMember] = useState([]);
	const [tempData, setTempData] = useState({});
	const [showDynamicTable, setShowDynamicTable] = useState(true);

	const [share, setShare] = useState(0);
	const [hideSummary, setHideSummary] = useState(false);

	const [activeOptionsList, setActiveOptionsList] = useState([]);
	const [showCreateShareholderPin, setShowCreateShareholderPin] =
		useState(false);

	const [invalidPin, setInvalidPin] = useState(false);
	const [pinNumber, setPinNumber] = useState("");
	const [enteredPinNumber, setEnteredPinNumber] = useState("");
	const [pinNumberFocused, setPinNumberFocused] = useState(0);
	const [pinNumberConfirm, setPinNumberConfirm] = useState("");
	const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
	const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
	const elePin = useRef(null);
	const eleConfirm = useRef(null);

	const [activeTab, setActiveTab] = useState(1);
	const [tabName, setTabName] = useState("Shareholders");

	var { isLoggedIn } = useSelector((state) => state.authState);
	let isShareholder = useSelector((state) => state.appState.isShareholder);
	var _userData = useSelector((state) => state.authState.userData);
	var _hideSummary = _userData.hideSummary;
	var activeTheme = _userData.selectedTheme;
	let _savedData = useSelector((state) => state.appState.savedText);
	let _savedQuery = useSelector((state) => state.appState.savedQuery);
	let _savedPageSize = useSelector((state) => state.appState.pageSize);

	const companyToggle = (category) => {
		let tempObj = companyCategoryTotal;
		if (category !== "null") {
			tempObj[category].show = !tempObj[category].show;
		} else {
			tempObj.show = !tempObj.show;
		}
		setCompanyCategoryTotal({ ...tempObj });
	};

	const settlementToggle = (username) => {
		let tempObj = processedUserTotal;
		tempObj[username].showset = !tempObj[username].showset;
		setProcessedUserTotal({ ...tempObj });
	};

	const settlementCatToggle = (username, category) => {
		let tempObj = processedCategoryTotal;
		tempObj[username][category].showset = !tempObj[username][category].showset;
		setProcessedCategoryTotal({ ...tempObj });
	};

	const usernameToggle = (username) => {
		let tempObj = processedUserTotal;
		tempObj[username].show = !tempObj[username].show;
		setProcessedUserTotal({ ...tempObj });
	};
	const categoryToggle = (username, category) => {
		let tempObj = processedCategoryTotal;
		tempObj[username][category].show = !tempObj[username][category].show;
		setProcessedCategoryTotal({ ...tempObj });
	};

	useEffect(() => {
		if (_userData["hideSummary"]) {
			setActiveTab(1);
		}

		return () => {
			// window.removeEventListener("scroll");
		};
	}, []);

	useEffect(() => {
		init(startDate, endDate);
	}, []);

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
		if (tab === 2) {
			claimInit();
		}
	};

	function getText(e) {
		_dispatch(setDownlineSearchText(e.target.value));
		_dispatch(
			setDownlineQuery(
				ApiUrl._API_GET_USER_MEMBER_LIST +
					"?keyword=" +
					e.target.value +
					"&directUserOnly=true"
			)
		);
		_dispatch(setPageSize(10));
		_dispatch(setY(0));
	}

	async function init(sDate, eDate) {
		_dispatch(setBusy());
		setStartDate(sDate);
		setEndDate(eDate);
		let params = {};
		if (checkIsLoggedIn) {
			if (isShareholder) {
				var responseJson2 = await ApiEngine.post(
					ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
					createFormBody(params)
				);

				if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
					var theInterval = setInterval(() => {
						if (elePin["current"] == undefined) return;

						if (
							elePin["current"]["values"][0] == "" ||
							elePin["current"]["values"][0] == undefined
						) {
							elePin["current"]["elements"][0].focus();
						} else if (
							elePin["current"]["values"][1] == "" ||
							elePin["current"]["values"][1] == undefined
						) {
							elePin["current"]["elements"][1].focus();
						} else if (
							elePin["current"]["values"][2] == "" ||
							elePin["current"]["values"][2] == undefined
						) {
							elePin["current"]["elements"][2].focus();
						} else if (
							elePin["current"]["values"][3] == "" ||
							elePin["current"]["values"][3] == undefined
						) {
							elePin["current"]["elements"][3].focus();
						} else if (
							eleConfirm["current"]["values"][0] == "" ||
							eleConfirm["current"]["values"][0] == undefined
						) {
							eleConfirm["current"]["elements"][0].focus();
						} else if (
							eleConfirm["current"]["values"][1] == "" ||
							eleConfirm["current"]["values"][1] == undefined
						) {
							eleConfirm["current"]["elements"][1].focus();
						} else if (
							eleConfirm["current"]["values"][2] == "" ||
							eleConfirm["current"]["values"][2] == undefined
						) {
							eleConfirm["current"]["elements"][2].focus();
						} else if (
							eleConfirm["current"]["values"][3] == "" ||
							eleConfirm["current"]["values"][3] == undefined
						) {
							eleConfirm["current"]["elements"][3].focus();
						} else {
							var currpin =
								elePin["current"]["values"][0] +
								elePin["current"]["values"][1] +
								elePin["current"]["values"][2] +
								elePin["current"]["values"][3];
							var currconfpin =
								eleConfirm["current"]["values"][0] +
								eleConfirm["current"]["values"][1] +
								eleConfirm["current"]["values"][2] +
								eleConfirm["current"]["values"][3];
							if (currpin != currconfpin) {
								setInvalidPin(true);
								elePin["current"].clear();
								eleConfirm["current"].clear();
								elePin["current"].focus();
							} else {
								setInvalidPin(false);
								clearInterval(theInterval);
								submitPin(currpin, currconfpin);
							}
						}
					}, 50);
					setShowCreateShareholderPin(true);
				}
			}

			var responseJson = await ApiEngine.get(
				ApiUrl._API_CHECK_HIDE_SUMMARY + "?hideSummary=" + _hideSummary
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				responseJson = await ApiEngine.get(
					`${ApiUrl._API_GET_MEMBER_SHAREHOLDER_DETAIL}?&startdate=${moment(
						sDate
					).format("YYYY-MM-DD")}&enddate=${moment(eDate).format("YYYY-MM-DD")}`
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					let data = responseJson[ApiKey._API_DATA_KEY]["shareholderData"];
					setAllData(data);
					let memberData = responseJson[ApiKey._API_DATA_KEY]["depthData"];
					setMemberDepthData(memberData);

					let summaryRows = {};
					let summaryCategoryTotal = { show: false };
					let summaryGrandTotal = {
						sales: 0,
						vsales: 0,
						payout: 0,
						netProfit: 0,
					};
					if (data != null) {
						data["summaryData"].map((summary, index) => {
							if (!summaryRows[summary.categoryName]) {
								summaryRows[summary.categoryName] = {};
								summaryCategoryTotal[summary.categoryName] = {
									grossSales: 0,
									validTurnover: 0,
									payout: 0,
									netProfit: 0,
								};
							}

							if (!summaryRows[summary.categoryName][summary.productName]) {
								summaryRows[summary.categoryName][summary.productName] = {
									grossSales: summary["grossSales"],
									validTurnover: summary["validTurnover"],
									payout: summary["payout"],
									netProfit: summary["netProfit"],
								};
							}

							summaryCategoryTotal[summary.categoryName].show = false;
							summaryCategoryTotal[summary.categoryName].grossSales +=
								summary["grossSales"];
							summaryCategoryTotal[summary.categoryName].validTurnover +=
								summary["validTurnover"];
							summaryCategoryTotal[summary.categoryName].payout +=
								summary["payout"];
							summaryCategoryTotal[summary.categoryName].netProfit +=
								summary["netProfit"];

							if (summary.categoryName !== null) {
								summaryGrandTotal.sales += summary["grossSales"];
								summaryGrandTotal.vsales += summary["validTurnover"];
								summaryGrandTotal.payout += summary["payout"];
								summaryGrandTotal.netProfit += summary["netProfit"];
							} else {
								summaryGrandTotal.netProfit += summary["netProfit"];
							}
						});
						setCompanySummaryData(summaryRows);
						setCompanyCategoryTotal(summaryCategoryTotal);
						setCompanyGrandTotal(summaryGrandTotal);

						let tempRows = {};
						let dataRows = {};
						let userTotal = {};
						let categoryTotal = {};
						let subTotal = { sales: 0, vsales: 0, netProfit: 0 };
						let grandTotal = { sales: 0, vsales: 0, netProfit: 0 };

						data["downlineData"].map((e, i) => {
							if (!dataRows[e.username]) {
								dataRows[e.username] = {};
								tempRows[e.username] = {
									memberGuid: e.memberId,
									hasDownline: e.usernameHasDownlineData,
								};
								userTotal[e.username] = {
									grossSales: 0,
									validTurnover: 0,
									netProfit: 0,
									profit: 0,
									settlement: 0,
									share: 0,
								};
								categoryTotal[e.username] = {};
							}

							if (!dataRows[e.username][e.categoryName]) {
								dataRows[e.username][e.categoryName] = {};
								categoryTotal[e.username][e.categoryName] = {
									grossSales: 0,
									validTurnover: 0,
									netProfit: 0,
									profit: 0,
									settlement: 0,
									share: 0,
								};
							}

							if (!dataRows[e.username][e.categoryName][e.productName]) {
								dataRows[e.username][e.categoryName][e.productName] = {
									grossSales: e.grossSales,
									validTurnover: e.validTurnover,
									netProfit: e.netProfit,
									profit: e.profit,
									settlement: e.settlement,
									share: e.share,
								};
							}

							userTotal[e.username].show = false;
							userTotal[e.username].showset = false;
							userTotal[e.username].grossSales += e.grossSales;
							userTotal[e.username].validTurnover += e.validTurnover;
							userTotal[e.username].netProfit += e.netProfit;
							userTotal[e.username].profit += e.profit;
							userTotal[e.username].settlement += e.settlement;
							userTotal[e.username].share = e.share;

							categoryTotal[e.username][e.categoryName].show = false;
							categoryTotal[e.username][e.categoryName].showset = false;
							categoryTotal[e.username][e.categoryName].grossSales +=
								e.grossSales;
							categoryTotal[e.username][e.categoryName].validTurnover +=
								e.validTurnover;
							categoryTotal[e.username][e.categoryName].netProfit +=
								e.netProfit;
							categoryTotal[e.username][e.categoryName].profit += e.profit;
							categoryTotal[e.username][e.categoryName].settlement +=
								e.settlement;
							categoryTotal[e.username][e.categoryName].share = e.share;

							subTotal.sales += e.grossSales;
							subTotal.vsales += e.validTurnover;
							subTotal.netProfit += e.profit;

							grandTotal.sales += e.grossSales;
							grandTotal.vsales += e.validTurnover;
							grandTotal.netProfit += e.profit;
							grandTotal.netProfit += e.settlement;
						});
						setProcessedUserTotal(userTotal);
						setProcessedCategoryTotal(categoryTotal);
						setProcessedData(dataRows);
						setProcessedUsernameRows(tempRows);
						setProcessedSubTotal(subTotal);
						setProcessedGrandTotal(grandTotal);
					}
				} else {
					_dispatch(
						showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
					);
				}
				claimInit();
			} else {
				_dispatch(
					showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
				);
				_history.push(WebUrl._URL_MAIN);
			}
		}

		_dispatch(setIdle());
	}

	useEffect(() => {
		if (!stringIsNullOrEmpty(selectedMonth)) {
			setSelectedIndex(
				claimData.bonusDetail.findIndex((bd) => bd.month == selectedMonth)
			);
		}
	}, [selectedMonth]);

	async function claimInit() {
		_dispatch(setBusy());
		var responseJson = await ApiEngine.get(
			ApiUrl._API_GET_MEMBER_SHAREHOLDER_UNCLAIM
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setClaimData(responseJson[ApiKey._API_DATA_KEY]);
		} else {
			_dispatch(
				showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
			);
		}
		_dispatch(setIdle());
	}

	function preSubmit() {
		setShowConfirmation(true);
	}

	async function submitForm() {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_CLAIM_SHAREHOLDER,
			createMultiPartFormBody({})
		);
		_dispatch(setIdle());

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			_dispatch(updateUserData());
			claimInit();
		}

		_dispatch(
			showResponseMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				responseJson[ApiKey._API_MESSAGE_KEY]
			)
		);
	}

	async function preShareholderSubmit(data, e) {
		e.preventDefault();
		setTempData(data);
		if (member["isShareHolder"] != 1) {
			setShowShareholderConfirmation(true);
		} else {
			await submitShareholderForm(data);
		}
	}

	async function submitShareholderForm(data) {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_SHAREHOLDER,
			createMultiPartFormBody({
				memberId: member["id"],
				share: share,
				hideSummary: hideSummary,
			})
		);
		setIsShowEditForm(false);
		_dispatch(setY(0));
		setShowDynamicTable(false);
		setTimeout(() => setShowDynamicTable(true), 1);
		_dispatch(setIdle());
	}

	async function handleEditShareholderBtn(index, downline, e) {
		_dispatch(setBusy());
		e.preventDefault();
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_MEMBER_DETAILS_BY_ID +
				"?memberId=" +
				downline["id"] +
				"&shareholder=true"
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let editingDownlineData = responseJson[ApiKey._API_DATA_KEY];
			let tempOptionsList = [];
			for (
				var i = editingDownlineData["minShare"];
				i <= editingDownlineData["maxShare"];
				i += 5
			) {
				tempOptionsList.push(i.toFixed(2));
			}
			setActiveOptionsList(tempOptionsList);
			setMember(responseJson[ApiKey._API_DATA_KEY]);
			setHideSummary(responseJson[ApiKey._API_DATA_KEY]["hideSummary"]);
			setShare(
				responseJson[ApiKey._API_DATA_KEY]["isShareHolder"] == 1
					? responseJson[ApiKey._API_DATA_KEY]["sharePercent"]
					: responseJson[ApiKey._API_DATA_KEY]["maxShare"]
			);

			setShareholderIndex(index);
			setIsShowEditForm(true);
		}
		_dispatch(setIdle());
	}

	function onCreatePinKeyPress(value) {
		// 1st: have to know if entering for elePin or eleConfirm
		// 2nd: have to know which index is currently focused for altering the state.value
		// 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
		// 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

		let currentIndex = 0;
		let pinStr = enteredPinNumber;
		let pinConfirmStr = enteredPinNumberConfirm;
		if (value !== "{bksp}") {
			if (pinNumber === "") {
				currentIndex = pinNumberFocused;
				// elePin["current"]["elements"][currentIndex].state.value = value;
				pinStr = value;
				setEnteredPinNumber(pinStr);
				if (currentIndex !== 3) {
					currentIndex++;
					setPinNumberFocused(currentIndex);
					elePin["current"]["elements"][currentIndex].focus();
				} else {
					setPinNumber(pinStr);
				}
			} else {
				currentIndex = pinNumberConfirmFocused;
				// eleConfirm["current"]["elements"][currentIndex].state.value = value;
				pinConfirmStr = value;
				setEnteredPinNumberConfirm(pinConfirmStr);
				currentIndex++;
				setPinNumberConfirmFocused(currentIndex);
				if (currentIndex < 3) {
					eleConfirm["current"]["elements"][currentIndex].focus();
				} else {
					setPinNumberConfirm(pinConfirmStr);
					// setTimeout(function () {
					//   submitPin(pinNumber, pinConfirmStr);
					// }, 1000);
				}
			}
		} else {
			elePin["current"].clear();
			eleConfirm["current"].clear();
			setPinNumber("");
			setEnteredPinNumber("");
			setPinNumberFocused(currentIndex);
			setPinNumberConfirm("");
			setEnteredPinNumberConfirm("");
			setPinNumberConfirmFocused(currentIndex);
			elePin["current"]["elements"][currentIndex].focus();
		}
	}

	async function submitPin(pin, conf) {
		try {
			let params = {
				pinNumber: pin,
				confirmPinNumber: conf,
			};
			let responseJson = await ApiEngine.post(
				ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
				createFormBody(params)
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setShowCreateShareholderPin(false);
				setInvalidPin(false);
				_history.push(WebUrl._URL_SHAREHOLDERS);
			}
			_dispatch(
				showResponseMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					responseJson[ApiKey._API_MESSAGE_KEY]
				)
			);

			elePin["current"].clear();
			eleConfirm["current"].clear();
			setPinNumber("");
			setEnteredPinNumber("");
			setPinNumberFocused(0);
			setPinNumberConfirm("");
			setEnteredPinNumberConfirm("");
			setPinNumberConfirmFocused(0);
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
	}

	const adjustFocus = () => {
		if (eleConfirm["current"] !== null) {
			if (
				eleConfirm["current"]["values"][0] == "" ||
				eleConfirm["current"]["values"][0] == undefined
			) {
				eleConfirm["current"]["elements"][0].focus();
			}
		}
	};

	return (
		<>
			<div className="">
				<div className="container-fluid">
					<ContentHeader title={tabName} />
					<div className="row">
						<div className="card p-0">
							<div className="card-header">
								<Input
									placeholder={t("ALIAS_NAME_PHONE")}
									defaultValue={_savedData}
									onInput={(e) => {
										getText(e);
									}}
									containerClassName="mb-0"
									inputGroupType="append"
									inputGroupContent={
										<i
											className="fas fa-search"
											style={{
												fontSize: "15px",
											}}
										></i>
									}
								/>
							</div>
							<div className="card-body">
								<>
									<Accordion>
										{showDynamicTable && (
											<DynamicLoadTable
												pageSize={_savedPageSize}
												apiQuery={_savedQuery}
												searchEnabled={true}
												autoscrollEnabled={true}
												render={(downline, index) => {
													return (
														<>
															<Accordion.Item eventKey={index}>
																<Accordion.Header
																	onClick={() => {
																		setIsShowEditForm(false);
																	}}
																>
																	<p className="font-semi mb-0 text-left shareholder-info">
																		{downline["username"]}
																		{!stringIsNullOrEmpty(downline["fullname"])
																			? "-" + downline["fullname"]
																			: downline["fullname"]}
																		<br />
																		{downline["nickname"] != "" && (
																			<>
																				<div className="d-block">
																					({downline["nickname"]})
																				</div>
																			</>
																		)}
																		{downline["phoneNumber"]}
																	</p>
																	{downline["isShareHolder"] === true ? (
																		<Button
																			text={t("SHAREHOLDERS")}
																			type="ghost"
																			style={{
																				marginLeft: "20px",
																			}}
																			size="small"
																			rightIcon={
																				downline.hideSummary === true ? (
																					<Icon
																						path={mdiEyeOff}
																						size={0.7}
																						// color="#FFFFFF"
																						style={{
																							marginLeft: 10,
																						}}
																					/>
																				) : null
																			}
																			onClick={() => {}}
																		/>
																	) : null}
																</Accordion.Header>
																<Accordion.Body>
																	{downline["isShareHolder"] == 1 ? (
																		<>
																			<div className="shareholder-accordion-details">
																				<span className="detail-title font-semi mb-3">
																					{t("SHAREHOLDERDATE")}
																				</span>
																				<span className="detail-value mb-3">
																					{downline["shareHolderDate"] !== null
																						? moment(
																								downline["shareHolderDate"]
																						  ).format(
																								"YYYY-MM-DD"
																								// "YYYY-MM-DD HH:mm:ss"
																						  )
																						: ""}
																				</span>
																			</div>

																			{isShowEditForm ? (
																				<form
																					id={index}
																					onSubmit={handleSubmitShareholder(
																						preShareholderSubmit
																					)}
																				>
																					<div className="shareholder-accordion-details">
																						<div className="display-flex flex-column">
																							<span className="detail-title font-semi mb-3">
																								{t("SHAREPERCENT")}
																							</span>
																							<span
																								className="detail-title"
																								style={{
																									marginTop: "-15px",
																								}}
																							>
																								{t("MIN") +
																									" : " +
																									member["minShare"] +
																									"%, " +
																									t("MAX") +
																									" : " +
																									member["maxShare"] +
																									"%"}
																							</span>
																						</div>
																						<span>
																							<div
																								className={
																									"btn btn-outline-info rounded-pill referral-add-minus"
																								}
																							>
																								<select
																									className={""}
																									value={share}
																									defaultValue={parseFloat(
																										downline[
																											"sharePercent"
																										].toFixed(2)
																									)}
																									placeholder={t(
																										"SHAREPERCENT"
																									)}
																									onChange={(e) => {
																										setShare(e.target.value);
																									}}
																								>
																									{activeOptionsList.map(
																										(percent, index) => {
																											return (
																												<option
																													key={"values" + index}
																													className="bonusComm-option-value"
																													value={parseFloat(
																														percent
																													)}
																												>
																													{percent}
																												</option>
																											);
																										}
																									)}
																								</select>
																							</div>
																						</span>
																					</div>
																					{_hideSummary !== true && (
																						<div className="shareholder-edit-accordion-details">
																							<input
																								// id="classic"
																								type="checkbox"
																								ref={registerShareholder()}
																								name="hideSummary"
																								className="hide-summary-radio-btn"
																								defaultChecked={
																									downline["hideSummary"]
																								}
																								onChange={(e) =>
																									setHideSummary(
																										e.target.checked
																									)
																								}
																							/>

																							<span className="detail-title font-semi">
																								{t("HIDE_SUMMARY")}
																							</span>
																						</div>
																					)}
																					<div className="shareholder-accordion-details">
																						<span></span>
																						<div>
																							<button
																								type="submit"
																								className="shareholder-save-btn btn btn-primary"
																							>
																								{t("SAVE")}
																							</button>
																						</div>
																					</div>
																				</form>
																			) : (
																				<div id={index}>
																					<div className="shareholder-accordion-details">
																						<span className="detail-title font-semi mb-3">
																							{t("SHAREPERCENT")}
																						</span>
																						<span className="detail-value">
																							{downline["sharePercent"].toFixed(
																								2
																							)}
																						</span>
																					</div>

																					{_hideSummary !== true && (
																						<div className="shareholder-accordion-details">
																							<span className="detail-title font-semi mb-3">
																								{t("HIDE_SUMMARY")}
																							</span>
																							<div
																								href=""
																								className={
																									downline["hideSummary"]
																										? "yes-no-button green-btn small-btn mb-3"
																										: "yes-no-button red-btn small-btn mb-3"
																								}
																							>
																								{downline["hideSummary"]
																									? t("YES")
																									: t("NO")}
																							</div>
																						</div>
																					)}

																					<div className="shareholder-accordion-details">
																						<a
																							onClick={(e) => {
																								handleEditShareholderBtn(
																									index,
																									downline,
																									e
																								);
																							}}
																							className="href-decoration mb-3 text-gold cursor-pointer"
																						>
																							{t("EDITSHAREHOLDER")}
																						</a>
																					</div>
																				</div>
																			)}
																		</>
																	) : isShowEditForm ? (
																		<form
																			id={index}
																			onSubmit={handleSubmitShareholder(
																				preShareholderSubmit
																			)}
																		>
																			<div className="shareholder-accordion-details">
																				<div className="display-flex flex-column">
																					<span className="detail-title font-semi mb-3">
																						{t("SHAREPERCENT")}
																					</span>
																					<span
																						className="detail-title"
																						style={{
																							marginTop: "-15px",
																						}}
																					>
																						{t("MIN") +
																							" : " +
																							member["minShare"] +
																							"%, " +
																							t("MAX") +
																							" : " +
																							member["maxShare"] +
																							"%"}
																					</span>
																				</div>
																				<span>
																					<div
																						className={
																							"btn btn-outline-info rounded-pill referral-add-minus"
																						}
																					>
																						<select
																							className={""}
																							value={share}
																							defaultValue={parseFloat(
																								downline[
																									"sharePercent"
																								].toFixed(2)
																							)}
																							placeholder={t("SHAREPERCENT")}
																							onChange={(e) => {
																								setShare(e.target.value);
																							}}
																						>
																							{activeOptionsList.map(
																								(percent, index) => {
																									return (
																										<option
																											key={"values" + index}
																											className="bonusComm-option-value"
																											value={parseFloat(
																												percent
																											)}
																										>
																											{percent}
																										</option>
																									);
																								}
																							)}
																						</select>
																					</div>
																				</span>
																			</div>
																			{_hideSummary !== true && (
																				<div className="shareholder-edit-accordion-details">
																					<input
																						// id="classic"
																						type="checkbox"
																						ref={registerShareholder()}
																						name="hideSummary"
																						className="hide-summary-radio-btn mb-4"
																						defaultChecked={
																							downline["hideSummary"]
																						}
																						onChange={(e) =>
																							setHideSummary(e.target.checked)
																						}
																					/>

																					<span className="detail-title font-semi mb-3">
																						{t("HIDE_SUMMARY")}
																					</span>
																				</div>
																			)}
																			<div className="shareholder-accordion-details">
																				<span></span>
																				<div>
																					<button
																						type="submit"
																						className="shareholder-save-btn btn btn-primary"
																					>
																						{t("SAVE")}
																					</button>
																				</div>
																			</div>
																		</form>
																	) : (
																		<div className="shareholder-accordion-details">
																			<a
																				onClick={(e) => {
																					handleEditShareholderBtn(
																						index,
																						downline,
																						e
																					);
																				}}
																				className="href-decoration text-gold cursor-pointer"
																			>
																				{t("ADDSHAREHOLDER")}
																			</a>
																		</div>
																	)}
																</Accordion.Body>
															</Accordion.Item>
														</>
													);
												}}
											/>
										)}
									</Accordion>
								</>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showConfirmation && (
				<SweetAlert
					info
					showCancel
					confirmBtnText={t("CONFIRM")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="info"
					title={t("CONFIRM_CLAIM")}
					onConfirm={() => {
						setShowConfirmation(false);
						handleSubmit(submitForm)();
					}}
					onCancel={() => {
						setShowConfirmation(false);
					}}
				></SweetAlert>
			)}

			{showShareholderConfirmation && (
				<SweetAlert
					info
					showCancel
					confirmBtnText={t("CONFIRM")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="info"
					title={t("CONFIRM_APPOINT_AS_SHAREHOLDER", {
						username: member["username"],
					})}
					onConfirm={() => {
						setShowShareholderConfirmation(false);
						submitShareholderForm(tempData);
					}}
					onCancel={() => {
						setShowShareholderConfirmation(false);
					}}
				></SweetAlert>
			)}

			<Modal
				fade={false}
				contentClassName="modal-brand modal-bottom modal-numpad"
				isOpen={isLoggedIn && showCreateShareholderPin}
				centered
			>
				<ModalBody>
					<form>
						<div
							className="display-wrapper"
							style={{ textAlign: "center" }}
							onKeyDown={(e) => {
								if (e.key === "Backspace") {
									onCreatePinKeyPress("{bksp}");
								}
							}}
						>
							<strong
								className="reload-title text-yellow mb-3 d-block"
								style={{ fontSize: "20px" }}
							>
								{t("NO_SHAREHOLDER_PIN_FOUND")}
							</strong>
							<strong
								className="reload-title mb-3 d-block"
								style={{
									fontSize: "14px",
									fontWeight: "bold",
									color: "#002e6c",
								}}
							>
								{t("ENTER_YOUR_PIN")}
							</strong>
							<PinInput
								ref={elePin}
								length={4}
								initialValue=""
								secret={true}
								focus={true}
								autoSelect={false}
								// disabled={showCreateShareholderPin}
								type="numeric"
								onChange={(value, index) => {
									setPinNumberFocused(index);
									onCreatePinKeyPress(value);
								}}
								onComplete={(value) => adjustFocus()}
								inputMode="numeric"
								inputStyle={{
									border: "0",
									margin: "0px 10px",
									width: "40px",
									height: "40px",
									backgroundColor: "transparent",
									borderBottom: "2px solid #002e6c",
								}}
								inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
								regexCriteria={/^[ 0-9_@./#&+-]*$/}
							/>
							<strong
								className="reload-title mb-3 d-block"
								style={{
									fontSize: "14px",
									fontWeight: "bold",
									color: "#002e6c",
									marginTop: "30px",
								}}
							>
								{t("CONFIRM_YOUR_PIN")}
							</strong>
							<PinInput
								ref={eleConfirm}
								length={4}
								initialValue=""
								secret={true}
								focus={false}
								autoSelect={false}
								// disabled={showCreateShareholderPin}
								type="numeric"
								onChange={(value, index) => {
									setPinNumberConfirmFocused(index);
									onCreatePinKeyPress(value);
								}}
								inputMode="numeric"
								inputStyle={{
									border: "0",
									margin: "0px 10px",
									width: "40px",
									height: "40px",
									backgroundColor: "transparent",
									borderBottom: "2px solid #002e6c",
								}}
								inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
								regexCriteria={/^[ 0-9_@./#&+-]*$/}
							/>
							<div
								className="invalid-feedback"
								style={{ fontSize: "12px", marginTop: "1rem" }}
							>
								{invalidPin && t("PIN_DOES_NOT_MATCH")}
								{!invalidPin && <span>&nbsp;</span>}
							</div>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</>
	);
};
export default Shareholders;
