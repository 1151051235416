import React, { useState, useRef, useEffect } from "react";
import { map } from "lodash";
import { vipSideMenu } from "../../config/game-configs";
import { performLogout } from "../../redux/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine.js";
import PinInput from "react-pin-input";
import "react-simple-keyboard/build/css/index.css";
import { ApiKey, ApiUrl, SessionKey, WebUrl } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { Alert, Modal, ModalBody } from "reactstrap";
import { showResponseMessage } from "../../redux/AppAction";

const SideMenu = (props) => {
  const { t, i18n } = useTranslation();
  const _dispatch = useDispatch();
  const _history = useHistory();
  const [theInterval, setTheInterval] = useState(false);
  const [invalidPin, setInvalidPin] = useState(false);
  const [pinNumber, setPinNumber] = useState("");
  const [enteredPinNumber, setEnteredPinNumber] = useState("");
  const [pinNumberFocused, setPinNumberFocused] = useState(0);
  const [pinNumberConfirm, setPinNumberConfirm] = useState("");
  const [enteredPinNumberConfirm, setEnteredPinNumberConfirm] = useState("");
  const [pinNumberConfirmFocused, setPinNumberConfirmFocused] = useState(0);
  const [pinNumberFinal, setPinNumberFinal] = useState("");
  const [enteredPinNumberFinal, setEnteredPinNumberFinal] = useState("");
  const [pinNumberFinalFocused, setPinNumberFinalFocused] = useState(0);
  const [forgetPin, setForgetPin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPinEntry, setShowPinEntry] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showHpErrorMessage, setShowHpErrorMessage] = useState(false);
  const [hpErrorMessage, setHpErrorMessage] = useState("");
  const [smsSent, setSmsSent] = useState(false);
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const _COUNTDOWN_PERIOD = 120;
  const _SECONDS_IN_MS = 1000;
  const _ALERT_TIMEOUT = 3000;

  const elePin = useRef(null);
  const eleConfirm = useRef(null);
  const eleFinalPin = useRef(null);
  const keyboard = useRef();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState("");
  var { isLoggedIn } = useSelector((state) => state.authState);
  var _userData = useSelector((state) => state.authState.userData);
  const [createShareholderPin, setCreateShareholderPin] = useState(false);
  const [showCreateShareholderPin, setShowCreateShareholderPin] =
    useState(false);

  async function sendPinCode() {
    if (countdownPeriod < 1) {
      let responseJson = await ApiEngine.get(
        ApiUrl._API_CHECK_IF_PHONE_VERIFIED
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setShowHpErrorMessage(false);
        var hpNum = responseJson[ApiKey._API_DATA_KEY];
        let params = {
          phoneNumber: hpNum,
          isShareholder: "true",
        };
        let smsJson = await ApiEngine.post(
          ApiUrl._API_SEND_PIN_CODE,
          createFormBody(params)
        );
        if (smsJson[ApiKey._API_SUCCESS_KEY]) {
          setForgetPin(false);
          setSmsSent(true);
          setCountdownPeriod(_COUNTDOWN_PERIOD);
        } else {
          setHpErrorMessage(smsJson[ApiKey._API_MESSAGE_KEY]);
          setShowHpErrorMessage(true);
        }
      } else {
        setHpErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
        setShowHpErrorMessage(true);
      }
    } else {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), [_ALERT_TIMEOUT]);
    }
  }

  function onCreatePinKeyPress(value) {
    // 1st: have to know if entering for elePin or eleConfirm
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin

    let currentIndex = 0;
    let pinStr = enteredPinNumber;
    let pinConfirmStr = enteredPinNumberConfirm;
    if (value !== "{bksp}") {
      if (pinNumber === "") {
        currentIndex = pinNumberFocused;
        // elePin["current"]["elements"][currentIndex].state.value = value;
        pinStr = value;
        setEnteredPinNumber(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFocused(currentIndex);
          elePin["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumber(pinStr);
        }
      } else {
        currentIndex = pinNumberConfirmFocused;
        // eleConfirm["current"]["elements"][currentIndex].state.value = value;
        pinConfirmStr = value;
        setEnteredPinNumberConfirm(pinConfirmStr);
        currentIndex++;
        setPinNumberConfirmFocused(currentIndex);
        if (currentIndex < 3) {
          eleConfirm["current"]["elements"][currentIndex].focus();
        } else {
          setPinNumberConfirm(pinConfirmStr);
          // setTimeout(function () {
          //   submitPin(pinNumber, pinConfirmStr);
          // }, 1000);
        }
      }
    } else {
      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(currentIndex);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(currentIndex);
      elePin["current"]["elements"][currentIndex].focus();
    }
  }

  async function submitPin(pin, conf) {
    try {
      let params = {
        pinNumber: pin,
        confirmPinNumber: conf,
      };
      let responseJson = await ApiEngine.post(
        ApiUrl._API_SET_SHAREHOLDER_PIN_NUMBER,
        createFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setCreateShareholderPin(false);
        setShowCreateShareholderPin(false);
        setInvalidPin(false);
        _history.push(WebUrl._URL_VIP);
      }
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );

      elePin["current"].clear();
      eleConfirm["current"].clear();
      setPinNumber("");
      setEnteredPinNumber("");
      setPinNumberFocused(0);
      setPinNumberConfirm("");
      setEnteredPinNumberConfirm("");
      setPinNumberConfirmFocused(0);
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  const adjustFocus = () => {
    if (eleConfirm["current"] !== null) {
      if (
        eleConfirm["current"]["values"][0] == "" ||
        eleConfirm["current"]["values"][0] == undefined
      ) {
        eleConfirm["current"]["elements"][0].focus();
      }
    }
  };

  function onVerifyPinKeyPress(value) {
    let currentIndex = 0;

    let pinStr = enteredPinNumberFinal;
    if (value !== "{bksp}") {
      if (pinNumberFinal === "") {
        currentIndex = pinNumberFinalFocused;
        // eleFinalPin["current"]["elements"][currentIndex].state.value = value;
        pinStr = value;
        setEnteredPinNumberFinal(pinStr);
        if (currentIndex !== 3) {
          currentIndex++;
          setPinNumberFinalFocused(currentIndex);
          eleFinalPin["current"]["elements"][currentIndex].focus();
        } else {
          verifyPin(pinStr);
        }
      }
    } else {
      eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(currentIndex);
      eleFinalPin["current"]["elements"][currentIndex].focus();
    }
  }

  const verifyPin = async (pin) => {
    // if (pin === "0000") {
    //   _history.push(WebUrl._URL_SETTINGS); // need to change
    //   setInvalidPin(false);
    // } else {
    let params = {
      pinNumber: pin,
    };
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setInvalidPin(false);
      //_history.push(WebUrl._URL_VIP);
      setShowPinEntry(false);
      localStorage.setItem("IsVerified", 1);
    } else {
      setInvalidPin(true);
      setErrorMessage(responseJson[ApiKey._API_MESSAGE_KEY]);
      //eleFinalPin["current"].clear();
      setPinNumberFinal("");
      setEnteredPinNumberFinal("");
      setPinNumberFinalFocused(0);
      // eleFinalPin["current"]["elements"][0].focus();
    }
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NOTIFICATION_LIST +
          "?languageCode=" +
          (localStorage.getItem(SessionKey._LANGUAGE) &&
            localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
            ? localStorage.getItem(SessionKey._LANGUAGE)
            : "en")
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var allData =
            responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

          allData.forEach(function (obj) {
            setUnreadNotificationCount(obj.unReadNotificationCount);
            localStorage.setItem(
              "unreadNotificationCount",
              obj.unReadNotificationCount
            );
          });
        } else {
          _dispatch(
            showResponseMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              responseJson[ApiKey._API_MESSAGE_KEY]
            )
          );
        }

        let params = {};
        if (_userData.isShareholder) {
          var responseJson2 = await ApiEngine.post(
            ApiUrl._API_CHECK_SHAREHOLDER_PIN_NUMBER,
            createFormBody(params)
          );

          if (!responseJson2[ApiKey._API_SUCCESS_KEY]) {
            var theInterval = setInterval(() => {
              if (elePin["current"] == undefined) return;

              if (
                elePin["current"]["values"][0] == "" ||
                elePin["current"]["values"][0] == undefined
              ) {
                elePin["current"]["elements"][0].focus();
              } else if (
                elePin["current"]["values"][1] == "" ||
                elePin["current"]["values"][1] == undefined
              ) {
                elePin["current"]["elements"][1].focus();
              } else if (
                elePin["current"]["values"][2] == "" ||
                elePin["current"]["values"][2] == undefined
              ) {
                elePin["current"]["elements"][2].focus();
              } else if (
                elePin["current"]["values"][3] == "" ||
                elePin["current"]["values"][3] == undefined
              ) {
                elePin["current"]["elements"][3].focus();
              } else if (
                eleConfirm["current"]["values"][0] == "" ||
                eleConfirm["current"]["values"][0] == undefined
              ) {
                eleConfirm["current"]["elements"][0].focus();
              } else if (
                eleConfirm["current"]["values"][1] == "" ||
                eleConfirm["current"]["values"][1] == undefined
              ) {
                eleConfirm["current"]["elements"][1].focus();
              } else if (
                eleConfirm["current"]["values"][2] == "" ||
                eleConfirm["current"]["values"][2] == undefined
              ) {
                eleConfirm["current"]["elements"][2].focus();
              } else if (
                eleConfirm["current"]["values"][3] == "" ||
                eleConfirm["current"]["values"][3] == undefined
              ) {
                eleConfirm["current"]["elements"][3].focus();
              } else {
                var currpin =
                  elePin["current"]["values"][0] +
                  elePin["current"]["values"][1] +
                  elePin["current"]["values"][2] +
                  elePin["current"]["values"][3];
                var currconfpin =
                  eleConfirm["current"]["values"][0] +
                  eleConfirm["current"]["values"][1] +
                  eleConfirm["current"]["values"][2] +
                  eleConfirm["current"]["values"][3];
                if (currpin != currconfpin) {
                  setInvalidPin(true);
                  elePin["current"].clear();
                  eleConfirm["current"].clear();
                  elePin["current"].focus();
                } else {
                  setInvalidPin(false);
                  clearInterval(theInterval);
                  submitPin(currpin, currconfpin);
                }
              }
            }, 50);
            setCreateShareholderPin(true);
          }
        }
      }
    };
    fetchData();
  }, [unreadNotificationCount]);

  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
  }, [countdownPeriod]);

  const onClickTopMenu = async () => {
    var x = document.getElementById("mobile-menu");
    console.log(x);
    if (x.className === "mobile-menu") {
      x.className += " responsive";
    } else {
      x.className = "mobile-menu";
    }
  };

  return (
    <>
      <div className="leftside-menu">
        <a className="logo logo-light">
          <span className="logo-lg">
            <img src={require(`../../assets/img/logo.png`)} alt="logo" />
          </span>
          <span className="logo-sm">
            <img src={require(`../../assets/img/logo.png`)} alt="small logo" />
          </span>
        </a>

        <div className="h-100" id="leftside-menu-container" data-simplebar>
          <ul className="side-nav">
            {vipSideMenu &&
              vipSideMenu.length &&
              vipSideMenu
                .filter((x) =>
                  _userData.isShareholder ? x.page !== "VIP_EVENT" : x
                )
                .map((item, index) => {
                  return (
                    <li key={index} className="side-nav-item">
                      <a
                        className="side-nav-link"
                        onClick={() => {
                          _history.push(item.link);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <img
													src={
														props.activePage === item.text
															? item.activeImage
															: item.image
													}
													className="sidemenu-img img-responsive"
													alt={item.title}
												/> */}
                        <span>
                          {" "}
                          {t(item.title)}
                          {item.title === "NOTIFICATION" &&
                            unreadNotificationCount > 0 &&
                            !props.isRead && (
                              <span className="circle-orange-notification">
                                {unreadNotificationCount}
                              </span>
                            )}{" "}
                        </span>
                      </a>
                    </li>
                  );
                })}

            <li className="side-nav-item">
              <a
                className="side-nav-link"
                onClick={() => {
                  _dispatch(performLogout());
                  _history.replace(WebUrl._URL_MAIN);
                  localStorage.setItem(
                    "isSettingsDropdownVisibleSideBar",
                    false
                  );
                  localStorage.removeItem("isReadAllNotification");
                  localStorage.removeItem("unreadNotificationCount");
                  setShowPinEntry(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <span> {t("LOGOUT")}</span>
              </a>
            </li>
          </ul>

          <div className="clearfix"></div>
        </div>
      </div>
      <div className="navbar-custom">
        <div className="topbar container-fluid">
          <i
            class="fa fa-bars"
            style={{ fontSize: 20 }}
            onClick={onClickTopMenu}
          ></i>
          <a className="logo logo-light">
            <span className="logo-lg">
              <img src={require(`../../assets/img/logo.png`)} alt="logo" />
            </span>
          </a>
          <span></span>
        </div>
        <div className="mobile-menu" id="mobile-menu">
          <ul className="side-nav">
            {vipSideMenu &&
              vipSideMenu.length &&
              vipSideMenu
                .filter((x) =>
                  _userData.isShareholder ? x.page !== "VIP_EVENT" : x
                )
                .map((item, index) => {
                  return (
                    <li key={index} className="side-nav-item text-center">
                      <a
                        className="side-nav-link"
                        onClick={() => {
                          _history.push(item.link);
                          onClickTopMenu();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          {" "}
                          {t(item.title)}
                          {item.title === "NOTIFICATION" &&
                            unreadNotificationCount > 0 &&
                            !props.isRead && (
                              <span className="circle-orange-notification">
                                {unreadNotificationCount}
                              </span>
                            )}{" "}
                        </span>
                      </a>
                    </li>
                  );
                })}

            <li className="side-nav-item">
              <a
                className="side-nav-link text-center"
                onClick={() => {
                  _dispatch(performLogout());
                  _history.replace(WebUrl._URL_MAIN);
                  onClickTopMenu();
                  localStorage.setItem(
                    "isSettingsDropdownVisibleSideBar",
                    false
                  );
                  localStorage.removeItem("isReadAllNotification");
                  localStorage.removeItem("unreadNotificationCount");
                  setShowPinEntry(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <span> {t("LOGOUT")}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {showPinEntry &&
        isLoggedIn &&
        localStorage.getItem("IsVerified") === null && (
          <div>
            <input
              type="password"
              style={{
                height: 0,
                width: 0,
                position: "absolute",
                zIndex: -1,
              }}
            />
            <div className="pin-wrapper"></div>
            <div className="pin-number">
              <strong className="reload-title text-gold mb-3 d-block font-semi font-20">
                {t("ENTER_YOUR_PIN")}
              </strong>
              <div
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    onVerifyPinKeyPress("{bksp}");
                  }
                }}
              >
                <PinInput
                  style={{ marginBottom: "35px" }}
                  ref={eleFinalPin}
                  length={4}
                  initialValue=""
                  secret={true}
                  focus={showPinEntry}
                  autoSelect={false}
                  // onChange={(value, index) => {
                  //   setPinNumberFinalFocused(index);
                  //   console.log(index);
                  // }}
                  onChange={(value) => {
                    onVerifyPinKeyPress(value);
                  }}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    border: "0",
                    margin: "0px 10px",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "transparent",
                    borderBottom: "2px solid #002e6c",
                  }}
                  inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                  regexCriteria={/^[ 0-9_@./#&+-]*$/}
                />
              </div>

              {/*<strong className="text-brand display-value" style={{ fontSize: "10px", letterSpacing: "0.33px" }} onClick={() => _history.push(WebUrl._URL_FORGET_PIN)}>Forgot PIN?</strong>*/}

              <strong
                className="text-brand font-bold"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setForgetPin(true);
                  sendPinCode();
                }}
              >
                {t("RESET_PIN")}
              </strong>
              {smsSent && (
                <p
                  className="text-brand"
                  style={{ textAlign: "center", marginTop: "5px" }}
                >
                  {t("PIN_SENT")} {t("RESEND_IN")} {countdownPeriod}{" "}
                  {t("SECOND")}
                </p>
              )}
              {showHpErrorMessage && (
                <p
                  className="text-brand text-red"
                  style={{ textAlign: "center", marginTop: "5px" }}
                >
                  {t(hpErrorMessage)}
                </p>
              )}
              {showAlert && (
                <div
                  className="content content-full-width"
                  style={{
                    position: "fixed",
                    top: "10px",
                    left: "0",
                    width: "100%",
                    zIndex: 9999,
                  }}
                >
                  <div className="container">
                    <Alert color="danger">{t("WAIT_TO_RESEND")}</Alert>
                  </div>
                </div>
              )}
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t(errorMessage)}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
            </div>
          </div>
        )}

      <Modal
        fade={false}
        contentClassName="modal-brand modal-bottom modal-numpad"
        isOpen={isLoggedIn && showCreateShareholderPin}
        centered
      >
        <ModalBody>
          <button
            type="button"
            style={{
              alignSelf: "flex-end",
              backgroundColor: "transparent",
              border: "none",
              marginRight: "15px",
              fontSize: "2rem",
              color: "#002e6c",
              marginTop: "-15px",
              float: "right",
              fontWeight: "bold",
            }}
            onClick={() => {
              clearInterval(theInterval);
              setShowCreateShareholderPin(false);
            }}
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
          <form>
            <div
              className="display-wrapper"
              style={{ textAlign: "center" }}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  onCreatePinKeyPress("{bksp}");
                }
              }}
            >
              <strong
                className="reload-title text-yellow mb-3 d-block"
                style={{ fontSize: "20px" }}
              >
                {t("NO_SHAREHOLDER_PIN_FOUND")}
              </strong>
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                }}
              >
                {t("ENTER_YOUR_PIN")}
              </strong>
              <PinInput
                ref={elePin}
                length={4}
                initialValue=""
                secret={true}
                focus={true}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberFocused(index);
                  onCreatePinKeyPress(value);
                }}
                onComplete={(value) => adjustFocus()}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <strong
                className="reload-title mb-3 d-block"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#002e6c",
                  marginTop: "30px",
                }}
              >
                {t("CONFIRM_YOUR_PIN")}
              </strong>
              <PinInput
                ref={eleConfirm}
                length={4}
                initialValue=""
                secret={true}
                focus={false}
                autoSelect={false}
                // disabled={showCreateShareholderPin}
                type="numeric"
                onChange={(value, index) => {
                  setPinNumberConfirmFocused(index);
                  onCreatePinKeyPress(value);
                }}
                inputMode="numeric"
                inputStyle={{
                  border: "0",
                  margin: "0px 10px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #002e6c",
                }}
                inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                regexCriteria={/^[ 0-9_@./#&+-]*$/}
              />
              <div
                className="invalid-feedback"
                style={{ fontSize: "12px", marginTop: "1rem" }}
              >
                {invalidPin && t("PIN_DOES_NOT_MATCH")}
                {!invalidPin && <span>&nbsp;</span>}
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SideMenu;
