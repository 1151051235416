import React, { useState, useEffect, useCallback, useRef } from "react";
import { debounce, filter, map, find, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Wheel } from "react-custom-roulette";
import {
	performLogin,
	performLogout,
	updateUserData,
} from "../../redux/AuthAction.js";
import {
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
	Carousel as ReactCarousel,
	CarouselItem,
	CarouselIndicators,
} from "reactstrap";
import PopupGenie from "./../../assets/img/homepage_popup_genie.png";
import PopupJClub from "./../../assets/img/homepage_popup_Jclub_X_Pragmatic.png";
import PopupAdvantPlay from "./../../assets/img/homepage_popup_advantplay.png";
import Popupi81 from "./../../assets/img/homepage_popup_20230619.jpg";
import CNYGenie from "./../../assets/img/CNY_Sign_Up@2x.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SecureLS from "secure-ls";
import ApiEngine from "./../../util/ApiEngine.js";
import {
	LanguageOption,
	ApiUrl,
	ApiKey,
	SessionKey,
	TransactionType,
	BankingChannel,
	PanelType,
	WebUrl,
	_MAIN_WALLET_ID,
	AlertTypes,
	DisplayDevice,
	Language,
} from "./../../util/Constant.js";
import {
	setActiveTab,
	setBusy,
	setIdle,
	showMessage,
	showResponseMessage,
	setIsShareholder,
} from "../../redux/AppAction.js";
import {
	createFormBody,
	createMultiPartFormBody,
	numberWithCurrencyFormat,
	stringIsNullOrEmpty,
} from "./../../util/Util.js";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { saveLanguageId } from "../../redux/LanguageAction.js";
import Input from "../../components/custom/Input";
import CnyHeader from "./../../assets/img/CNY_Header.png";
import SpinWheel from "../../pages/home/SpinWheel";

const Header = (props) => {
	const { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	const _history = useHistory();

	const [popupBannerData, setPopupBannerData] = useState([]);
	const [showPopupBanner, setShowPopupBanner] = useState(false);
	const [animating, setAnimating] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const _MIN_PASSWORD_LENGTH = 6;
	const [isSettingsDropdownVisible, setIsSettingsDropdownVisible] =
		useState(false);
	const [isGameDropdownVisible, setIsGameDropdownVisible] = useState(false);
	const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showRegisterPopup, setShowRegisterPopup] = useState(false);
	const [loginFailCount, setLoginFailCount] = useState(0);
	const [loginAttemptUsername, setLoginAttemptUsername] = useState("");
	const [displayDateTime, setDisplayDateTime] = useState(
		moment().format("YYYY-MM-DD HH:mm:ss Z")
	);
	const [keptUsername, setKeptUsername] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [tempPhoneNum, setTempPhoneNum] = useState("");
	const [keptPassword, setKeptPassword] = useState("");
	const [referralCode, setReferralCode] = useState("");
	const [showCodeError, setShowCodeError] = useState(false);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [searchData, setSearchData] = useState([]);
	const [loadingSearchData, setLoadingSearchData] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState();
	const [isForgetPassword, setIsForgetPassword] = useState(false);
	const [isLogin, setIsLogin] = useState(true);
	const [verifyPhoneTAC, setVerifyPhoneTAC] = useState(false);
	const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
	const verifyState = {
		button: "1",
	};

	const selectedGameProvider = useSelector(
		(state) => state.gameState.gameProviders
	);
	const [isUpdatePassword, setIsUpdatePassword] = useState(false);
	const [username, setUsername] = useState("");
	const _TAC_VALID_TIMER = 300000;
	const _SECONDS_IN_MS = 1000;
	const [countdownTAC, setCountdownTAC] = useState(false);
	const [freeSpinCount, setFreeSpinCount] = useState(0);
	const [walletInterval, setWalletInterval] = useState();
	const [clockTimeout, setClockTimeout] = useState();
	const [showFreeSpin, setShowFreeSpin] = useState(false);
	const [freeSpinDone, setFreeSpinDone] = useState(false);
	const {
		register: registerLogin,
		handleSubmit: handleSubmitLogin,
		setValue: setValueLogin,
		getValues: getValuesLogin,
		watch: watchLogin,
		errors: errorsLogin,
	} = useForm();
	const {
		register: forgotPasswordRegister,
		handleSubmit: forgotPasswordHandleSubmit,
		errors: forgotPasswordErrors,
		watch: forgotPasswordWatch,
		setValue: forgotPasswordSetValue,
		unregister: forgotPasswordUnregister,
	} = useForm();
	const {
		register: tacVerifyRegister,
		handleSubmit: tacVerifyHandleSubmit,
		errors: tacVerifyErrors,
		watch: tacVerifyWatch,
		setValue: tacVerifySetValue,
		unregister: tacVerifyUnregister,
	} = useForm();
	const {
		register: ResetPasswordRegister,
		handleSubmit: ResetPasswordHandleSubmit,
		errors: ResetPasswordErrors,
		watch: ResetPasswordWatch,
		setValue: ResetPasswordSetValue,
		unregister: ResetPasswordUnregister,
		watch,
	} = useForm();
	const { handleSubmit: PasswordUpdatedHandleSubmit } = useForm();
	var { isLoggedIn } = useSelector((state) => state.authState);
	let isShareholder = useSelector((state) => state.appState.isShareholder);

	var _userData = useSelector((state) => state.authState.userData);
	var isSettingsDropdownVisibleSideBar = JSON.parse(
		localStorage.getItem("isSettingsDropdownVisibleSideBar")
	);

	const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
	const gameCategories = useSelector((state) => {
		return filter(state.gameState.gameCategories, (item) => {
			return (
				item.id > 0 &&
				item.originalTitle != "Virtual-Sports" &&
				item.originalTitle != "V.Sports"
			);
		});
	});

	const selectedLanguageId = useSelector(
		(state) => state.languageState.languageId
	);
	const search = window.location.search;
	const params = new URLSearchParams(search);

	const [languageCode, setLanguageCode] = useState("");
	const [downloadURL, setDownloadURL] = useState("");
	const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

	// useEffect(() => {
	//   console.log(selectedLanguageId);
	// }, [selectedLanguageId]);

	var ls = new SecureLS();
	const [notificationUnread, setNotificationUnread] = useState(false);
	var isReadAllNotification = JSON.parse(
		localStorage.getItem("isReadAllNotification")
	);
	var unReadNotification = localStorage.getItem("unreadNotificationCount");
	const activeTab = useSelector((state) => state.appState.activeTab);
	const [selectedLanguageCode, setSelectedLanguageCode] = useState("en");

	const countryList = [
		{
			name: "Malaysia",
			iso: "MYR",
			icon: require("../../assets/img/v3/my.svg"),
			icon_circle: require("../../assets/img/v3/my-circle.svg"),
			url: window.location.origin, //(window.location.href).includes("staging") ? 'https://my-staging-d.i8global.com' : (window.location.href).includes("preproduction") ? 'https://my-preproduction-d.i8global.com/' : 'https://my-preproduction-d.i8global.com/',
			child_languages: [
				{
					name: "English",
					languageCode: Language._ENGLISH,
					languageId: 1,
				},
				{
					name: "中文",
					languageCode: Language._CHINESE,
					languageId: 2,
				},
				{
					name: "Malay",
					languageCode: Language._BAHASA_MELAYU,
					languageId: 3,
				},
				{
					name: "ไทย",
					languageCode: Language._THAI,
					languageId: 5,
				},
				{
					name: "Tiếng Việt",
					languageCode: Language._VIETNAMESE,
					languageId: 6,
				},
				{
					name: "Indo",
					languageCode: Language._INDON,
					languageId: 7,
				},
			],
		},
		{
			name: "Thailand",
			iso: "TH",
			icon: require("../../assets/img/v3/th.svg"),
			icon_circle: require("../../assets/img/v3/th-circle.svg"),
			url: window.location.href.includes("staging")
				? "https://th-staging-d.i8global.com"
				: window.location.href.includes("preproduction")
				? "https://th-preproduction-d.huat8th.com/"
				: "https://i8th.live/",
			child_languages: [
				{
					name: "ไทย",
					languageCode: Language._THAI,
					languageId: 5,
				},
				{
					name: "English",
					languageCode: Language._ENGLISH,
					languageId: 1,
				},
				{
					name: "中文",
					languageCode: Language._CHINESE,
					languageId: 2,
				},
				{
					name: "Malay",
					languageCode: Language._BAHASA_MELAYU,
					languageId: 3,
				},
				{
					name: "Tiếng Việt",
					languageCode: Language._VIETNAMESE,
					languageId: 6,
				},
				{
					name: "Indo",
					languageCode: Language._INDON,
					languageId: 7,
				},
			],
		},
		//{
		//  name: "Indonesia",
		//  iso: "ID",
		//  icon: require("../assets/img/v3/id.png"),
		//  icon_circle: require("../assets/img/v3/id-circle.png"),
		//  url: (window.location.href).includes("staging") ? 'https://id-staging.huat8id.com/' : (window.location.href).includes("preproduction") ? 'https://id-preproduction.huat8id.com/' : 'https://i8id.live/',
		//  child_languages: [
		//    {
		//      name: "Indo",
		//      languageCode: Language._INDON,
		//      languageId: 7
		//    },
		//    {
		//      name: "English",
		//      languageCode: Language._ENGLISH,
		//      languageId: 1
		//    },
		//    //{
		//    //  name: "中文",
		//    //  languageCode: Language._CHINESE,
		//    //  languageId: 2
		//    //},
		//    //{
		//    //  name: "Malay",
		//    //  languageCode: Language._BAHASA_MELAYU,
		//    //  languageId: 3
		//    //},
		//    //{
		//    //  name: "ไทย",
		//    //  languageCode: Language._THAI,
		//    //  languageId: 5
		//    //},
		//    //{
		//    //  name: "Tiếng Việt",
		//    //  languageCode: Language._VIETNAMESE,
		//    //  languageId: 6
		//    //},
		//  ],
		//},
		//{
		//  name: "Australia",
		//  iso: "AU",
		//  icon: require("../../assets/img/v3/au.png"),
		//  icon_circle: require("../../assets/img/v3/my-circle.png"),
		//  child_languages: [
		//    {
		//      name: "English",
		//    },
		//    {
		//      name: "ไทย",
		//    },
		//  ],
		//},
	];

	const settingsList = [
		{
			image: (
				unReadNotification === null
					? notificationUnread
					: unReadNotification > 0 && !isReadAllNotification
			)
				? require("../../assets/img/assets/nav/nav1.1.png") //with red dot
				: require("../../assets/img/icon/notification-icon.svg"), //without red dot
			text: t("NOTIFICATION"),
			redirectUrl: WebUrl._URL_NOTIFICATION,
		},
		{
			image: require("../../assets/img/assets/nav/nav2.svg"),
			text: t("VIDEO_TUTORIAL"),
			redirectUrl: WebUrl._URL_VIDEO_TUTORIAL,
		},
		{
			image: require("../../assets/img/assets/nav/nav3.svg"),
			text: t("KPI"),
			redirectUrl: WebUrl._URL_KPI,
		},
		{
			image: require("../../assets/img/assets/nav/nav4.svg"),
			text: t("RELOAD"),
			redirectUrl: WebUrl._URL_DEPOSIT,
		},
		{
			image: require("../../assets/img/assets/nav/nav5.svg"),
			text: t("TRANSFER"),
			redirectUrl: WebUrl._URL_TRANSFER,
		},
		{
			image: require("../../assets/img/assets/nav/nav8.svg"),
			text: t("LIVE_CHAT"),
			redirectUrl: WebUrl._URL_CONTACT_US,
		},
		{
			image: require("../../assets/img/assets/nav/nav11.svg"),
			text: t("MORE"),
			redirectUrl: WebUrl._URL_SETTINGS,
		},
	];

	const headerDropdown = useRef(null);
	const headerSearchBar = useRef(null);
	const headerSearchResult = useRef(null);

	useEffect(() => {
		// const value = LanguageOption.find((item) => localStorage.getItem(SessionKey._LANGUAGE) === item.code );
		if (params.get("languageCode") != null) {
			onChangeLanguage(params.get("languageCode"));
			localStorage.setItem(SessionKey._LANGUAGE, params.get("languageCode"));
		} else if (
			localStorage.getItem(SessionKey._LANGUAGE) &&
			localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
		) {
			if (
				LanguageOption.find(
					(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
				) != undefined
			) {
				setSelectedLanguage(
					LanguageOption.find(
						(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
					).prefix
				);
				setSelectedLanguageCode(
					LanguageOption.find(
						(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
					).code
				);

				let downloadURL =
					window.location.href.includes("staging") ||
					window.location.href.includes("localhost")
						? "http://download.i8global.com/?lang=" +
						  localStorage.getItem(SessionKey._LANGUAGE) +
						  "&country=my"
						: "http://download.i8king.com/?lang=" +
						  localStorage.getItem(SessionKey._LANGUAGE) +
						  "&country=my";
				setDownloadURL(downloadURL);
				i18n.changeLanguage(localStorage.getItem(SessionKey._LANGUAGE));
			} else {
				i18n.changeLanguage("en");
				setSelectedLanguage("EN");
				setSelectedLanguageCode("EN");
				let downloadURL =
					window.location.href.includes("staging") ||
					window.location.href.includes("localhost")
						? "http://download.i8global.com/?lang=" +
						  localStorage.getItem(SessionKey._LANGUAGE) +
						  "&country=my"
						: "http://download.i8king.com/?lang=" +
						  localStorage.getItem(SessionKey._LANGUAGE) +
						  "&country=my";
				setDownloadURL(downloadURL);
				localStorage.setItem(SessionKey._LANGUAGE, "en");
			}
		} else {
			i18n.changeLanguage("en");
			setSelectedLanguage("EN");
			setSelectedLanguageCode("EN");
			let downloadURL =
				window.location.href.includes("staging") ||
				window.location.href.includes("localhost")
					? "http://download.i8global.com/?lang=" +
					  localStorage.getItem(SessionKey._LANGUAGE) +
					  "&country=my"
					: "http://download.i8king.com/?lang=" +
					  localStorage.getItem(SessionKey._LANGUAGE) +
					  "&country=my";
			setDownloadURL(downloadURL);
			localStorage.setItem(SessionKey._LANGUAGE, "en");
		}
	}, [
		localStorage.getItem(SessionKey._LANGUAGE) &&
		localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
			? localStorage.getItem(SessionKey._LANGUAGE)
			: "en",
	]);

	const checkIsShareholder = async () => {
		if (isLoggedIn) {
			var responseJson = await ApiEngine.get(ApiUrl._API_CHECK_IS_SHAREHOLDER);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				let data = responseJson[ApiKey._API_DATA_KEY];
				//if (data !== isShareholder) {
				//    _dispatch(setIsShareholder(data));
				//}
			}
		}
	};

	const headerTab = (tab) => {
		if (activeTab !== tab) {
			_dispatch(setActiveTab(tab));
		}
	};

	useEffect(() => {
		_dispatch(setActiveTab(false));
		if (window.location.pathname == "/") {
			_dispatch(setActiveTab(1));
		} else if (window.location.pathname == "/4d-result") {
			_dispatch(setActiveTab(3));
		} else if (window.location.pathname == "/download") {
			_dispatch(setActiveTab(4));
		}
		//checkIsShareholder();
	}, [window.location.pathname]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		//if (isLoggedIn) {
		//  if (!window.ReactNativeWebView) {
		//    if (userData["selectedTheme"] === 0) {
		//      _history.push(WebUrl._URL_MAIN);
		//    } else if (userData["selectedTheme"] === 1) {
		//      _history.push(WebUrl._URL_DASHBOARD_V2);
		//    }
		//  }
		//}

		if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
			setKeptUsername(ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
			setKeptPassword(ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
			//  setValueLogin("loginUsername", ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
			//  setValueLogin("loginPassword", ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
		}
	}, []);

	useEffect(() => {
		let tempTimeout = setTimeout(() => {
			setDisplayDateTime(moment().format("YYYY-MM-DD HH:mm:ss Z"));
		}, _SECONDS_IN_MS);
		setClockTimeout(tempTimeout);
		if (showFreeSpin) {
			if (!freeSpinDone) {
				clearTimeout(tempTimeout);
			}
		}
	}, [displayDateTime]);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (
				isGameDropdownVisible &&
				headerDropdown.current &&
				!headerDropdown.current.contains(e.target)
			) {
				setIsGameDropdownVisible(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutside);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [isGameDropdownVisible]);

	useEffect(() => {
		const checkIfClickedOutsideSearch = (e) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (
				isSearchInputVisible &&
				headerSearchBar.current &&
				!headerSearchBar.current.contains(e.target)
				// ||
				// (headerSearchResult.current &&
				//   !headerSearchResult.current.contains(e.target))
			) {
				setIsSearchInputVisible(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutsideSearch);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutsideSearch);
		};
	}, [isSearchInputVisible]);

	useEffect(() => {
		const fetchData = async () => {
			if (isLoggedIn) {
				var responseJson = await ApiEngine.get(
					ApiUrl._API_GET_NOTIFICATION_LIST +
						"?languageCode=" +
						(localStorage.getItem(SessionKey._LANGUAGE) &&
						localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
							? localStorage.getItem(SessionKey._LANGUAGE)
							: "en")
					// localStorage.getItem(SessionKey._LANGUAGE)
				);
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					var allData =
						responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY];

					allData.forEach(function (obj) {
						if (obj.isReadFlag == false) {
							setNotificationUnread(true);
							return true;
						}
						setNotificationUnread(false);
						return false;
					});
				} else {
					_dispatch(
						showResponseMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							responseJson[ApiKey._API_MESSAGE_KEY]
						)
					);
				}
			}
			setCurrentWalletBalance(_userData["walletBalance"]);
		};
		fetchData();
		if (isLoggedIn) {
			onChangeLanguage(
				localStorage.getItem(SessionKey._LANGUAGE) &&
					localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
					? localStorage.getItem(SessionKey._LANGUAGE)
					: "en"
			);
		}
	}, [isLoggedIn]);

	//useEffect(() => {
	//    if (selectedLanguageId) {
	//        const selectedItem = find(
	//            LanguageOption,
	//            (item) => item.code === selectedLanguageId
	//        );
	//        setSelectedLanguage(selectedItem.prefix);
	//    }
	//}, [selectedLanguageId]);

	useEffect(() => {
		setSearchKeyword("");
	}, []);

	const submitLoginForm = async (data, e) => {
		_dispatch(setBusy());

		localStorage.setItem(SessionKey._ISSHOWMINIGAMETRIGGER, true);

		setShowLoginPopup(false);
		setLoginAttemptUsername(data.username);
		if (data.isKeepedPassword) {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: data.loginUsername,
				gR1IPGBN01dS: data.loginPassword,
				PYTLczcty9uH: data.isKeepedPassword,
			});
		} else {
			ls.set("gR97lPRIn797", {
				Do3SwI4cuvqR: "fake",
				gR1IPGBN01dS: "null",
				PYTLczcty9uH: false,
			});
		}
		await _dispatch(
			performLogin(data.loginUsername, data.loginPassword, data.isKeepedLogin)
		);
		let params = {
			username: data.loginUsername,
		};
		var loginFailCountJson = await ApiEngine.post(
			ApiUrl._API_GET_LOGIN_FAILED_COUNT,
			createFormBody(params)
		);
		if (loginFailCountJson[ApiKey._API_SUCCESS_KEY]) {
			let data = loginFailCountJson[ApiKey._API_DATA_KEY];
			setLoginFailCount(data);
		}
		_dispatch(setIdle());
	};

	const onChangeLanguage = async (selectedLanguage) => {
		_dispatch(setBusy());
		setSelectedLanguage(
			LanguageOption.find((x) => x.code === selectedLanguage).prefix
		);
		i18n.changeLanguage(selectedLanguage);
		if (!isLoggedIn) {
			_dispatch(saveLanguageId(selectedLanguage, true));
		} else {
			_dispatch(saveLanguageId(selectedLanguage));
			let data = {};
			// data["id"] = _userData.userId;
			// data["selectedTheme"] = _userData.selectedTheme;
			data["languageSetting"] = selectedLanguage;
			// data["isMemberOperate"] = true;

			// Silently update user's language settings
			await ApiEngine.post(
				// ApiUrl._API_CREATE_OR_UPDATE_MEMBER,
				ApiUrl._API_UPDATE_MEMBER_LANGUAGE,
				createMultiPartFormBody(data)
			);

			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "language",
						language: selectedLanguage,
					})
				);
			}

			ManageBanner();
		}
		_dispatch(setIdle());
	};

	const handleReferralCode = (code) => {
		setReferralCode(code);
	};

	async function checkIfReferralExist(code) {
		let referralCode = code;
		if (code.includes(" +")) {
			referralCode = code.substring(0, code.indexOf(" +"));
		}
		let apiUrl =
			ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
		let responseJson = await ApiEngine.get(apiUrl);
		if (
			!responseJson[ApiKey._API_SUCCESS_KEY] ||
			responseJson[ApiKey._API_DATA_KEY] == null ||
			stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])
		) {
			if (
				responseJson[ApiKey._API_DATA_KEY] == null ||
				stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY])
			) {
				_dispatch(showResponseMessage(false, t("INVALID_DATA_ERROR")));
			} else {
				_dispatch(
					showResponseMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			}
		} else {
			if (responseJson[ApiKey._API_DATA_KEY] !== code) {
				//_dispatch(showMessage({
				//    type: AlertTypes._INFO,
				//    content: t("REFERRAL_CODE_INVALID_USING_COMPANY_CODE")
				//}));
				code = responseJson[ApiKey._API_DATA_KEY];
			}
			setShowRegisterPopup(false);
			_history.push(WebUrl._URL_REGISTER + "?code=" + code);
		}
	}

	function handleLoginBtn() {
		if (ls.get("gR97lPRIn797")["PYTLczcty9uH"]) {
			setKeptUsername(ls.get("gR97lPRIn797")["Do3SwI4cuvqR"]);
			setKeptPassword(ls.get("gR97lPRIn797")["gR1IPGBN01dS"]);
			//setValueLogin("loginUsername", ls.get("gR97lPRIn797")["Do3SwI4cuvqR"].toString());
			//setValueLogin("loginPassword", ls.get("gR97lPRIn797")["gR1IPGBN01dS"].toString());
		}
		setShowLoginPopup(true);
	}

	async function submitForgotPasswordForm(data) {
		_dispatch(setBusy());
		let formData = {
			language: i18n.language,
			username: data.username,
		};
		let formBody = createMultiPartFormBody(formData);
		var responseJson = await ApiEngine.post(
			ApiUrl._API_SEND_RESET_PASSWORD_SMS,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setUsername(data.username);
			setPhoneNumber(responseJson.data);
			setTempPhoneNum(
				responseJson.data.replace(
					responseJson.data.substr(3, responseJson.data.length - 7),
					responseJson.data
						.substr(1, responseJson.data.length - 4)
						.replace(/./g, "*")
				)
			);
			setVerifyPhoneTAC(true);
			setIsForgetPassword(false);
			setTimeout(() => {
				setCountdownTAC(true);
			}, 120000);
			//resetForm();
		} else {
			_dispatch(
				showResponseMessage(false, responseJson[ApiKey._API_MESSAGE_KEY])
			);
		}
		_dispatch(setIdle());
	}

	async function submitVerifyTAC(data) {
		_dispatch(setBusy());
		if (verifyState.button == 1) {
			let formData = {
				taccode: data.taccode,
				username: username,
			};
			let formBody = createMultiPartFormBody(formData);
			let TACjson = await ApiEngine.post(
				ApiUrl._API_VERIFY_RESET_PASSWORD_SMS,
				formBody
			);
			if (TACjson[ApiKey._API_SUCCESS_KEY]) {
				setVerifyPhoneTAC(false);
				setIsUpdatePassword(true);
				//resetForm();
			}
		} else if (verifyState.button == "2") {
			let formData = {
				language: i18n.language,
				username: username,
			};
			let formBody = createMultiPartFormBody(formData);
			//data["phoneNumber"] = formattedPhoneNumber;
			data["language"] = i18n.language;
			var responseJson = await ApiEngine.post(
				ApiUrl._API_SEND_RESET_PASSWORD_SMS,
				formBody
			);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setCountdownTAC(false);
				setUsername(data.username);
				setPhoneNumber(responseJson.data);
				setTempPhoneNum(
					responseJson.data.replace(
						responseJson.data.substr(3, responseJson.data.length - 7),
						responseJson.data
							.substr(1, responseJson.data.length - 4)
							.replace(/./g, "*")
					)
				);
				setVerifyPhoneTAC(true);
				setIsForgetPassword(false);
				setTimeout(() => {
					setCountdownTAC(true);
				}, 120000);
				//resetForm();
			}
		}

		_dispatch(setIdle());
	}

	async function submitNewPassword(data) {
		_dispatch(setBusy());
		let formData = {
			newpassword: data.newPassword,
			confpassword: data.confPassword,
			username: username,
		};
		let formBody = createMultiPartFormBody(formData);

		var responseJson = await ApiEngine.post(
			ApiUrl._API_MEMBER_RESET_PASSWORD_BEFORE_LOGIN,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setIsUpdatePassword(false);
			setIsPasswordUpdated(true);
		}
		_dispatch(setIdle());
	}

	async function submitPasswordUpdated(data) {
		setIsUpdatePassword(false);
		setIsPasswordUpdated(false);
		setIsLogin(true);
	}

	const onChangeKeyword = useCallback(
		debounce(async (keyword) => {
			let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_PRODUCT_GAME_LIST +
					"?keyword=" +
					keyword +
					(_userData && !stringIsNullOrEmpty(_userData.userId)
						? "&memberId=" + _userData.userId
						: "")
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setSearchData(responseJson[ApiKey._API_DATA_KEY]);
			}
			setLoadingSearchData(false);
		}, 1000),
		[]
	);

	useEffect(() => {
		if (isLoggedIn) {
			ManageBanner();
			let tempInterval = setInterval(() => tempUpdateUserData(), 30000);
			setWalletInterval(tempInterval);
			return () => clearInterval(tempInterval);
		}
	}, [isLoggedIn]);

	// sets/removes popup banners
	function ManageBanner(isRead) {
		let apiUrl = ApiUrl._API_MANAGE_BANNER_NOTIFICATION;
		if (typeof isRead != "undefined") {
			apiUrl +=
				"?isRead=" +
				isRead +
				"&languageCode=" +
				(localStorage.getItem(SessionKey._LANGUAGE) &&
				localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
					? localStorage.getItem(SessionKey._LANGUAGE)
					: "en");
		}
		fetch(apiUrl, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					let data = responseJson[ApiKey._API_DATA_KEY];
					if (data.filter((x) => x["isDisplay"] === true).length > 0) {
						setShowPopupBanner(true);
					} else {
						setShowPopupBanner(false);
					}

					if (isLoggedIn) {
						fetch(
							ApiUrl._API_GET_USER_BANNER +
								"?page=" +
								window.location.pathname +
								"&isPopup=true&isDesktop=true&languageCode=" +
								(localStorage.getItem(SessionKey._LANGUAGE) &&
								localStorage.getItem(SessionKey._LANGUAGE) != "undefined"
									? localStorage.getItem(SessionKey._LANGUAGE)
									: "en"),
							{
								method: ApiKey._API_GET,
								headers: {
									"Content-Type": ApiKey._API_FORM_URLENCODED,
									"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
								},
							}
						)
							.then((response) => response.json())
							.then((responseJson) => {
								if (responseJson[ApiKey._API_SUCCESS_KEY]) {
									let data = responseJson[ApiKey._API_DATA_KEY].filter(
										(x) =>
											x.displayDevice === DisplayDevice._DESKTOP_POPUP &&
											x.isDisplay === true &&
											x.isRead === false
									);
									setPopupBannerData(data);
								}
							});
					}
				}
			});
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const next = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === popupBannerData.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const previous = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === 0 ? popupBannerData.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	const onClickGameHandler = (data) => {
		const getGameNameUm = selectedGameProvider.find((i) => {
			return i.gameName === data.gameName && i.gameUm == true;
		});

		if (getGameNameUm === undefined) {
			window.open(
				`${process.env.REACT_APP_ENDPOINT_URL || ""}${
					ApiUrl._API_START_GAME
				}?gameId=${data.gameId}&isApp=${data.isApp ? 1 : 0}&device=d`
			);
		} else {
			_dispatch(
				showMessage({
					type: AlertTypes._INFO,
					content: t("GAME_UM"),
					onConfirm: () => {},
				})
			);
		}
	};

	const tempUpdateUserData = async () => {
		let walletResponse = await ApiEngine.get(
			ApiUrl._API_GET_MEMBER_WALLET_BALANCE
		);
		if (walletResponse[ApiKey._API_SUCCESS_KEY]) {
			setCurrentWalletBalance(walletResponse[ApiKey._API_DATA_KEY]);
		}
	};

	const handleCountryOnClick = async (countryItem, selectedLanguage) => {
		if (
			window.location.href.startsWith(countryItem.url) ||
			(window.location.href.includes("localhost") && countryItem.iso == "TH")
		) {
			if (typeof selectedLanguage !== "undefined") {
				onChangeLanguage(selectedLanguage);
			}
		} else {
			let redirectUrl =
				countryItem.url +
				"?languageCode=" +
				(typeof selectedLanguage !== "undefined" ? selectedLanguage : "en");
			window.location.assign(redirectUrl);
		}
		setShowLanguageDropdown(false);
	};

	return (
		<>
			<div className="navbar-custom">
				<div className="topbar container-fluid"></div>
			</div>
		</>
	);
};

export default Header;
