import React from "react";
import { Redirect } from "react-router";
import { WebUrl } from "../util/Constant";

///john ralph
import Home from "../pages/home/Home";
import GameProvider from "../pages/home/GameProvider";
import MainWallet from "../pages/home/MainWallet";
import DepositDesktop from "../pages/reload/Deposit";
import Withdrawal from "../pages/home/Withdrawal";
import TransactionHistory from "../pages/home/TransactionHistory";
import PendingTransaction from "../pages/home/PendingTransaction";
import Transfer from "../pages/home/Transfer";
import Notification from "../pages/home/Notification";
import Downline from "../pages/downline/Downline";
import ProgressCommRate from "../pages/downline/ProgressCommRate";
import EditDownlineDetails from "../pages/downline/EditDownlineDetails";
import DownlineGroupDetail from "../pages/downline/DownlineGroupDetail";
import EditDownlineGroupDetail from "../pages/downline/EditDownlineGroupDetail";
import Kpi from "../pages/home/Kpi";
import Report from "../pages/home/Report";
import GameRecord from "../pages/home/GameRecord";
import WalletStatement from "../pages/home/WalletStatement";
import RolloverStatement from "../pages/home/RolloverStatement";
import Rewards from "../pages/home/Rewards";
import RewardHistory from "../pages/home/RewardHistory";
import RewardsTnC from '../pages/home/RewardsTnC';
/* addition from Jason */
import Register from "../pages/home/Register";
import NotificationDetail from "../pages/home/NotificationDetail";

import BonusReport from "../pages/home/BonusReport";
import InternalTransferReport from "../pages/home/InternalTransferReport";
import Account from "../pages/home/Account";
import Settings from "../pages/home/Settings";
import Terms from "../pages/home/TermsAndCondition";
import About from "../pages/home/About";
import VipEvent from "../pages/vip-event/VipEvent";
import Summary from "../pages/vip-event/Summary";
import Shareholders from "../pages/vip-event/Shareholders";
import Claim from "../pages/vip-event/Claim";
import SummaryDownline from "../pages/vip-event/SummaryDownline";
import HelpCentre from "../pages/setting/HelpCentre";
import HelpCentreCategory from "../pages/setting/HelpCentreCategory";
import HelpCentreSubCategory from "../pages/setting/HelpCentreSubCategory";
import HelpCentreFaq from "../pages/setting/HelpCentreFaq";

import Referral from "../pages/home/Referral";
import ReferralCode from "../pages/home/ReferralCode";
import ReferralEdit from "../pages/home/ReferralEdit";
import DownloadApp from "../pages/home/DownloadApp";
import VideoTutorial from "../pages/home/VideoTutorial";
import Result from "../pages/result/4DResult";
import ContactUs from "../pages/contact-us/ContactUs";
import GameList from "pages/home/GameList";
import Download from "pages/download/";
import ClaimHistory from "pages/home/ClaimHistory";
import Esport from "pages/game/Esport";
import Rng from "pages/game/Rng";
import Lottery from "pages/game/Lottery";
import Poker from "pages/game/Poker";
import Sport from "pages/game/Sports";
import Casino from "pages/game/Casino";
import RichwinAdmin from "pages/home/RichwinAdmin";
import CommissionHistory from "../pages/home/CommissionHistory";
import UnclaimCommissionHistory from "../pages/member/UnclaimCommissionHistory";
import LiveChatPage from './../pages/contact-us/LiveChat';
import WalletTransferHistory from 'pages/home/WalletTransferHistory';
import DepositRecord from 'pages/transaction/DepositRecord';
import WithdrawalRecord from 'pages/transaction/WithdrawalRecord';
import Connect from "../pages/game/Connect";
import LoyaltyRedemption from "../pages/home/LoyaltyRedemption";
import LoyaltyRedemptionHistory from "../pages/home/LoyaltyRedemptionHistory";
import LoyaltyRedemptionTnC from "../pages/home/LoyaltyRedemptionTnC";
import UnclaimLoyaltyPoints from "../pages/member/UnclaimLoyaltyPoints";
import LoyaltyPointsHistory from "../pages/home/LoyaltyPointsHistory";
import CustomizeWeb from "../pages/vip-event/CustomizeWeb";


import LoginSH from "../pages/LoginSH"
/// <summary>
/// Author :
/// </summary>
export const _LOGIN_ROUTE = {
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
  component: () => <Redirect to={WebUrl._URL_MAIN} />,
};

export const _ROUTES = [
    // {
    //   path: WebUrl._URL_MAIN,
    //   exact: true,
    //   authRequired: true,
    //   component: () => <Redirect to={WebUrl._URL_HOME} />,
    // },
    {
        path: "/",
        exact: true,
        authRequired: false,
        component: () => <Redirect to={WebUrl._URL_MAIN} />,
    },
    {
        path: "/home",
        exact: true,
        authRequired: false,
        component: () => <Redirect to={WebUrl._URL_MAIN} />,
    },
    {
        path: "/login",
        exact: true,
        authRequired: false,
        component: () => <Redirect to={WebUrl._URL_MAIN} />,
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        title: "Home",
        authRequired: false,
      component: () => <LoginSH />,
  },
  // {
  //   path: WebUrl._URL_VIP,
  //   exact: true,
  //   title: "VIP",
  //   authRequired: true,
  //   component: () => <VipEvent />,
  // },
  {
    path: WebUrl._URL_SUMMARY,
    exact: true,
    title: "Summary",
    authRequired: true,
    component: () => <Summary />,
  },
  {
    path: WebUrl._URL_SHAREHOLDERS,
    exact: true,
    title: "Shareholders",
    authRequired: true,
    component: () => <Shareholders />,
  },
  {
    path: WebUrl._URL_CLAIM,
    exact: true,
    title: "Claim",
    authRequired: true,
    component: () => <Claim />,
  },
  {
    path: WebUrl._URL_SUMMARY_DOWNLINE,
    exact: true,
    title: "Summary Downline",
    authRequired: true,
    component: () => <SummaryDownline />,
  },
  {
    path: WebUrl._URL_CLAIM_HISTORY,
    exact: true,
    title: "Claim History",
    authRequired: true,
    component: () => <ClaimHistory />,
  },
  {
    path: WebUrl._URL_CUSTOMIZE_WEB,
    exact: true,
    title: "Customize",
    authRequired: true,
    component: () => <CustomizeWeb />,
  },
    //{
    //    path: WebUrl._URL_GAME_PROVIDER,
    //    exact: true,
    //    title: "Game Provider",
    //    authRequired: false,
    //    component: () => <GameProvider />,
    //},
    //{
    //    path: WebUrl._URL_MAIN_WALLET,
    //    exact: true,
    //    title: "Main Wallet",
    //    authRequired: true,
    //    component: () => <MainWallet />,
    //},
    //{
    //    path: WebUrl._URL_DEPOSIT,
    //    exact: true,
    //    title: "Deposit",
    //    authRequired: true,
    //    component: () => <DepositDesktop />,
    //},
    //{
    //    path: WebUrl._URL_WITHDRAWAL,
    //    exact: true,
    //    title: "Withdrawal",
    //    authRequired: true,
    //    component: () => <Withdrawal />,
    //},
    //{
    //    path: WebUrl._URL_TRANSACTION_HISTORY,
    //    exact: true,
    //    title: "Transaction-history",
    //    authRequired: true,
    //    component: () => <TransactionHistory />,
    //},
    //{
    //    path: WebUrl._URL_PENDING_TRANSACTION,
    //    exact: true,
    //    title: "Pending Transaction",
    //    authRequired: true,
    //    component: () => <PendingTransaction />,
    //},
    //{
    //    path: WebUrl._URL_TRANSFER,
    //    exact: true,
    //    title: "Transfer",
    //    authRequired: true,
    //    component: () => <Transfer />,
    //},
    //{
    //    path: WebUrl._URL_NOTIFICATION,
    //    exact: true,
    //    title: "Notification",
    //    authRequired: true,
    //    component: () => <Notification />,
    //},
    //{
    //    path: WebUrl._URL_DOWNLINE,
    //    exact: true,
    //    title: "Downline",
    //    authRequired: true,
    //    component: () => <Downline />,
    //},
    //{
    //    path: WebUrl._URL_PROGRESS_COMM_RATE,
    //    exact: true,
    //    title: "Products Commission Rate",
    //    authRequired: true,
    //    component: () => <ProgressCommRate />,
    //},
    //{
    //    path: WebUrl._URL_EDIT_DOWNLINE,
    //    exact: true,
    //    title: "Edit Downline",
    //    authRequired: true,
    //    component: () => <EditDownlineDetails />,
    //},
    //{
    //    path: WebUrl._URL_DOWNLINE_GROUP_DETAIL,
    //    exact: true,
    //    title: "Downline Group Detail",
    //    authRequired: true,
    //    component: () => <DownlineGroupDetail />,
    //},
    //{
    //    path: WebUrl._URL_Edit_Downline_Group_Detail,
    //    exact: true,
    //    title: "Downline Group Detail",
    //    authRequired: true,
    //    component: () => <EditDownlineGroupDetail />,
    //},
    //{
    //    path: WebUrl._URL_KPI,
    //    exact: true,
    //    title: "KPI",
    //    authRequired: true,
    //    component: () => <Kpi />,
    //},
    //{
    //    path: WebUrl._URL_REPORT,
    //    exact: true,
    //    title: "Report",
    //    authRequired: true,
    //    component: () => <Report />,
    //},
    //{
    //    path: WebUrl._URL_GAME_RECORD,
    //    exact: true,
    //    title: "Game Record",
    //    authRequired: true,
    //    component: () => <GameRecord />,
    //},
    //{
    //    path: WebUrl._URL_WALLET_STATEMENT,
    //    exact: true,
    //    title: "Wallet Statement",
    //    authRequired: true,
    //    component: () => <WalletStatement />,
    //},
    //{
    //    path: WebUrl._URL_ROLLOVER_STATEMENT,
    //    exact: true,
    //    title: "Rollover Statement",
    //    authRequired: true,
    //    component: () => <RolloverStatement />,
    //},
    //{
    //    path: WebUrl._URL_REWARDS,
    //    exact: true,
    //    title: "Rewards",
    //    authRequired: true,
    //    component: () => <Rewards />,
    //},
    //{
    //    path: WebUrl._URL_REWARD_HISTORY,
    //    exact: true,
    //    title: "Reward History",
    //    authRequired: true,
    //    component: () => <RewardHistory />,
    //},
    //{
    //    path: WebUrl._URL_REWARDS_TNC,
    //    exact: true,
    //    title: 'Rewards Terms & Conditions',
    //    authRequired: true,
    //    component: () => <RewardsTnC />
    //},
    //{
    //    path: WebUrl._URL_REGISTER,
    //    exact: true,
    //    title: "Register",
    //    authRequired: true,
    //    component: () => <Register />,
    //},
    //{
    //    path: WebUrl._URL_BONUS_REPORT,
    //    exact: true,
    //    title: "Bonus Report",
    //    authRequired: true,
    //    component: () => <BonusReport />,
    //},
    //{
    //    path: WebUrl._URL_INTERNAL_TRANSFER_REPORT,
    //    exact: true,
    //    title: "Internal Transfer Report",
    //    authRequired: true,
    //    component: () => <InternalTransferReport />,
    //},
    //{
    //    path: WebUrl._URL_ACCOUNT,
    //    exact: true,
    //    title: "Account",
    //    authRequired: true,
    //    component: () => <Account />,
    //},
    //{
    //    path: WebUrl._URL_SETTINGS,
    //    exact: true,
    //    title: "Settings",
    //    authRequired: true,
    //    component: () => <Settings />,
    //},
    //{
    //    path: WebUrl._URL_TERMS,
    //    exact: true,
    //    title: "Terms and Conditions",
    //    authRequired: true,
    //    component: () => <Terms />,
    //},
    //{
    //    path: WebUrl._URL_ABOUT,
    //    exact: true,
    //    title: "About",
    //    authRequired: false,
    //    component: () => <About />,
    //},
    //{
    //    path: WebUrl._URL_VIP,
    //    exact: true,
    //    title: "VIP",
    //    authRequired: true,
    //    component: () => <VipEvent />,
    //},
    //{
    //    path: WebUrl._URL_SUMMARY_DOWNLINE,
    //    exact: true,
    //    title: "Summary Downline",
    //    authRequired: true,
    //    component: () => <SummaryDownline />,
    //},
    //{
    //    path: WebUrl._URL_HELP_CENTRE,
    //    exact: true,
    //    title: "Help Centre",
    //    authRequired: false,
    //    component: () => <HelpCentre />,
    //},
    //{
    //    path: WebUrl._URL_HELP_CENTRE_CATEGORY,
    //    exact: true,
    //    title: "Help Centre Category",
    //    authRequired: false,
    //    component: () => <HelpCentreCategory />,
    //},
    //{
    //    path: WebUrl._URL_HELP_CENTRE_SUB_CATEGORY,
    //    exact: true,
    //    title: "Help Centre Sub Category",
    //    authRequired: false,
    //    component: () => <HelpCentreSubCategory />,
    //},
    //{
    //    path: WebUrl._URL_HELP_CENTRE_FAQ,
    //    exact: true,
    //    title: "Help Centre Faq",
    //    authRequired: false,
    //    component: () => <HelpCentreFaq />,
    //},
    //{
    //    path: WebUrl._URL_REFERRAL,
    //    exact: true,
    //    title: "Referral",
    //    authRequired: true,
    //    component: () => <Referral />,
    //},
    //{
    //    path: WebUrl._URL_REFERRAL_CODE,
    //    exact: true,
    //    title: "Referral Code",
    //    authRequired: true,
    //    component: () => <ReferralCode />,
    //},
    //{
    //    path: WebUrl._URL_REFERRAL_EDIT,
    //    exact: true,
    //    title: "Referral Edit",
    //    authRequired: true,
    //    component: () => <ReferralEdit />,
    //},
    //{
    //    path: WebUrl._URL_DOWNLOAD_APP,
    //    exact: true,
    //    title: "Download App",
    //    authRequired: true,
    //    component: () => <DownloadApp />,
    //},
    //{
    //    path: WebUrl._URL_VIDEO_TUTORIAL,
    //    exact: true,
    //    title: "Video Tutorial",
    //    authRequired: true,
    //    component: () => <VideoTutorial />,
    //},
    //{
    //    path: WebUrl._URL_NOTIFICATION_DETAIL,
    //    exact: true,
    //    title: "Notification Detail",
    //    authRequired: true,
    //    component: () => <NotificationDetail />,
    //},
    //{
    //    path: WebUrl._URL_4D_RESULT,
    //    exact: true,
    //    title: "4D Result",
    //    authRequired: false,
    //    component: () => <Result />,
    //},
    //{
    //    path: WebUrl._URL_CONTACT_US,
    //    exact: true,
    //    title: "Contact Us",
    //    authRequired: false,
    //    component: () => <ContactUs />,
    //},
    //{
    //    path: WebUrl._URL_LIVE_CHAT,
    //    exact: true,
    //    title: 'Live Chat',
    //    component: () => <LiveChatPage />
    //},
    //{
    //    path: WebUrl._URL_GAME_LIST,
    //    exact: true,
    //    title: "Game Sort Selection",
    //    authRequired: false,
    //    component: () => <GameList />,
    //},
    //{
    //    path: WebUrl._URL_DOWNLOAD,
    //    exact: true,
    //    title: "Download",
    //    authRequired: true,
    //    component: () => <Download />,
    //},
   
    //{
    //    path: WebUrl._URL_ESPORT,
    //    exact: true,
    //    title: "Esport",
    //    authRequired: true,
    //    component: () => <Esport />,
    //},
    //{
    //    path: WebUrl._URL_RNG,
    //    exact: true,
    //    title: "RNG",
    //    authRequired: true,
    //    component: () => <Rng />,
    //},
    //{
    //    path: WebUrl._URL_CONNECT,
    //    exact: true,
    //    title: "93Connect",
    //    authRequired: true,
    //    component: () => <Connect />,
    //},
    //{
    //    path: WebUrl._URL_LOTTERY,
    //    exact: true,
    //    title: "Lottery",
    //    authRequired: true,
    //    component: () => <Lottery />,
    //},
    //{
    //    path: WebUrl._URL_POKER,
    //    exact: true,
    //    title: "Poker",
    //    authRequired: true,
    //    component: () => <Poker />,
    //},
    //{
    //    path: WebUrl._URL_SPORT,
    //    exact: true,
    //    title: "Sport",
    //    authRequired: true,
    //    component: () => <Sport />,
    //},
    //{
    //    path: WebUrl._URL_CASINO,
    //    exact: true,
    //    title: "Casino",
    //    authRequired: true,
    //    component: () => <Casino />,
    //},
    //{
    //    path: WebUrl._URL_RICHWIN_ADMIN,
    //    exact: true,
    //    title: "Richwin Admin",
    //    authRequired: true,
    //    component: () => <RichwinAdmin />,
    //},
    //{
    //    path: WebUrl._URL_COMMISSION_HISTORY,
    //    exact: true,
    //    title: "Commission History",
    //    authRequired: true,
    //    component: () => <CommissionHistory />,
    //},
    //{
    //    path: WebUrl._URL_UNCLAIM_COMMISSION_HISTORY,
    //    exact: true,
    //    title: "Unclaim Commission History",
    //    authRequired: true,
    //    component: () => <UnclaimCommissionHistory />,
    //},
    //{
    //    path: WebUrl._URL_WALLET_TRANSFER_HISTORY,
    //    exact: true,
    //    title: "Wallet Transfer History",
    //    authRequired: true,
    //    component: () => <WalletTransferHistory />,
    //},
    //{
    //    path: WebUrl._URL_DEPOSIT_RECORD,
    //    exact: true,
    //    title: "Deposit Record",
    //    authRequired: true,
    //    component: () => <DepositRecord />,
    //},
    //{
    //    path: WebUrl._URL_WITHDRAWAL_RECORD,
    //    exact: true,
    //    title: "Withdrawal Record",
    //    authRequired: true,
    //    component: () => <WithdrawalRecord />,
    //},
    //{
    //    path: WebUrl._URL_LOYALTY_REDEMPTION,
    //    exact: true,
    //    title: "Loyalty Redemption",
    //    authRequired: true,
    //    component: () => <LoyaltyRedemption />,
    //},
    //{
    //    path: WebUrl._URL_LOYALTY_REDEMPTION_HISTORY,
    //    exact: true,
    //    title: "Loyalty Redemption History",
    //    authRequired: true,
    //    component: () => <LoyaltyRedemptionHistory />,
    //},
    //{
    //    path: WebUrl._URL_LOYALTY_REDEMPTION_TNC,
    //    exact: true,
    //    title: "Loyalty Redemption TnC",
    //    authRequired: true,
    //    component: () => <LoyaltyRedemptionTnC />,
    //},
    //{
    //    path: WebUrl._URL_UNCLAIM_LOYALTY_POINTS,
    //    exact: true,
    //    title: "Unclaim Loyalty Points",
    //    authRequired: true,
    //    component: () => <UnclaimLoyaltyPoints />,
    //},
    //{
    //    path: WebUrl._URL_LOYALTY_POINTS_HISTORY,
    //    exact: true,
    //    title: "Loyalty Points History",
    //    authRequired: true,
    //    component: () => <LoyaltyPointsHistory />,
    //}
];
