import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Button = ({
  disabled,
  long,
  rightIcon,
  size,
  style,
  text,
  textColor,
  type,
  width,
  onClick,
}) => {
  const { t } = useTranslation();
  // Default size(normal)
  let classSize = "btn";

  if (size === "small") {
    classSize = "small-btn";
  }

  // Default button class(primary)
  let className = `${classSize} side-logout-btn`;

  // Button's type
  if (type === "ghost") {
    className = `btn btn-outline-info rounded-pill transaction-btn ${classSize}`;
  } else if (type === "secondary") {
    className = `${classSize} green-btn`;
  } else if (type === "danger") {
    className = `${classSize} red-btn`;
  }

  // Class for full width button
  if (long) {
    className = `${className} depostit-submit-btn`;
  }

  // Class for disabled button
  if (disabled) {
    className = `${className} disabled`;
  }

  return (
    <div
      onClick={() => onClick()}
      className={className}
      style={{
        ...style,
        width,
        color: textColor,
      }}
    >
      {t(text)}
      {rightIcon}
    </div>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  long: PropTypes.bool, // Full width button
  rightIcon: PropTypes.element,
  size: PropTypes.string, // Available options: small, normal
  style: PropTypes.object, // Inline style object
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  type: PropTypes.string, // Available options: primary, secondary, ghost and danger
  width: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  disabled: false,
  long: false,
  size: "normal",
  type: "primary",
};

export default Button;
